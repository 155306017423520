import { Modal } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Image from "../../common/image/Image";
import Footer from "../../layout/footer/Footer";
import { Typo } from "../../layout/theme";
import FAQModal from "./components/faq-modal/FAQModal";

const GUTTER = 10;

export default function FooterContainer({ img }) {
	const [showFAQModal, setShowFAQModal] = useState();

	const handleFAQOk = () => {
		setShowFAQModal(false);
	};

	const handleFAQCancel = () => {
		setShowFAQModal(false);
	};

	const handleFAQClick = () => {
		setShowFAQModal(true);
	};

	return (
		<Footer>
			<Footer.Divider></Footer.Divider>
			<Footer.Main gutter={GUTTER}>
				<Footer.Column>
					<Link
						to="/"
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Footer.Image>
							<Image
								src={img}
								style={{ height: "30%", width: "30%", objectFit: "cover" }}
							/>
						</Footer.Image>
						<Footer.Copy>
							Copyright © <br /> Todos los derechos reservados
						</Footer.Copy>
					</Link>
				</Footer.Column>
				{/* <Footer.Column>
					<Footer.Titles>COMPAÑÍA</Footer.Titles>

					<Footer.Info>
						<Link to="/">Información</Link> <br />
						<br /> <Link to="/">Contacto</Link>
					</Footer.Info>
				</Footer.Column>
				<Footer.Column>
					<Footer.Titles>PRODUCTOS</Footer.Titles>
					<Footer.Info>
						<Link to="/categories">Categorías</Link> <br />
						<br />
						<Link to="/offers">Ofertas</Link> <br />
						<br />
						<Link to="/stores">Laboratorios</Link>
					</Footer.Info>
				</Footer.Column> */}
				{/* <Footer.Column> */}
				{/* <Footer.Titles>SOPORTE</Footer.Titles> */}
				{/* <Footer.Info>
						<Link
							to={"/redirect?uri=https://checkpos.zendesk.com/hc/es-419"}
							target="_blank"
						>
							<Typo level={5} type="secondary" style={{ cursor: "pointer" }}>
								Centro de Soporte
							</Typo>
						</Link>
					</Footer.Info> */}
				{/* </Footer.Column> */}
				{/* <Footer.Column>
					<Footer.Info>
						<Typo
							level={5}
							type="secondary"
							onClick={handleFAQClick}
							style={{ cursor: "pointer" }}
						>
							Preguntas Frecuentes
						</Typo>
					</Footer.Info>
				</Footer.Column> */}
			</Footer.Main>
			{/* <Footer.Bottom></Footer.Bottom> */}
			<Modal
				title={`Preguntas Frecuentes`}
				visible={showFAQModal}
				onOk={handleFAQOk}
				onCancel={handleFAQCancel}
				width={1200}
				centered={true}
				footer={null}
				destroyOnClose={true}
			>
				<FAQModal />
			</Modal>
		</Footer>
	);
}
