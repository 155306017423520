import React, { useState, useEffect, useMemo } from "react";
import { Modal, Input } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FaImages } from "react-icons/fa";
import debounce from "lodash/debounce";
import {
	TYPE,
	openNotificationWithIcon,
} from "../../../../../../../utils/notificationToast";
import { COLORS, Typo } from "../../../../../../layout/theme";
import Image from "../../../../../../common/image/Image";
import { AntdTable, Inner, TableContainer } from "./styles";
import { calculatePercentage } from "../../../../../../../utils/calculatePrice";

let productIndex = null;

export default function EventProductsDiscounts({
	clientId,
	templateProducts,
	setTemplateProducts,
	saveChanges,
	hasChanges,
	setHasChanges,
}) {
	//const [data, setData] = useState(null);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);

	const { confirm } = Modal;

	const fetch_discounts_today = async (search = "") => {
		const newData = templateProducts
			.map((i) => ({
				...i,
				hasChanges: false,
			}))
			.sort((a, b) =>
				a.title.toUpperCase() > b.title.toUpperCase()
					? 1
					: b.title.toUpperCase() > a.title.toUpperCase()
					? -1
					: 0
			);
		setTemplateProducts(newData);

		const thereIsChanges = templateProducts.some((i) => i.hasChanges === true);
		setHasChanges(thereIsChanges);
	};

	useEffect(() => {
		fetch_discounts_today();
	}, [clientId]);

	const onInputChange = (key, index) => (e) => {
		const newData = [...templateProducts];
		if (key === "discount") {
			newData[index]["discount"] = Number(e.target.value);
		}

		if (key === "triggerDiscount") {
			newData[index]["triggerDiscount"] = Number(e.target.value);
		}

		if (key === "triggerQty") {
			if (Number(e.target.value) === 0) {
				newData[index]["triggerDiscount"] = 0;
			}
			newData[index]["triggerQty"] = Number(e.target.value);
		}

		newData[index]["hasChanges"] = true;
		setTemplateProducts(newData);
		setHasChanges(true);
	};

	const handleSubmit = () => {
		confirm({
			title: "¿Seguro que quiere publicar estos descuentos?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El cambio se efectuará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				confirmDiscounts();
			},
		});
	};

	const confirmDiscounts = async () => {
		setLoading(true);
		try {
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Los descuentos se actualizaron correctamente",
				"Los descuentos se actualizaron correctamente"
			);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Los descuentos no se pudieron actualizar correctamente, contacte con soporte a soporte@checkpos.com",
				"Los descuentos se se pudieron actualizar correctamente, contacte con soporte a soporte@checkpos.com"
			);
		} finally {
			setLoading(false);
		}
	};

	const columns = [
		{
			title: () => {
				return (
					<div
						style={{
							fontSize: "18px",
						}}
					>
						<FaImages style={{ color: COLORS.White }} />
					</div>
				);
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			width: 150,
			align: "center",
			render: (value, record) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "title",
			key: "title",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					{value} {record.presentationName}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>EAN</div>,
			dataIndex: "ean13",
			key: "ean13",
			width: 140,
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento PSL</div>,
			dataIndex: "discount",
			key: "discount",
			width: 220,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
						display: "flex",
						alignItems: "center",
						gap: 20,
					}}
				>
					<Input
						type="number"
						min={0}
						value={value}
						onChange={onInputChange(
							"discount",
							page === 1 ? index : (page - 1) * 25 + index
						)}
						addonBefore="%"
						style={{ width: "110px" }}
					/>
					{console.log(record)}
					<Typo type="secondary" level={8} translate="no">
						PVP -
						{calculatePercentage(
							record.price,
							record.clientGeneralDiscount,
							record.discount,
							0,
							0
						)}
						%
					</Typo>
				</div>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Descuento por cantidad</div>
			),
			dataIndex: "triggerDiscount",
			key: "triggerDiscount",
			//width: 100,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
						display: "flex",
						alignItems: "center",
						gap: 20,
					}}
				>
					<Input
						type="number"
						min={0}
						value={record.triggerQty}
						onChange={onInputChange(
							"triggerQty",
							page === 1 ? index : (page - 1) * 25 + index
						)}
						addonAfter="un."
						style={{ width: "100px" }}
					/>
					{record?.triggerQty > 0 && (
						<Input
							type="number"
							min={0}
							value={record.triggerDiscount}
							onChange={onInputChange(
								"triggerDiscount",
								page === 1 ? index : (page - 1) * 25 + index
							)}
							addonBefore="%"
							style={{ width: "110px" }}
						/>
					)}
					{record?.triggerDiscount > 0 && (
						<Typo type="terciary" lineHeight={1} level={8} translate="no">
							PVP -
							{calculatePercentage(
								parseFloat(record?.price),
								parseFloat(record?.clientGeneralDiscount),
								parseFloat(record?.discount),
								parseFloat(record?.triggerDiscount),
								0
							)}
							%
						</Typo>
					)}
				</div>
			),
		},
	];

	const eventHandler = (e) => {
		if (hasChanges) {
			confirm({
				title: "Existen cambios sin guardar",
				icon: <InfoCircleOutlined style={{ color: COLORS.Warning }} />,
				content:
					"Efectuar la búsqueda originará una pérdida de los datos sin guardar",
				okText: "Buscar",
				okType: "default",
				cancelText: "Cancelar",
				onOk: () => {
					fetch_discounts_today(e.target.value);
				},
			});
		} else {
			fetch_discounts_today(e.target.value);
		}
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 350),
		[hasChanges]
	);

	return (
		<Inner>
			{/* <AntdSearch
				allowClear
				placeholder="Buscar productos"
				onChange={debouncedEventHandler}
				width={400}
			/> */}
			{!templateProducts ? (
				<div>Loading...</div>
			) : (
				<TableContainer>
					<AntdTable
						dataSource={templateProducts}
						columns={columns}
						loading={loading}
						pagination={false}
						rowClassName={(record) => (record.hasChanges ? "hasChanges" : null)}
						scroll={{
							y: 650,
						}}
					/>
				</TableContainer>
			)}
		</Inner>
	);
}
