import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Form, Input, Row, Col, Space, Modal } from "antd";
import { InnerForm, ProductData, PublishSettings } from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import COLORS from "../../../../layout/theme/colors/Colors";
import { get_categories } from "../../../../../api/endpoints/categories";
import DebounceSelect from "../../../../common/debounce-select/DebounceSelect";
import { create_sale_department } from "../../../../../api/endpoints/saledepartments";
import { Typo } from "../../../../layout/theme";
import { CloseCircleOutlined } from "@ant-design/icons";

const GUTTER = 10;

export default function CreateSaleDepartment() {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [categories, setCategories] = useState(null);
	const [submitting, setSubmitting] = useState(false);

	const fetch_categories = async (term) => {
		const res = await get_categories(term);

		return res.map((r) => ({
			label: r.name,
			value: r.id,
		}));
	};

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				title: values.title,
				categories: categories?.map((i) => ({ id: i.value })),
			};

			const res = await create_sale_department(postData);
			navigate(`/admin/saledepartments?uri=${uri}`);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Rubro creado con éxito",
				"El rubro se creo con éxito"
			);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear el rubro",
				"Un error ocurrio al intentar crear el rubro, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido completados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/saledepartments?uri=" + uri);
			},
		});
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/saledepartments?uri=" + uri}>Rubros</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Crear Rubro</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Form
					form={form}
					name="create-saleDepartment"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
				>
					<InnerForm>
						<ProductData>
							<Space
								direction="vertical"
								size="middle"
								style={{ display: "flex" }}
							>
								<Row gutter={GUTTER}>
									<Col span={22}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Nombre
												</label>
											}
											name="title"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(
														/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
													),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input placeholder="Nombre" />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={GUTTER}>
									<Col span={22}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Categorías asociadas
												</label>
											}
										>
											<DebounceSelect
												fetchCallback={fetch_categories}
												placeholder="Buscar categorias"
												onValuesChange={setCategories}
												initialValues={categories}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Space>
						</ProductData>
						<PublishSettings>
							<Space
								direction="vertical"
								size="middle"
								style={{ display: "flex" }}
							>
								<Form.Item>
									<SignInTemplate.AntdButton
										type="Primary"
										color="white"
										bg="Primary"
										htmlType="submit"
										loading={submitting}
										block
									>
										Crear Rubro
									</SignInTemplate.AntdButton>
								</Form.Item>
								<SignInTemplate.AntdButton
									type="Primary"
									loading={submitting}
									onClick={handleBack}
									block
								>
									<Typo type="danger" level={6}>
										Volver sin guardar
									</Typo>
								</SignInTemplate.AntdButton>
							</Space>
						</PublishSettings>
					</InnerForm>
				</Form>
			</Main.Body>
		</Main>
	);
}
