import React from "react";
import { Typo } from "../../../../layout/theme";
import { Container } from "./styles";

export default function FAQModal() {
	return (
		<Container>
			<Typo type="primary" level={4}>
				¿Tiene Costo para las farmacias?
			</Typo>
			<Typo type="muted" level={6}>
				Registrarse en Checkpos Transfer NO tiene ningún costo para las
				farmacias. Generar Pedidos Tampoco tiene ningún costo, los pedidos serán
				entregados por las droguerías que seleccione la farmacia con las
				condiciones que la misma tenga con ellas.
			</Typo>
			<Typo type="primary" level={4}>
				¿Cómo realizo un pedido?
			</Typo>
			<Typo type="muted" level={6}>
				Generar pedidos es super sencillo. Primero seleccionaras los productos y
				las cantidades que desees solicitar, luego seleccionaras la droguería a
				la que quieras enviarle el pedido y ¡listo! De manera inmediata el
				pedido será enviado por Checkpos Transfer con las condiciones que
				habitualmente trabajas y con los mismos tiempos de entrega que vienes
				trabajando.
			</Typo>
			<Typo type="primary" level={4}>
				¿Cuántos pedidos puedo realizar?
			</Typo>
			<Typo type="muted" level={6}>
				Todos los que quieras, no hay límites. Podes usar Checkpos Transfer como
				cualquier plataforma de droguería.
			</Typo>
			<Typo type="primary" level={4}>
				¿Quien Factura y emite el remito?
			</Typo>
			<Typo type="muted" level={6}>
				La Droguería que selecciones para cada pedido es quien emite las
				facturas y los remitos. Vas a mantener los mismos acuerdos comerciales y
				los plazos de entrega que tenes con cada droguería.
			</Typo>
			<Typo type="primary" level={4}>
				¿En cuánto tiempo recibo mi pedido?
			</Typo>
			<Typo type="muted" level={6}>
				Los tiempos dependen de cada droguería y distribuidor, vas a recibir el
				pedido en los mismos tiempos que trabajabas hasta hoy con cada
				droguería.
			</Typo>
			<Typo type="primary" level={4}>
				¿Dónde puedo ver y seguir mis pedidos
			</Typo>
			<Typo type="muted" level={6}>
				En la sección “mis compras” podras ver cada pedido, el usuario que lo
				generó y en que estado se encuentra.
			</Typo>
			<Typo type="primary" level={4}>
				¿Puedo cancelar un pedido?
			</Typo>
			<Typo type="muted" level={6}>
				Si el pedido fue enviado a la droguería, podras cancelarlo comunicándote
				con ella, una vez enviado el pedido a la droguería, Checkpos Transfer ya
				no tiene potestad por el, y la cancelación depende de la droguería.
			</Typo>
		</Container>
	);
}
