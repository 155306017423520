import {
	CloseCircleOutlined,
	MinusCircleOutlined,
	PlusOutlined,
	RightCircleOutlined,
} from "@ant-design/icons";
import {
	Button,
	Form,
	Input,
	Select,
	Space,
	DatePicker,
	Radio,
	Checkbox,
	Modal,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaImages } from "react-icons/fa";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { get_market_brands } from "../../../../../../api/endpoints/brands";
import { create_campaign } from "../../../../../../api/endpoints/campaigns";
import { get_clients_simplified_filtered } from "../../../../../../api/endpoints/clients";
import { get_distributor_of_client } from "../../../../../../api/endpoints/distributors";
import {
	get_brands_products,
	get_products_by_id,
} from "../../../../../../api/endpoints/products";
import { get_provinces } from "../../../../../../api/endpoints/region";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import ImageGallery from "../../../../../common/image-gallery/ImageGallery";
import Image from "../../../../../common/image/Image";
import Main from "../../../../../layout/main/Main";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import { COLORS, Typo } from "../../../../../layout/theme";
import CampaignTable from "./components/campaign-table/CampaignTable";
import ProductsTable from "./components/products-table/ProductsTable";
import {
	CartAntdButton,
	Container,
	FirstTable,
	InfoRegion,
	Inner,
	SecondTable,
	InfoSection,
	SubmitSection,
} from "./styles";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function CreateCampaign() {
	const [form] = Form.useForm();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [clients, setClients] = useState(null);
	const [brands, setBrands] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [products, setProducts] = useState(null);
	const [distributors, setDistributors] = useState(null);
	const [productsForCampaign, setProductsForCampaign] = useState([]);
	const [realProducts, setRealProducts] = useState([]);
	const [campaignOffer, setCampaignOffer] = useState(0);
	const [photoGallery, setPhotoGallery] = useState(null);
	const [specificRegion, setSpecificRegion] = useState(false);
	const [listadoProvincias, setListadoProvincias] = useState(false);
	const [selectedProvince, setSelectedProvince] = useState(null);
	const [listadoLocalities, setListadoLocalities] = useState(null);
	const [selectedLocalities, setSelectedLocalities] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [checkboxState, setCheckboxState] = useState(false);
	const [loadingAdd, setLoadingAdd] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [provinceDisabled, setProvinceDisabled] = useState(false);
	const [distributorsForCampaign, setDistributorsForCampaign] = useState(null);
	const navigate = useNavigate();

	const fetch_clients = async () => {
		const res = await get_clients_simplified_filtered("approved");
		setClients(res);
	};

	const fetch_brands = async () => {
		const data = await get_market_brands();
		setBrands(data.filter((b) => b.clientId === selectedClient));
	};

	const fetch_products = async (e) => {
		const data = await get_brands_products(e, selectedClient);
		setProducts(data);
	};

	const handleClientSelect = (e) => {
		setSelectedClient(e);
		setProductsForCampaign([]);
		setRealProducts([]);
	};

	const handleBrandSelect = (e) => {
		fetch_products(e);
	};

	const handleAddToTemplate = async (id) => {
		setLoadingAdd(true);
		try {
			const prodsInArray = [...productsForCampaign];
			const realProdsInArray = [...realProducts];
			const prod = products?.find((e) => e.id === id);

			if (!productsForCampaign.some((e) => e.id === id)) {
				prodsInArray.push(prod);
				setProductsForCampaign(prodsInArray);
				const res = await get_products_by_id(prod.id);
				realProdsInArray.unshift(res);
				setRealProducts(realProdsInArray);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoadingAdd(false);
		}
	};

	const disabledDate = (current) => {
		return current && current < moment().add(-1, "days").endOf(-1, "day");
	};

	const handlePhotoGalleryChange = (values) => {
		setPhotoGallery(values);
	};

	const regionChange = (e) => {
		e === 2 ? setSpecificRegion(true) : setSpecificRegion(false);
		setSelectedLocalities(null);
		setSelectedProvince(null);
	};

	const fetch_region_1_data = async () => {
		const res = await get_provinces();
		setListadoProvincias(
			res
				.map((p) => ({
					id: p.id,
					name: p.title,
					localities: p.localities.map((l) => ({
						localityId: l.locality.id,
						localityName: l.locality.title,
					})),
				}))
				.sort((a, b) => {
					const titleA = a.name.toLowerCase();
					const titleB = b.name.toLowerCase();
					if (titleA < titleB) {
						return -1;
					}
					if (titleA > titleB) {
						return 1;
					}

					return 0;
				})
		);
	};

	const fetch_client_distributors = async () => {
		const res = await get_distributor_of_client(selectedClient);
		setDistributors(res);
	};

	const handleCheckbox = (e) => {
		setCheckboxState(e.target.checked);
		e.target.checked === true
			? setDistributorsForCampaign(distributors.map((e) => e.id))
			: setDistributorsForCampaign(null);
	};

	const localitiesSelect = (e) => {
		const country = "ARG";
		const province = selectedProvince;
		const localitie = e[e.length - 1];
		const fullLocalitie = `${country}-${province}-${localitie}`;
		if (selectedLocalities === null || selectedLocalities.length === 0) {
			setSelectedLocalities([fullLocalitie]);
		}
		if (
			selectedLocalities.length > 0 &&
			!selectedLocalities?.some((i) => i === fullLocalitie)
		) {
			const arr = [...selectedLocalities];
			arr.push(fullLocalitie);
			setSelectedLocalities(arr);
		}
	};

	const localitiesDeselect = (e) => {
		let arr = [...selectedLocalities];
		arr.forEach((element, i) => {
			if (element.includes(e)) {
				arr.splice(i, 1);
			}
		});
		setSelectedLocalities(arr);
	};

	const provinceSelect = (e, name) => {
		setSelectedProvince(e);
		setProvinceDisabled(false);
		setListadoLocalities(
			listadoProvincias
				.find((p) => p.id === e)
				.localities.sort((a, b) => {
					const titleA = a.localityName.toLowerCase();
					const titleB = b.localityName.toLowerCase();
					if (titleA < titleB) {
						return -1;
					}
					if (titleA > titleB) {
						return 1;
					}

					return 0;
				})
		);
		if (name !== null) {
			const fields = form.getFieldsValue();
			const { region } = fields;

			Object.assign(region[name], { locality: [] });
			Object.assign(region[name], { checkbox: false });
			form.setFieldsValue({ region });
		}
	};

	const handleDelete = (id) => {
		setLoadingDelete(true);
		try {
			const _template = realProducts.filter((p) => p.id !== id);
			const template = productsForCampaign.filter((p) => p.id !== id);
			setRealProducts(_template);
			setProductsForCampaign(template);
		} catch (err) {
			console.log(err);
		} finally {
			setLoadingDelete(false);
		}
	};

	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			key: "action",
			dataIndex: "id",
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(_);
						}}
						loading={loadingAdd}
					>
						<RightCircleOutlined />
					</Button>
				</Space>
			),
		},
	];

	useEffect(() => {
		fetch_clients();
		fetch_region_1_data();
	}, []);

	useEffect(() => {
		fetch_brands();
		fetch_client_distributors();
	}, [selectedClient]);

	const onFinish = async (values) => {
		if (!photoGallery || photoGallery.length === 0) {
			return openNotificationWithIcon(
				TYPE.ERROR,
				"Imagen obligatoria",
				"Un error ocurrio al intentar crear la campaña, no ingreso una imagen"
			);
		}
		const arr = [];
		if (values.regionOption !== "ARG") {
			const transfromArray = values.region?.map((i) => {
				if (typeof i.province === "undefined") {
					return false;
				} else if (i.locality.length === 0) {
					return `ARG-${i.province}`;
				} else if (i.checkbox) {
					return `ARG-${i.province}`;
				} else {
					return i.locality.map((a) => `ARG-${i.province}-${a}`);
				}
			});
			transfromArray.forEach((g) => {
				if (typeof g === "string") {
					arr.push(g);
				} else if (typeof g === "boolean") {
					return null;
				} else {
					arr.push(...g);
				}
			});
		}

		if (values.regionOption !== "ARG" && arr.length === 0) {
			return openNotificationWithIcon(
				TYPE.ERROR,
				"No selecciono una region",
				"Un error ocurrio al intentar crear la campaña, seleccione una región."
			);
		}

		if (realProducts.length === 0) {
			return openNotificationWithIcon(
				TYPE.ERROR,
				"No hay productos agregados",
				"Un error ocurrio al intentar crear la campaña, seleccione al menos un producto."
			);
		}

		setLoadingSubmit(true);
		try {
			const campaign = {
				title: values.title,
				isActive: state === "ACTIVE" ? true : false,
				publishFrom: moment(values.campaignDate[0]),
				publishTo: moment(values.campaignDate[1]),
				campaignOffer: parseInt(values.campaignOffer),
				clientId: values.client,
				products: realProducts.map((e) => e.id),
				region: values.regionOption === "ARG" ? [values.regionOption] : arr,
				distributors: distributorsForCampaign,
			};
			const formData = new FormData();
			formData.append("data", JSON.stringify(campaign));
			photoGallery.forEach((f) => {
				formData.append("files", f.originFileObj);
			});
			const res = await create_campaign(formData);
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Campaña creada con exito",
				"La campaña se creo con exito"
			);
			if (res) {
				navigate("/admin/advertising/campaigns?uri=" + uri);
			}
		} catch (err) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear la campaña",
				"Un error ocurrio al intentar crear la campaña, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setLoadingSubmit(false);
		}
	};

	const handleCheckboxLocalities = (e, name) => {
		const fields = form.getFieldsValue();
		const { region } = fields;
		if (e.target.checked === true) {
			Object.assign(region[name], {
				locality: listadoProvincias
					.find((p) => p.id == region[name].province)
					.localities.map((e) => e.localityId),
			});
			form.setFieldsValue({ region });
		} else {
			Object.assign(region[name], {
				locality: [],
			});
			form.setFieldsValue({ region });
		}
	};

	const handleDisabledLocalities = (name) => {
		const fields = form.getFieldsValue();
		const { region } = fields;
		let final;
		if (region[name].checkbox) {
			final = true;
		} else {
			final = false;
		}
		return final;
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido completados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/advertising/campaigns?uri=" + uri);
			},
		});
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/advertising/campaigns?uri=" + uri}>Campañas</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Crear Campaña</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<Form
					form={form}
					name="basic"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
				>
					<Inner>
						<InfoSection>
							<Typo type="primary" level={4}>
								Crear Campaña
							</Typo>
							<Typo type="secondary" level={5} style={{ marginTop: "20px" }}>
								Información Campaña
							</Typo>
							<Container>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Nombre de la campaña
										</label>
									}
									name="title"
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
										{
											pattern: new RegExp(
												/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
											),
											message:
												"No se aceptan caracteres especiales. Solo letras y numeros",
										},
									]}
								>
									<Input placeholder="Titulo" />
								</Form.Item>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Laboratorio
										</label>
									}
									name="client"
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
									]}
								>
									<Select
										style={{ width: "200px" }}
										onChange={(e) => handleClientSelect(e)}
										placeholder="Elegir Cliente"
									>
										{clients?.map((i) => (
											<Option value={i.id}>{i.name}</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Marca
										</label>
									}
									name="brand"
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
									]}
								>
									<Select
										style={{ width: "200px" }}
										onChange={(e) => handleBrandSelect(e)}
										placeholder="Elegir Marca"
									>
										{brands?.map((i) => (
											<Option value={i.id}>{i.name}</Option>
										))}
									</Select>
								</Form.Item>
							</Container>
							<Container>
								<Form.Item
									name="campaignDate"
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Fecha
										</label>
									}
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
									]}
								>
									<RangePicker
										disabledDate={disabledDate}
										format="DD/MM/YYYY HH:mm:ss"
										showTime={{
											format: "HH:mm:ss",
										}}
									/>
								</Form.Item>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Estado
										</label>
									}
									name="state"
								>
									<Select defaultValue="ACTIVE" onChange={(e) => setState(e)}>
										<Select.Option value="ACTIVE">Activa</Select.Option>
										<Select.Option value="INACTIVE">Inactiva</Select.Option>
									</Select>
								</Form.Item>
							</Container>
							<Container>
								<Typo type="primary" level={6}>
									Galeria
								</Typo>
								<ImageGallery
									data={photoGallery}
									onChange={handlePhotoGalleryChange}
								/>
							</Container>
							{photoGallery === null ||
								(photoGallery.length === 0 && (
									<Typo type="danger" level={8}>
										*La foto es obligatoria
									</Typo>
								))}
							<Typo type="secondary" level={5} style={{ marginTop: "20px" }}>
								Región
							</Typo>
							<Container>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Alcance de la campaña
										</label>
									}
									name="regionOption"
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
									]}
								>
									<Radio.Group
										onChange={(e) => regionChange(e.target.value)}
										style={{ flex: 4 }}
									>
										<Space direction="vertical" size={20}>
											<Radio value={"ARG"}>Todo el país</Radio>
											<Radio value={2}>Región específica</Radio>
										</Space>
									</Radio.Group>
								</Form.Item>
								{specificRegion === true && (
									<Form.List name="region">
										{(fields, { add, remove }) => (
											<Space direction="vertical" style={{ marginTop: "70px" }}>
												<>
													{fields?.map(({ key, name, ...restField }) => (
														<Space
															key={key}
															style={{ display: "flex", marginBottom: 8 }}
															align="baseline"
														>
															<Form.Item
																{...restField}
																name={[name, "province"]}
																label={<InfoRegion>Provincia</InfoRegion>}
															>
																<Select
																	style={{ width: "200px" }}
																	onChange={(e) => provinceSelect(e, name)}
																	disabled={provinceDisabled}
																>
																	{listadoProvincias?.map((e) => (
																		<Select.Option key={e.id} value={e.id}>
																			{e.name}
																		</Select.Option>
																	))}
																</Select>
															</Form.Item>
															{form.getFieldsValue(true).region[name] && (
																<>
																	<Form.Item
																		{...restField}
																		name={[name, "locality"]}
																		label={<InfoRegion>Localidades</InfoRegion>}
																		style={{
																			display: "flex",
																			flexDirection: "column",
																		}}
																	>
																		<Select
																			style={{
																				width: "200px",
																				maxHeight: "200px",
																			}}
																			onChange={(e) => localitiesSelect(e)}
																			mode="multiple"
																			onDeselect={(e) => localitiesDeselect(e)}
																			allowClear
																			showSearch={false}
																			disabled={handleDisabledLocalities(name)}
																			maxTagCount={1}
																		>
																			{listadoProvincias
																				.find(
																					(p) =>
																						p.id ==
																						form.getFieldsValue(true).region[
																							name
																						].province
																				)
																				.localities.map((e) => (
																					<Select.Option
																						key={e.localityId}
																						value={e.localityId}
																					>
																						{e.localityName}
																					</Select.Option>
																				))}
																		</Select>
																	</Form.Item>
																	<Form.Item
																		name={[name, "checkbox"]}
																		valuePropName="checked"
																	>
																		<Checkbox
																			onChange={(e) =>
																				handleCheckboxLocalities(e, name)
																			}
																		>
																			Seleccionar Todas
																		</Checkbox>
																	</Form.Item>
																</>
															)}
															<MinusCircleOutlined
																onClick={() => remove(name)}
															/>
														</Space>
													))}
													<Form.Item>
														<Button
															type="dashed"
															onClick={() => add()}
															block
															icon={<PlusOutlined />}
														>
															Agregar Provincia{" "}
														</Button>
													</Form.Item>
												</>
											</Space>
										)}
									</Form.List>
								)}
							</Container>
							<Typo type="secondary" level={5} style={{ marginTop: "20px" }}>
								Droguerias
							</Typo>
							<Container>
								<Form.Item
									name="distributor"
									rules={[
										{
											required: distributorsForCampaign ? false : true,
											message: "Este campo es obligatorio",
										},
									]}
								>
									{checkboxState ? (
										<Select
											mode="multiple"
											allowClear
											style={{
												width: "100%",
												display: "none",
											}}
											placeholder="Seleccionar"
											disabled={checkboxState}
											value={distributors.map((e) => ({
												key: e.id,
												value: e.id,
											}))}
										>
											{distributors?.map((i) => (
												<Option value={i.id}>{i.name}</Option>
											))}
										</Select>
									) : (
										<Select
											mode="multiple"
											allowClear
											style={{
												width: "100%",
											}}
											placeholder="Seleccionar"
											disabled={checkboxState}
											onChange={(e) => setDistributorsForCampaign(e)}
										>
											{distributors?.map((i) => (
												<Option value={i.id}>{i.name}</Option>
											))}
										</Select>
									)}
									<Space direction="vertical">
										{checkboxState && <Typo>Todas las droguerias</Typo>}
										<Space direction="horizontal">
											<Typo type="primary">Seleccionar todas</Typo>{" "}
											<Checkbox onChange={(e) => handleCheckbox(e)} />
										</Space>
									</Space>
								</Form.Item>
							</Container>
							<Typo type="secondary" level={5} style={{ marginTop: "20px" }}>
								Productos
							</Typo>
							<Container>
								<FirstTable>
									<ProductsTable data={products} columns={columns} />
								</FirstTable>
								<SecondTable style={{ marginTop: "16px" }}>
									<CampaignTable
										data={realProducts}
										campaignOffer={campaignOffer}
										onDelete={handleDelete}
										loading={loadingDelete}
									/>
								</SecondTable>
							</Container>
						</InfoSection>

						<SubmitSection>
							<Typo type="secondary" level={5} style={{ marginTop: "20px" }}>
								Publicar
							</Typo>
							<Form.Item
								label={
									<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
										% Adicional
									</label>
								}
								name="campaignOffer"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
								]}
							>
								<Input
									placeholder="% Adicional"
									type="number"
									onChange={(e) => setCampaignOffer(e.target.value)}
								/>
							</Form.Item>
							<CartAntdButton
								type="Primary"
								color="white"
								bg="Primary"
								htmlType="submit"
								style={{ width: "150px" }}
								loading={loadingSubmit}
							>
								Publicar
							</CartAntdButton>
							<SignInTemplate.AntdButton type="Primary" onClick={handleBack}>
								<Typo type="danger" level={6}>
									Volver sin guardar
								</Typo>
							</SignInTemplate.AntdButton>
						</SubmitSection>
					</Inner>
				</Form>
			</Main.Body>
		</Main>
	);
}
