import React from "react";
import Layout from "../../../../layout/base/Layout";
import Image from "../../../../common/image/Image";
import { Badge, Col } from "antd";
import { COLORS, SIZES, Typo } from "../../../../layout/theme";
import { AuditOutlined, PhoneOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import MarketLogo from "../../../../../assets/images/logo.png";
import CartIcon from "../../../../../assets/images/cart-shopping-solid.svg";
import MenuIcon from "../../../../../assets/images/bars-solid.svg";
import SearchProducts from "../../../../common/search-products/SearchProducts";

export default function LaboratoryHeader({
	scrollDirection,
	userdata,
	campaignRegion,
	location,
	clientSlug,
	pharmaName,
	templateProducts,
	bookQty,
	qty,
	setShowDrawer,
	showDrawer,
	browserInfo,
	clientPhotoUrl,
}) {
	return (
		<Layout.LayoutHeader
			justify="space-evenly"
			align="middle"
			scroll={scrollDirection}
		>
			<Col md={3} lg={3} xl={3}>
				{/* {location?.pathname === "/homeMarketplace" ||
				location?.pathname === "/offers" ||
				location?.pathname === "/search" ? (
					<Link to="/">
						<Image src={MarketLogo} alt="Logo Market" height={45} />
					</Link>
				) : ( */}
				<Link to="/">
					<Image src={clientPhotoUrl} alt="Logo Laboratorio" height={45} />
				</Link>
				{/* )} */}
			</Col>
			<Col md={7} lg={7} xl={7}>
				<Layout.LayoutHeaderOptions
					style={{
						gap: "30px",
					}}
				>
					{/* {(location?.pathname === "/homeMarketplace" ||
						location?.pathname === "/offers" ||
						location?.pathname === "/search") && (
						<Link to="/offers">
							<Layout.HeaderActionParagraph>
								{location?.pathname.includes("/offers") ? (
									<Typo level={6} type="primary" hover="primary">
										Ofertas
									</Typo>
								) : (
									<Typo level={6} type="muted" hover="primary">
										Ofertas
									</Typo>
								)}
							</Layout.HeaderActionParagraph>
						</Link>
					)}
					{(location?.pathname === "/homeMarketplace" ||
						location?.pathname === "/offers" ||
						location?.pathname === "/search") && (
						<Link to="/search">
							<Layout.HeaderActionParagraph>
								{location?.pathname.includes("/search") ? (
									<Typo level={6} type="primary" hover="primary">
										Catálogo
									</Typo>
								) : (
									<Typo level={6} type="muted" hover="primary">
										Catálogo
									</Typo>
								)}
							</Layout.HeaderActionParagraph>
						</Link>
					)}
					{(location?.pathname === "/homeMarketplace" ||
						location?.pathname === "/offers" ||
						location?.pathname === "/search") && (
						<Link to={`/stores/${clientSlug}`}>
							<Layout.HeaderActionParagraph>
								<Typo level={6} type="muted" hover="primary">
									Tienda {pharmaName}
								</Typo>
							</Layout.HeaderActionParagraph>
						</Link>
					)} */}
				</Layout.LayoutHeaderOptions>
			</Col>

			<Col md={6} lg={6} xl={8}>
				{/* {(location?.pathname === "/homeMarketplace" ||
					location?.pathname === "/offers" ||
					location?.pathname === "/search") && (
					<SearchProducts location={location?.pathname} />
				)} */}
			</Col>

			<Col md={3} lg={3} xl={3}>
				{(userdata.p_shop || (!userdata.p_shop && userdata.p_mobile)) && (
					<div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
						{userdata.p_shop && !userdata.p_mobile && (
							<Link to="/admin/laboratorySellerWeb">
								<Layout.HeaderAction
									style={{
										width: "60px",
										marginTop: "5px",
									}}
								>
									<Layout.HeaderIcon>
										<Badge
											count={templateProducts?.reduce(
												(acc, item) => acc + item.quantity,
												0
											)}
											offset={[9, -6]}
											size="large"
											color={COLORS.Default}
										>
											<div
												style={{
													fontSize: "30px",
													position: "relative",
													top: "-2px",
												}}
											>
												<PhoneOutlined
													style={{
														color: COLORS.Primary,
													}}
												/>
											</div>
											<div
												style={{
													position: "absolute",
													fontSize: SIZES.level8,
													color: COLORS.Primary,
													top: "40px",
													left: "-3px",
												}}
											>
												Pedidos
											</div>
										</Badge>
									</Layout.HeaderIcon>
								</Layout.HeaderAction>
							</Link>
						)}
						{userdata.p_shop && userdata.p_mobile && (
							<Link to="/admin/expoSellerWeb">
								<Layout.HeaderAction
									style={{
										width: "60px",
										marginTop: "5px",
									}}
								>
									<Layout.HeaderIcon>
										<Badge
											count={templateProducts?.reduce(
												(acc, item) => acc + item.quantity,
												0
											)}
											offset={[9, -6]}
											size="large"
											color={COLORS.Default}
										>
											<div
												style={{
													fontSize: "30px",
													position: "relative",
													top: "-2px",
												}}
											>
												<AuditOutlined
													style={{
														color: COLORS.Primary,
													}}
												/>
											</div>
											<div
												style={{
													position: "absolute",
													fontSize: SIZES.level8,
													color: COLORS.Primary,
													top: "40px",
													left: "2px",
												}}
											>
												Expo
											</div>
										</Badge>
									</Layout.HeaderIcon>
								</Layout.HeaderAction>
							</Link>
						)}
						{!userdata.p_shop && userdata.p_mobile && (
							<Link to="/homeMobile">
								<Layout.HeaderAction
									style={{
										width: "60px",
										marginTop: "5px",
									}}
								>
									<Layout.HeaderIcon>
										<Badge
											count={templateProducts?.reduce(
												(acc, item) => acc + item.quantity,
												0
											)}
											offset={[9, -6]}
											size="large"
											color={COLORS.Default}
										>
											<div
												style={{
													fontSize: "30px",
													position: "relative",
													top: "-2px",
												}}
											>
												<PhoneOutlined
													style={{
														color: COLORS.Primary,
													}}
												/>
											</div>
											<div
												style={{
													position: "absolute",
													fontSize: SIZES.level8,
													color: COLORS.Primary,
													top: "40px",
													left: "-3px",
												}}
											>
												Pedidos
											</div>
										</Badge>
									</Layout.HeaderIcon>
								</Layout.HeaderAction>
							</Link>
						)}
						<Link to="/cart">
							<Layout.HeaderAction
								style={{
									width: "60px",
									marginLeft: "10px",
								}}
							>
								<Layout.HeaderIcon>
									<Badge
										count={qty}
										offset={[-14, -7]}
										size="large"
										color={COLORS.Default}
									>
										<Image
											src={CartIcon}
											alt="Cart"
											style={{
												width: "50%",
											}}
										/>
									</Badge>
								</Layout.HeaderIcon>
							</Layout.HeaderAction>
						</Link>
					</div>
				)}
			</Col>
			<Col md={2} lg={2} xl={2}>
				<Layout.HeaderAction
					onClick={() => setShowDrawer(!showDrawer)}
					style={{
						width: "100px",
					}}
				>
					<Layout.HeaderMenuIcon>
						<Layout.HeaderMenuIconContainer firefox={browserInfo.firefox}>
							{/* {userdata?.guideProgress === 0 &&
                  !userdata?.isOneMarket &&
                  userdata.p_client && (
                    <>
                      <AlertContainer>
                        <AiOutlineAlert color={COLORS.Danger} />
                      </AlertContainer>
                      <AlertContainerAnimated>
                        <AiFillAlert color={COLORS.Danger} />
                      </AlertContainerAnimated>
                    </>
                  )} */}
							<Image
								src={MenuIcon}
								alt="Menu"
								style={{
									height: "50%",
									width: "50%",
									marginLeft: "10px",
								}}
							/>
							<Layout.HeaderMenuParagraph>Menú</Layout.HeaderMenuParagraph>
						</Layout.HeaderMenuIconContainer>
					</Layout.HeaderMenuIcon>
				</Layout.HeaderAction>
			</Col>
		</Layout.LayoutHeader>
	);
}
