import React, { useEffect, useState } from "react";
import {
	AntdTable,
	BodyContainer,
	Container,
	HeaderContainer,
	Section,
	StepContainer,
} from "./styles";
import { COLORS, Typo } from "../../../../../../../layout/theme";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import { get_provinces } from "../../../../../../../../api/endpoints/region";
import SignInTemplate from "../../../../../../../layout/sign-in-template/SignInTemplate";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../../../utils/notificationToast";
import { update_general_pos_by_id } from "../../../../../../../../api/endpoints/pointsofsale";

const GUTTER = 10;

export default function Step2PosDetail({
	stepData,
	handleBackStep,
	setFlag,
	userdata,
}) {
	const [form] = Form.useForm();
	const [provinceList, setProvinceList] = useState(null);
	const [localityList, setLocalityList] = useState(null);
	const [selectedProvince, setSelectedProvince] = useState(stepData?.province);
	const [selectedLocality, setSelectedLocality] = useState(stepData?.locality);
	const [initialValues, setInitialValues] = useState(stepData);
	const [submitting, setSubmitting] = useState(false);
	const [isPhoneVerified, setIsPhoneVerified] = useState(
		stepData?.isPhoneVerified ?? false
	);
	const [isEmailVerified, setIsEmailVerified] = useState(
		stepData?.isEmailVerified ?? false
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setProvinceList(
				res
					.map((p) => ({
						value: p.title,
						label: p.title,
					}))
					.sort((a, b) => {
						const titleA = a.label.toLowerCase();
						const titleB = b.label.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					})
			);

			setLocalityList(
				res
					.filter((p) => p.title === selectedProvince)[0]
					.localities?.map((p) => ({
						value: p.locality.title,
						label: p.locality.title,
					}))
					.sort((a, b) => {
						const titleA = a.label.toLowerCase();
						const titleB = b.label.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}
						return 0;
					})
			);
		};

		fetch_region_1_data();
	}, [selectedProvince]);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				id: initialValues.id,
				name: values.name,
				cuit: values.cuit,
				businessName: values.businessName,
				address: values.address,
				postalCode: values.postalCode,
				contactName: values.contactName,
				contactEmail: values.contactEmail,
				contactPhone: values.contactPhone,
				province: selectedProvince,
				locality: selectedLocality,
				isPhoneVerified: isPhoneVerified,
				isEmailVerified: isEmailVerified,
			};

			await update_general_pos_by_id(initialValues.id, postData);

			handleBackStep(stepData?.backStep);
			setFlag((prev) => !prev);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Punto de venta general editado con exito",
				"El punto de venta general se editó con exito"
			);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al editar el punto de venta general",
				"Un error ocurrio al intentar editar el punto de venta general, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleIsPhoneVerified = (e) => {
		setIsPhoneVerified(e.target.checked);
	};

	const handleIsEmailVerified = (e) => {
		setIsEmailVerified(e.target.checked);
	};

	return (
		<StepContainer>
			<HeaderContainer style={{ padding: 10 }}>
				<Section
					onClick={() => handleBackStep(stepData?.backStep)}
					style={{ cursor: "pointer", justifyContent: "start" }}
				>
					<IoIosArrowRoundBack size={19} color={COLORS.Secondary} />
					<Typo type="secondary" level={7}>
						Volver
					</Typo>
				</Section>
				<Section>
					<Typo type="dark" level={6}>
						Ficha punto de venta
					</Typo>
				</Section>
				<Section></Section>
			</HeaderContainer>
			<BodyContainer>
				<Form
					form={form}
					name="basic"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
					initialValues={initialValues}
				>
					<Row gutter={GUTTER}>
						<Col span={6}>
							<Form.Item
								label={
									<div style={{ color: COLORS.MediumGrey }}>
										Nombre punto de venta
									</div>
								}
								name="name"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
									{
										pattern: new RegExp(
											/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s.-]*$/
										),
										message:
											"No se aceptan caracteres especiales. Solo letras y numeros",
									},
								]}
							>
								<Input disabled={!userdata.p_client} />
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item
								label={<div style={{ color: COLORS.MediumGrey }}>Cuit</div>}
								name="cuit"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
								]}
							>
								<Input type="number" disabled={!userdata.p_client} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label={
									<div style={{ color: COLORS.MediumGrey }}>Razón social</div>
								}
								name="businessName"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
									{
										pattern: new RegExp(
											/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s.-]*$/
										),
										message:
											"No se aceptan caracteres especiales. Solo letras y numeros",
									},
								]}
							>
								<Input disabled={!userdata.p_client} />
							</Form.Item>
						</Col>
						<Col span={6}></Col>
					</Row>
					<Row gutter={GUTTER} style={{ paddingTop: 10 }}>
						<Col span={6}>
							<Form.Item
								label={
									<div style={{ color: COLORS.MediumGrey }}>Dirección</div>
								}
								name="address"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
									{
										pattern: new RegExp(
											/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s.-]*$/
										),
										message:
											"No se aceptan caracteres especiales. Solo letras y numeros",
									},
								]}
							>
								<Input disabled={!userdata.p_client} />
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item
								label={
									<div style={{ color: COLORS.MediumGrey }}>Código Postal</div>
								}
								name="postalCode"
								rules={[]}
							>
								<Input type="number" disabled={!userdata.p_client} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label={
									<div style={{ color: COLORS.MediumGrey }}>Provincia</div>
								}
								name="province"
								rules={[]}
							>
								<Select
									showSearch
									//allowClear
									placeholder="Provincia"
									optionFilterProp="children"
									onChange={(e, o) => setSelectedProvince(e)}
									filterOption={(input, option) =>
										(option?.label ?? "")
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									disabled={selectedLocality || !userdata.p_client}
									options={provinceList}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label={
									<div style={{ color: COLORS.MediumGrey }}>Localidad</div>
								}
								name="locality"
								rules={[]}
							>
								<Select
									showSearch
									allowClear
									placeholder="Localidad"
									optionFilterProp="children"
									onChange={(e, o) => setSelectedLocality(e)}
									filterOption={(input, option) =>
										(option?.label ?? "")
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									options={localityList}
									disabled={!userdata.p_client}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={GUTTER} style={{ paddingTop: 10 }}>
						<Col span={6}>
							<Form.Item
								label={
									<div style={{ color: COLORS.MediumGrey }}>
										Nombre de contacto
									</div>
								}
								name="contactName"
								rules={[]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item
								label={<div style={{ color: COLORS.MediumGrey }}>Teléfono</div>}
								name="contactPhone"
								rules={[
									{
										pattern: new RegExp(/^[0-9]*$/),
										message:
											"No se aceptan caracteres especiales. Solo numeros",
									},
								]}
							>
								<Input
									addonAfter={
										<Checkbox
											onChange={handleIsPhoneVerified}
											checked={isPhoneVerified}
										>
											<Typo type="mediumGrey" level={6}>
												Verificado
											</Typo>
										</Checkbox>
									}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label={
									<div style={{ color: COLORS.MediumGrey }}>
										Email de contacto
									</div>
								}
								name="contactEmail"
								rules={[]}
							>
								<Input
									addonAfter={
										<Checkbox
											onChange={handleIsEmailVerified}
											checked={isEmailVerified}
										>
											<Typo type="mediumGrey" level={6}>
												Verificado
											</Typo>
										</Checkbox>
									}
								/>
							</Form.Item>
						</Col>
						<Col span={6}></Col>
					</Row>
					<Row gutter={GUTTER} style={{ marginTop: -40 }}>
						<Col span={6}></Col>
						<Col span={4}>
							<Form.Item label={<></>} name="secondContactPhone" rules={[]}>
								<Input disabled />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label={<></>} name="secondContactEmail" rules={[]}>
								<Input disabled />
							</Form.Item>
						</Col>
						<Col span={6}></Col>
					</Row>
					<Row style={{ paddingTop: 20, justifyContent: "center" }}>
						<Col span={4}>
							<Form.Item>
								<SignInTemplate.AntdButton
									type="Primary"
									color="white"
									bg="Primary"
									htmlType="submit"
									loading={submitting}
								>
									Guardar cambios
								</SignInTemplate.AntdButton>
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item>
								<SignInTemplate.AntdButton
									type="Primary"
									loading={submitting}
									onClick={() => handleBackStep(stepData?.backStep)}
								>
									<Typo type="danger" level={6}>
										Volver sin guardar
									</Typo>
								</SignInTemplate.AntdButton>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</BodyContainer>
		</StepContainer>
	);
}
