import { Table } from "antd";
import { ContainerDiv } from "./styles";
import { COLORS, Typo } from "../../../../../../layout/theme";

export default function ErrorTable({ data, scrollData = null }) {
	const defColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Columna</div>,
			dataIndex: "columnName",
			key: "columnName",
			render: (value, record, index) => (
				<Typo type="darkMuted" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Error</div>,
			dataIndex: "error",
			key: "error",
			render: (value, record, index) => (
				<Typo type="danger" level={6}>
					{record.errors[0]}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Valor</div>,
			dataIndex: "errorValue",
			key: "errorValue",
			render: (value, record, index) => (
				<Typo type="primary" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Fila</div>,
			dataIndex: "errorRowIndex",
			key: "errorRowIndex",
			render: (value, record, index) => (
				<Typo type="darkMuted" level={6}>
					{value}
				</Typo>
			),
		},
	];

	return (
		<ContainerDiv>
			<Table
				showHeader={true}
				columns={defColumns}
				dataSource={data}
				pagination={false}
				rowKey={(record) => record.id}
				scroll={scrollData}
			/>
		</ContainerDiv>
	);
}
