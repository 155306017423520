import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Row, Col, message, Checkbox, Select } from "antd";
import Main from "../../../../layout/main/Main";
import {
	get_client,
	update_client,
	update_client_distributors,
} from "../../../../../api/endpoints/clients";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import UploadFile from "../../../../common/upload-file/UploadFile";
import {
	Inner,
	DataSection,
	Container,
	ButtonContainer,
	DistributorSection,
	Title,
	AntdModal,
	AntdTable,
	DistributorText,
	Section,
	GuideText,
	ImageContainer,
	LeftSection,
	LeftCard,
	ImageCard,
	Header,
	BillerContainer,
	BillerAndCodeContainer,
	BillerSelection,
	BillerCode,
	IconContainer,
} from "./styles";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import PhoneInput from "../../../../common/phone-input/PhoneInput";
import Image from "../../../../common/image/Image";
import { useAuth } from "../../../../../contexts/authContext";
import DistributorModal from "./components/distributors-modal/DIstributorModal";
import { COLORS, Typo } from "../../../../layout/theme";
import { FaImages } from "react-icons/fa";
import { AiFillPlusCircle } from "react-icons/ai";
import { GoAlert } from "react-icons/go";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { get_billers } from "../../../../../api/endpoints/billers";
import { CopyOutlined } from "@ant-design/icons";
import { BsDownload } from "react-icons/bs";

const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function AccountInformation() {
	const [form] = Form.useForm();
	const [initialValues, setInitialValues] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [distributors, setDistributors] = useState(null);
	const { user, userdata, updateStartGuideProgress } = useAuth();
	const [showDistributorsModal, setShowDistributorsModal] = useState(false);
	const [distributorsList, setDistributorsList] = useState(null);
	const [flag, setFlag] = useState(false);
	const [activeIsChain, setActiveIsChain] = useState(false);
	const navigate = useNavigate();
	const [hasBiller, setHasBiller] = useState(false);
	const [billers, setBillers] = useState(null);
	const [billerSelected, setBillerSelected] = useState(null);
	const [showBillers, setShowBillers] = useState(false);

	const columns = [
		{
			title: () => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			align: "center",
			render: (data) => <Image width={40} height={40} src={data} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Droguería</div>,
			dataIndex: "name",
			key: "name",
			align: "center",
		},
	];

	const fetch_client = async (id) => {
		const res = await get_client(id);
		const data = {
			...res.client,
			contactId: res.client.users.find((u) => u.isFirstUser === true).id,
			phone: res.client.contactPhone.slice(3, res.client.contactPhone.length),
			phoneAreaCode: res.client.contactPhone.slice(0, 2),
			distributors: res.distributors?.map((d) => ({
				key: d.id,
				...d,
			})),
		};
		setInitialValues(data);
		setDistributors(res.distributors);
		setDistributorsList(res.distributors.filter((d) => d.code === "si"));
		setImageUrl(data.photoUrl);
		setActiveIsChain(data.isChain ? data.isChain : false);
		setHasBiller(data.billerId ? true : false);
		setShowBillers(data.billerId ? true : false);
		setBillerSelected(data.billerId);
	};

	const fetch_billers = async () => {
		const res = await get_billers();
		setBillers(res);
	};

	useEffect(() => {
		fetch_client();
		fetch_billers();
	}, [form, flag]);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			if (
				values.glnAnmat === "" ||
				values.glnAnmat === null ||
				values.glnAnmat === undefined
			) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"El código GLN no puede ser vacío"
				);
				setSubmitting(false);
				return;
			}

			const postData = {
				name: initialValues.name,
				businessName: initialValues.businessName,
				glnAnmat: values.glnAnmat,
				photoUrl: values.photoUrl,
				contactName: values.contactName,
				contactId: initialValues.contactId,
				cuit: initialValues.cuit,
				contactEmail: values.contactEmail,
				contactPhone: `${values.phoneAreaCode}${values.phone}`,
				address: values.address,
				postalCode: values.postalCode,
				isSeller: initialValues.isSeller,
				isChain: initialValues.isChain,
				deleted: initialValues.deleted,
				distributors: distributorsList.map((d) => ({
					id: d.id,
					code: d.code,
					selectedMail: d.selectedMail,
				})),
				billerId: billerSelected,
			};

			const formData = new FormData();

			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);

			formData.append("data", JSON.stringify(postData));

			await update_client(initialValues.id, formData);

			if (userdata?.guideProgress === 0) {
				updateStartGuideProgress(user.uid, 1);
				navigate("/startGuide");
			}

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Los datos se guardaron con éxito"
			);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al guardar los datos",
				"Un error ocurrio al intentar guardar los datos, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
			setFlag((prev) => !prev);
			window.scrollTo(0, 0);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleClick = () => {
		setShowDistributorsModal(true);
	};

	const handleOk = async () => {
		const newDistributor = distributors.map((d) =>
			d.checked === true ? { ...d, code: "si" } : { ...d, code: null }
		);
		const data = {
			distributors: newDistributor.map((d) => ({
				id: d.id,
				code: d.code,
				selectedMail: d.selectedMail,
			})),
		};
		await update_client_distributors(data);
		setFlag(!flag);
		setShowDistributorsModal(false);
	};

	const handleCancel = () => {
		setShowDistributorsModal(false);
	};

	const handleInfoClick = () => {
		navigate(`/admin/pointsofsale`);
	};

	const handleBillerChange = (b) => {
		setBillerSelected(b);
	};

	const handleUseBiller = (e) => {
		setShowBillers(e.target.checked);
	};

	const copyToClipboard = async (text) => {
		try {
			await navigator.clipboard.writeText(text);
			message.success("Código de Facturador copiado al portapapeles");
		} catch (err) {
			message.error("Error al copiar al portapapeles");
		}
	};

	const downloadFile = () => {
		window.location.href = initialValues.biller?.fileUrl;
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Datos de la cuenta
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<Header>
							<Title type="primary" style={{ flex: "0 0 40%" }}>
								Datos de la Cuenta
							</Title>
							<Title type="primary" style={{ flex: "0 0 60%" }}>
								Información del Cliente
							</Title>
						</Header>
						<Inner>
							<LeftSection>
								<ImageCard>
									<Container>
										<Title>Imagen de usuario</Title>
										<Form.Item name="file" style={{}}>
											<UploadFile onChange={handleImageChange} />
										</Form.Item>
									</Container>
									<ImageContainer>
										{imageUrl && (
											<Image
												preview={true}
												src={imageUrl}
												width="150px"
												height="150px"
											/>
										)}
									</ImageContainer>
								</ImageCard>
								<LeftCard>
									{billers?.length > 0 && (
										<BillerContainer>
											{!showBillers ? (
												<Checkbox
													onClick={(e) => handleUseBiller(e)}
													checked={showBillers}
												>
													Asociar Facturador{" "}
												</Checkbox>
											) : (
												<BillerAndCodeContainer>
													{!hasBiller && (
														<BillerSelection>
															<Typo type="grey" level={6}>
																Facturador:
															</Typo>
															<Select
																allowClear={true}
																placeholder="Seleccione Facturador"
																onChange={handleBillerChange}
															>
																{billers.map((item) => (
																	<Select.Option key={item.id} value={item.id}>
																		{item.title}
																	</Select.Option>
																))}
															</Select>
														</BillerSelection>
													)}
													{hasBiller && (
														<BillerCode>
															<Typo
																type="grey"
																level={6}
																style={{
																	whiteSpace: "nowrap",
																}}
															>
																Facturador: {initialValues.biller?.title}
															</Typo>
															<BillerSelection>
																<Typo
																	type="grey"
																	level={7}
																	style={{
																		whiteSpace: "nowrap",
																	}}
																>
																	Código:
																</Typo>
																<Input
																	disabled
																	value={initialValues.id}
																	style={{ width: "290px" }}
																/>
																<IconContainer
																	onClick={() =>
																		copyToClipboard(initialValues.id)
																	}
																>
																	<CopyOutlined
																		style={{
																			color: COLORS.Secondary,
																		}}
																	/>
																	<Typo
																		type="secondary"
																		level={9}
																		style={{ whiteSpace: "nowrap" }}
																	>
																		copiar código
																	</Typo>
																</IconContainer>
															</BillerSelection>
															<Typo type="primary" level={7}>
																Este código debe insertarse en su facturador
															</Typo>
															{initialValues.biller?.fileUrl && (
																<div
																	onClick={() => downloadFile()}
																	style={{
																		cursor: "pointer",
																		display: "flex",
																		gap: 10,
																	}}
																>
																	<Typo type="secondary" level={7}>
																		Descargar instructivo
																	</Typo>
																	<BsDownload color={COLORS.Secondary} />
																</div>
															)}
														</BillerCode>
													)}
												</BillerAndCodeContainer>
											)}
										</BillerContainer>
									)}
								</LeftCard>
							</LeftSection>

							<DataSection>
								<Row gutter={GUTTER} style={{ width: "100%" }}>
									<Col md={12} xl={12}>
										<Form.Item label="Razón social" name="businessName">
											<Input disabled />
										</Form.Item>
									</Col>
									<Col md={12} xl={12}>
										<Form.Item label="Nombre de contacto" name="contactName">
											<Input />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={GUTTER} style={{ width: "100%" }}>
									<Col md={12} xl={12}>
										<Form.Item label="Email" name="contactEmail">
											<Input type="email" />
										</Form.Item>
									</Col>
									<Col md={12} xl={12}>
										<PhoneInput form={form} size="default" label="Teléfono" />
									</Col>
								</Row>

								<Row gutter={GUTTER} style={{ width: "100%" }}>
									<Col md={12} xl={12}>
										<Form.Item label="Dirección" name="address">
											<Input />
										</Form.Item>
									</Col>
									<Col md={12} xl={12}>
										<Form.Item label="Código postal" name="postalCode">
											<Input />
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={GUTTER} style={{ width: "100%" }}>
									<Col md={12} xl={12}>
										<Form.Item label="Cuit" name="cuit">
											<Input disabled />
										</Form.Item>
									</Col>
									<Col md={12} xl={12}>
										<Form.Item label="GLN" name="glnAnmat">
											<Input />
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={GUTTER} style={{ width: "100%" }}>
									<Col md={12} xl={12}>
										<Form.Item
											style={{
												display: "flex",
												alignItems: "center",
												height: "100%",
												pointerEvents: "none",
											}}
										>
											<Typo type="secondary" level={6}>
												{activeIsChain
													? "Es una cadena con sucursales"
													: "No es una cadena con sucursales"}
											</Typo>
										</Form.Item>
									</Col>
								</Row>
							</DataSection>
						</Inner>

						<Form.Item>
							<ButtonContainer>
								<SignInTemplate.AntdButton
									type="Primary"
									color="white"
									bg="Primary"
									htmlType="submit"
									loading={submitting}
								>
									{userdata?.guideProgress === 0
										? "Confirmar los datos"
										: "Guardar cambios"}
								</SignInTemplate.AntdButton>
							</ButtonContainer>
						</Form.Item>

						<Title type="primary">Droguerías Seleccionadas</Title>

						<Inner>
							<DistributorSection>
								<DistributorText
									onClick={handleClick}
									textColor={1}
									marginBottom={15}
								>
									<AiFillPlusCircle /> Agregar/Quitar Droguería
								</DistributorText>
								<Row
									gutter={GUTTER}
									style={{
										width: "100%",
										justifyContent: "center",
										alignItems: "center",
										alignContent: "center",
									}}
								>
									<Col md={17} xl={17}>
										<Form.Item name="distributorsList">
											<AntdTable
												dataSource={distributorsList}
												columns={columns}
												pagination={false}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Section>
									<DistributorText
										onClick={
											userdata?.guideProgress === 0 ? null : handleInfoClick
										}
										textColor={0}
									>
										<GoAlert /> Si se agrega una Droguería, se debe agregar el
										código de cliente en la configuración del Punto de Venta
									</DistributorText>
								</Section>
								<AntdModal
									title={`Droguerías`}
									visible={showDistributorsModal}
									onOk={handleOk}
									onCancel={handleCancel}
									width={900}
									centered={true}
									destroyOnClose={false}
								>
									<DistributorModal
										setDistributors={setDistributors}
										distributors={distributors}
										isSeller={userdata.isSeller}
										isOneMarket={userdata.isOneMarket}
									/>
								</AntdModal>
							</DistributorSection>
						</Inner>
						{userdata?.guideProgress === 0 && (
							<GuideText>
								Chequeá las Droguería seleccionadas y confirmá los datos
							</GuideText>
						)}
						<Form.Item>
							<ButtonContainer>
								<SignInTemplate.AntdButton
									type="Primary"
									color="white"
									bg="Primary"
									htmlType="submit"
									loading={submitting}
								>
									{userdata?.guideProgress === 0
										? "Confirmar los datos"
										: "Guardar cambios"}
								</SignInTemplate.AntdButton>
							</ButtonContainer>
						</Form.Item>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
