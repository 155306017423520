import React, { useState, useEffect } from "react";
import {
	useParams,
	Link,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { Form, Input, Button, Row, Col, Space, message, Select } from "antd";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import Main from "../../../../../layout/main/Main";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import {
	get_brand_by_id,
	update_brand,
} from "../../../../../../api/endpoints/brands";
import UploadFile from "../../../../../common/upload-file/UploadFile";
import { PreviewImage } from "./styles";

const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function UpdateBrand() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	const [submitting, setSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [initialValues, setInitialValues] = useState(null);

	useEffect(() => {
		const fetch_product_by_id = async () => {
			const data = await get_brand_by_id(id);

			const product = {
				name: data.name,
				isActive: data.isActive,
			};

			setImageUrl(data.photoUrl);

			setInitialValues(product);
		};

		if (id) {
			fetch_product_by_id();
		}
	}, []);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				name: values.name,
				isActive: values.isActive,
			};

			const formData = new FormData();
			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);

			formData.append("data", JSON.stringify(postData));

			await update_brand(id, formData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Producto creado con exito",
				"El producto se creo con exito"
			);
			navigate(`/admin/items`);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear el producto",
				"Un error ocurrio al intentar crear la cuenta de usuario, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	if (!id) navigate(`/admin/products?uri=${uri}`);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={`/admin/items?uri=${uri}`}>Productos y Marcas</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Editar Marca</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<Space
							direction="vertical"
							size="middle"
							style={{ display: "flex" }}
						>
							<Row gutter={GUTTER}>
								<Col span={6}>
									<Form.Item name="file" label="Imagen de la marca">
										<UploadFile onChange={handleImageChange} />
									</Form.Item>
								</Col>
								<Col span={4} offset={2}>
									<Form.Item
										label="Estado"
										name="isActive"
										defaultValue={initialValues.isActive}
										rules={[
											{
												required: true,
												message: "Este campo es obligatorio",
											},
										]}
									>
										<Select allowClear={true} placeholder="Estado de la marca">
											<Select.Option key={1} value={true}>
												Activa
											</Select.Option>
											<Select.Option key={2} value={false}>
												Inactiva
											</Select.Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>

							<Row>
								<Col>
									<Row>
										{imageUrl && <PreviewImage src={imageUrl} alt="avatar" />}
									</Row>
								</Col>
							</Row>

							<Row gutter={GUTTER}>
								<Col span={12}>
									<Form.Item
										label="Nombre de la Marca"
										name="name"
										rules={[
											{
												required: true,
												message: "Este campo es obligatorio",
											},
											{
												pattern: new RegExp(
													/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
												),
												message:
													"No se aceptan caracteres especiales. Solo letras y numeros",
											},
										]}
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>

							<Form.Item>
								<Button type="primary" htmlType="submit" loading={submitting}>
									Guardar Cambios
								</Button>
							</Form.Item>
						</Space>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
