import React, { useState, useEffect } from "react";
import { Form, Divider } from "antd";
import { get_cart } from "../../../../../../api/endpoints/cart";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import {
	CartAntdButton,
	Container,
	InfoIva,
	InfoNumber,
	InfoNumberItems,
	InfoSection,
	InfoSubSection,
	InfoSubTitle,
	InfoTitle,
	InfoTitleAlert,
	InfoTitleBuy,
	InfoTitleProductQuantity,
	InfoTitleProductQuantityItems,
	InfoTitleTotal,
	Inner,
	OptionsSection,
	PresentationTitle,
	ProductsSection,
	SelectContainer,
} from "./styles";
import ProductsTable from "./components/products-table/ProductsTable";
import { useCart } from "../../../../../../contexts/cartContext";
import Image from "../../../../../common/image/Image";
import { COLORS, Typo } from "../../../../../layout/theme";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { FaImages } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../../contexts/authContext";

export default function ZeroPointOfSale() {
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const [totals, setTotals] = useState({
		totalProducts: 0,
		subtotal: 0,
		saved: 0,
		total: 0,
	});
	const { setQty } = useCart();
	const { userdata } = useAuth();
	const [products, setProducts] = useState(null);
	let navigate = useNavigate();

	const columns = [
		{
			title: () => {
				return (
					<div
						style={{
							fontSize: "18px",
						}}
					>
						<FaImages style={{ color: COLORS.White }} />
					</div>
				);
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			heigth: 100,
			align: "center",
			render: (photoUrl) => <Image src={photoUrl} width={80} height={80} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "title",
			key: "title",
			width: 140,
			align: "center",
			render: (value, record) => (
				<>
					<InfoTitle>{value}</InfoTitle>
					<PresentationTitle>{record.presentationName}</PresentationTitle>
					<Typo type="muted" level={7}>
						{record.clientName}
					</Typo>
				</>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Precio</div>,
			dataIndex: "price",
			key: "price",
			align: "center",
			render: (value, record) => (
				<Typo type="muted" level={6}>
					$ {numberWithDotAndCommas(value.toFixed(2))}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "price",
			key: "discount",
			align: "center",
			render: (value, record) => (
				<Typo type="secondary" level={6}>
					{numberWithDotAndCommas(
						(100 - (record.estimatedPrice * 100) / value).toFixed(2)
					)}{" "}
					%
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Final</div>,
			dataIndex: "offerPrice",
			key: "offerPrice",
			align: "center",
			render: (value, record) => (
				<Typo type="primary" level={6}>
					$ {numberWithDotAndCommas(record.estimatedPrice.toFixed(2))}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cantidad</div>,
			dataIndex: "quantity",
			key: "quantity",
			align: "center",
			render: (quantity, record, index) => (
				<SelectContainer>{quantity}</SelectContainer>
			),
		},
	];

	useEffect(() => {
		if (!products) return;

		const totalProducts = products.reduce(
			(acc, item) => acc + item.quantity,
			0
		);

		const totalItems = products.length;

		const subtotal = products.reduce(
			(acc, item) => acc + item.quantity * item.price,
			0
		);

		const offers = products.reduce(
			(acc, item) =>
				acc +
				(item.estimatedPrice
					? item.quantity * item.estimatedPrice
					: item.quantity * item.price),
			0
		);

		const saved = Number(
			Math.round(parseFloat(subtotal - offers + "e" + 2)) + "e-" + 2
		).toFixed(2);

		const total = Number(
			Math.round(parseFloat(((subtotal - saved) * 100) / 100 + "e" + 2)) +
				"e-" +
				2
		).toFixed(2);

		setTotals({
			totalProducts,
			totalItems,
			subtotal,
			total,
			saved,
		});

		setQty(totalProducts);
	}, [products]);

	useEffect(() => {
		const fetch_data = async () => {
			const res = await get_cart();
			setProducts(
				res.cart?.items?.map((c) => ({
					key: c.product.id,
					id: c.productId,
					productId: c.productId,
					photoUrl: c.product.photoUrl,
					ean13: c.product.ean13,
					presentationName: c.product.presentation?.name,
					quantity: c.quantity,
					price: c.product.price,
					offerPrice: c.product.offerPrice,
					generalDiscount: c.product.client.generalDiscount,
					discount: c.product.discount,
					offerFrom: c.product.offerFrom,
					offerTo: c.product.offerTo,
					total: c.product.price * c.quantity,
					title: c.product.title,
					clientName: c.product.client.name,
					minQuantity: c.product.minQuantity,
					maxQuantity: c.product.maxQuantity,
					triggerQty: c.product.triggerQty,
					triggerDiscount: c.product.triggerDiscount,
					saleCommision: c.product.saleCommision,
					campaignDiscount: 0,
					quantityDiscount: 0,
					estimatedPrice:
						c.quantity >= c.product.triggerQty
							? c.product.offerPrice -
							  c.product.offerPrice * (c.product.triggerDiscount / 100)
							: c.product.offerPrice,
					distributorId: c.distributor?.id,
					distributorName: c.distributor?.name,
					distributors: c.product.distributors
						?.map((d) => ({
							key: d.distributor.id,
							id: d.distributor.id,
							name: d.distributor.name,
							isActive: d.distributor.isActive,
							code: d.code,
						}))
						?.filter((o) => o.code !== "" && o.code !== null),
				})) || []
			);
		};

		fetch_data();
	}, []);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error con el carro de compras",
				"Un error ocurrio al intentar avanzar con el carro de compras, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handlePosCreate = () => {
		navigate(`/admin/pointsofsale/create?uri=cart`);
	};

	return (
		<Form
			form={form}
			name="create-cart"
			onFinish={onFinish}
			autoComplete="off"
			requiredMark={false}
			layout="vertical"
			initialValues={initialValues}
		>
			{userdata &&
			((userdata.isOneMarket && userdata.p_shop) ||
				(!userdata.isOneMarket && userdata.isSeller && userdata.p_shop)) ? (
				<OptionsSection>
					<InfoTitleAlert style={{ marginTop: 30 }}>
						Para poder continuar con el pedido debe crear un Punto de Venta para
						esta farmacia
					</InfoTitleAlert>
				</OptionsSection>
			) : (
				<OptionsSection>
					<InfoTitleAlert>
						Para poder continuar con el pedido debe crear un Punto de Venta
					</InfoTitleAlert>
					<CartAntdButton
						type="Primary"
						color="white"
						bg="Primary"
						loading={submitting}
						style={{ width: "180px" }}
						onClick={handlePosCreate}
					>
						Crear Punto de Venta
					</CartAntdButton>
				</OptionsSection>
			)}
			<Inner>
				<ProductsSection>
					<ProductsTable data={products} columns={columns} />
				</ProductsSection>
				<InfoSection>
					<Container>
						<InfoSubSection>
							<InfoTitleBuy>DETALLE TRANSFER:</InfoTitleBuy>
						</InfoSubSection>
						<Divider />
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0 40px 0 40px",
							}}
						>
							<InfoTitleProductQuantity>
								Cantidad de productos:
							</InfoTitleProductQuantity>
							<InfoNumber>{totals.totalItems}</InfoNumber>
						</InfoSubSection>
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "20px 40px 0px 40px",
							}}
						>
							<InfoTitleProductQuantityItems>
								Cantidad de unidades:
							</InfoTitleProductQuantityItems>
							<InfoNumberItems>{totals.totalProducts}</InfoNumberItems>
						</InfoSubSection>
						<Divider />
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0px 40px 30px 40px",
							}}
						>
							<InfoSubTitle>SUBTOTAL:</InfoSubTitle>
							<InfoSubTitle>
								$ {numberWithDotAndCommas(totals.subtotal.toFixed(2))}
							</InfoSubTitle>
						</InfoSubSection>
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0 40px 0 40px",
							}}
						>
							<InfoTitle>AHORRO:</InfoTitle>
							<InfoTitle>$ {numberWithDotAndCommas(totals.saved)}</InfoTitle>
						</InfoSubSection>
						<Divider />
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0px 40px 30px 40px",
							}}
						>
							<InfoTitleTotal>TOTAL:</InfoTitleTotal>
							<InfoTitleTotal>
								$ {numberWithDotAndCommas(totals.total)}
							</InfoTitleTotal>
						</InfoSubSection>
						<InfoIva translate="no">IVA no incluido</InfoIva>
						<InfoIva translate="no">
							Los precios PVP, PSD y PSL son de referencia y pueden variar
							dependiendo de la droguería seleccionada.
						</InfoIva>
						<Divider />
						{/* <InfoSubSection>
							<Form.Item>
								<CartAntdButton
									type="Primary"
									color="white"
									bg="Primary"
									htmlType="submit"
									loading={submitting}
									style={{ width: "150px" }}
								>
									Siguiente
								</CartAntdButton>
							</Form.Item>
						</InfoSubSection> */}
					</Container>
				</InfoSection>
			</Inner>
		</Form>
	);
}
