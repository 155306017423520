import React, { useState, useEffect, useMemo } from "react";
import { Modal, Input } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
	AntdButton,
	AntdTable,
	Buttons,
	ButtonsLeft,
	ConfirmButton,
	Span,
	SpanCointainer,
	TableContainer,
	UploadFile,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import { COLORS, Typo } from "../../../layout/theme";
import { useAuth } from "../../../../contexts/authContext";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
	check_new_mobile_discounts,
	export_mobile_discounts,
	get_products,
	get_products_header_info,
	update_mobile_discounts,
} from "../../../../api/endpoints/products";
import moment from "moment";
import Image from "../../../common/image/Image";
import { FaImages } from "react-icons/fa";
import Main from "../../../layout/main/Main";
import debounce from "lodash/debounce";
import AntdSearch from "../../../common/antd-search/AntdSearch";
import { calculatePercentage } from "../../../../utils/calculatePrice";

let productIndex = null;

export default function LaboratoryProductDiscountList({ clientId }) {
	const navigate = useNavigate();
	const { userdata } = useAuth();
	const [data, setData] = useState(false);
	const [state, setState] = useState("PUBLISHED");
	const [hasChanges, setHasChanges] = useState(false);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	const { confirm } = Modal;

	const fetch_discounts_today = async (search = "") => {
		const res = await get_products(search, state, clientId);
		const newData = res
			.map((i) => ({
				title: i.title,
				photoUrl: i.photoUrl,
				ean13: i.ean13,
				mobileDiscount: i.mobileDiscount,
				mobileTriggerQty: i.mobileTriggerQty,
				mobileTriggerDiscount: i.mobileTriggerDiscount,
				productPrice: i.price,
				clientGeneralDiscount: i.clientGeneralDiscount,
				hasChanges: false,
			}))
			.sort((a, b) =>
				a.title.toUpperCase() > b.title.toUpperCase()
					? 1
					: b.title.toUpperCase() > a.title.toUpperCase()
					? -1
					: 0
			);
		setData(newData);

		const thereIsChanges = res.some((i) => i.hasChanges === true);
		setHasChanges(thereIsChanges);
	};

	const fetch_products_header = async () => {
		const res = await get_products_header_info(clientId);
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_discounts_today();
		fetch_products_header();
	}, [clientId, state]);

	const onInputChange = (key, index) => (e) => {
		const newData = [...data];
		if (key === "mobileDiscount") {
			newData[index]["mobileDiscount"] = Number(e.target.value);
		}
		if (key === "mobileTriggerDiscount") {
			newData[index]["mobileTriggerDiscount"] = Number(e.target.value);
		}
		if (key === "mobileTriggerQty") {
			if (Number(e.target.value) === 0) {
				newData[index]["mobileTriggerDiscount"] = 0;
			}
			newData[index]["mobileTriggerQty"] = Number(e.target.value);
		}

		newData[index]["hasChanges"] = true;
		setData(newData);
		setHasChanges(true);
	};

	const fetch_export_mobile_discounts = async () => {
		setLoading(true);
		try {
			const res = await export_mobile_discounts(clientId);
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const fileName = moment().format("DD-MM-YYYY") + ".xlsx";
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const handleUpload = async (e) => {
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("data", clientId ?? userdata.clientId);
			formData.append("file", e.fileList[e.fileList.length - 1].originFileObj);
			const res = await check_new_mobile_discounts(formData);
			setData(
				res.productsToChange.map((i) => ({
					...i,
				}))
			);
			const thereIsChanges = res.productsToChange.some(
				(i) => i.hasChanges === true
			);
			setHasChanges(thereIsChanges);
		} catch (err) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Los precios no pudieron actualizarse",
				"Verifique su archivo de excel, si sigue con problemas contacte con soporte a soporte@checkpos.com"
			);
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = () => {
		confirm({
			title: "¿Seguro que quiere publicar estos descuentos?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El cambio se efectuará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				confirmDiscounts();
			},
		});
	};

	const confirmDiscounts = async () => {
		setLoading(true);
		try {
			const res = await update_mobile_discounts({
				discounts: data,
				clientId: clientId,
			});
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Los descuentos se actualizaron correctamente",
				"Los descuentos se actualizaron correctamente"
			);
			fetch_discounts_today();
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Los descuentos no se pudieron actualizar correctamente, contacte con soporte a soporte@checkpos.com",
				"Los descuentos se se pudieron actualizar correctamente, contacte con soporte a soporte@checkpos.com"
			);
		} finally {
			setLoading(false);
		}
	};

	const columns = [
		{
			title: () => {
				return (
					<div
						style={{
							fontSize: "18px",
						}}
					>
						<FaImages style={{ color: COLORS.White }} />
					</div>
				);
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			width: 150,
			align: "center",
			render: (value, record) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "title",
			key: "title",
			width: 400,
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Ean13</div>,
			dataIndex: "ean13",
			key: "ean13",
			width: 180,
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Descuento PSL mobile</div>
			),
			dataIndex: "mobileDiscount",
			key: "mobileDiscount",
			width: 220,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						display: "flex",
						alignItems: "center",
						gap: 20,
						fontWeight: "600",
					}}
				>
					<Input
						type="number"
						value={value}
						onChange={onInputChange(
							"mobileDiscount",
							page === 1 ? index : (page - 1) * 25 + index
						)}
						addonAfter="%"
						style={{ width: "100px" }}
					/>
					{record?.mobileDiscount > 0 && (
						<Typo type="terciary" lineHeight={1} level={8} translate="no">
							PVP -
							{calculatePercentage(
								parseFloat(record?.productPrice),
								parseFloat(record?.clientGeneralDiscount),
								parseFloat(record?.mobileDiscount),
								0,
								0
							)}
							%
						</Typo>
					)}
				</div>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Descuento PSL por cantidad</div>
			),
			dataIndex: "mobileTriggerDiscount",
			width: 400,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						display: "flex",
						alignItems: "center",
						gap: 20,
						fontWeight: "600",
					}}
				>
					<Input
						type="number"
						min={0}
						value={record.mobileTriggerQty}
						onChange={onInputChange(
							"mobileTriggerQty",
							page === 1 ? index : (page - 1) * 25 + index
						)}
						addonAfter="un."
						style={{ width: "100px" }}
					/>
					{record?.mobileTriggerQty > 0 && (
						<Input
							type="number"
							min={0}
							value={record.mobileTriggerDiscount}
							onChange={onInputChange(
								"mobileTriggerDiscount",
								page === 1 ? index : (page - 1) * 25 + index
							)}
							addonAfter="%"
							style={{ width: "100px" }}
						/>
					)}
					{record?.mobileTriggerDiscount > 0 && (
						<Typo type="terciary" lineHeight={1} level={8} translate="no">
							PVP -
							{calculatePercentage(
								parseFloat(record?.productPrice),
								parseFloat(record?.clientGeneralDiscount),
								parseFloat(record?.mobileDiscount),
								parseFloat(record?.mobileTriggerDiscount),
								0
							)}
							%
						</Typo>
					)}
				</div>
			),
		},
	];

	const eventHandler = (e) => {
		if (hasChanges) {
			confirm({
				title: "Existen cambios sin guardar",
				icon: <InfoCircleOutlined style={{ color: COLORS.Warning }} />,
				content:
					"Efectuar la búsqueda originará una pérdida de los datos sin guardar",
				okText: "Buscar",
				okType: "default",
				cancelText: "Cancelar",
				onOk: () => {
					fetch_discounts_today(e.target.value);
				},
			});
		} else {
			fetch_discounts_today(e.target.value);
		}
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 350),
		[hasChanges]
	);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Descuento por producto
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<AntdSearch
					allowClear
					placeholder="Buscar productos"
					onChange={debouncedEventHandler}
					width={400}
				/>
				<Buttons clientId={clientId}>
					<ButtonsLeft>
						<AntdButton
							loading={loading}
							onClick={fetch_export_mobile_discounts}
						>
							Exportar
						</AntdButton>
						<UploadFile
							multiple={false}
							showUploadList={false}
							beforeUpload={() => false}
							action={null}
							onChange={handleUpload}
							loading={loading}
						>
							Importar
						</UploadFile>
						{clientId && (
							<ConfirmButton clientId={clientId}>
								{hasChanges && (
									<AntdButton onClick={handleSubmit} loading={loading} success>
										Confirmar cambios
									</AntdButton>
								)}
							</ConfirmButton>
						)}
					</ButtonsLeft>
				</Buttons>
				{!clientId && (
					<ConfirmButton>
						{hasChanges && (
							<AntdButton onClick={handleSubmit} loading={loading} success>
								Confirmar cambios
							</AntdButton>
						)}
					</ConfirmButton>
				)}
				<SpanCointainer clientId={clientId}>
					<Span onClick={() => setState("PUBLISHED")}>
						Publicados ({headerInfo?.totalPublished})
					</Span>
					<Span onClick={() => setState("DRAFT")}>
						Borradores ({headerInfo?.totalDraft})
					</Span>
					{!clientId && (
						<Span
							onClick={() => navigate("/admin/items")}
							style={{ textDecoration: "underline" }}
						>
							Productos
						</Span>
					)}
				</SpanCointainer>
				{!data ? (
					<div>Loading...</div>
				) : (
					<TableContainer>
						<AntdTable
							dataSource={data}
							columns={columns}
							loading={loading}
							pagination={{
								pageSize: 25,
								showSizeChanger: false,
								onChange: (page) => setPage(page),
							}}
							rowClassName={(record) =>
								record.hasChanges ? "hasChanges" : null
							}
							// scroll={{
							// 	x: 1500,
							// 	y: 450,
							// }}
						/>
					</TableContainer>
				)}
			</Main.Body>
		</Main>
	);
}
