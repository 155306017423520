import styled from "styled-components/macro";
import { COLORS } from "../../../layout/theme";

export const Body = styled.div`
	margin-top: 10px;
	background-color: ${COLORS.White};
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;
	padding: 3rem 4rem 3rem 4rem;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

	.ant-form {
		flex: 1;
	}

	.ant-form-item {
		flex: 1;
	}

	@media only screen and (min-width: 701px) and (max-width: 900px) {
		width: 60%;
		padding: 3rem 2rem 3rem 2rem;
	}

	@media only screen and (min-width: 414px) and (max-width: 700px) {
		width: 80%;
		padding: 3rem 2rem 3rem 2rem;
	}

	@media only screen and (min-width: 250px) and (max-width: 413px) {
		width: 90%;
		padding: 3rem 2rem 3rem 2rem;
	}
`;

export const InnerForm = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Main = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

// no borrar
// export const InnerForm = styled.div`
//   display: flex;
//   gap: 0px;
//   justify-content: flex-start;
// `;

// borrar
// export const Title = styled.div``;
// export const Logo = styled.div``;
// export const Options = styled.div`
//   display: flex;
//   flex-direction: column;
// `;
// export const Provider = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 10px;
//   height: 30px;
//   cursor: pointer;
// `;
// export const Providers = styled.div``;

export const ButtonSection = styled.div`
	filter: drop-shadow(0px 7px 7px rgba(0, 0, 0, 0.25));
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	text-align: center;

	svg {
		color: ${COLORS.Primary};
		scale: 1.2;
	}
`;

export const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	gap: 10px;
`;

export const Section = styled.div`
	display: flex;
	gap: 20px;

	@media only screen and (min-width: 250px) and (max-width: 413px) {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
`;
