import React from "react";
import Image from "../image/Image";
import { Content, Inner, Logo, Message } from "./styles";
import MarketLogoBox from "../../../assets/images/logo-box.png";

export default function LoadingScreen({ message }) {
	return (
		<Inner>
			<Content>
				<Logo>
					<Image src={MarketLogoBox} alt="OneMarket logo" />
				</Logo>
				<Message>{message}</Message>
			</Content>
		</Inner>
	);
}
