import React from "react";
import { Typo } from "../../../../../layout/theme";
import { AntdButton, Arrow, ModalContainer, Section } from "./styles";
import MarketLogo from "../../../../../../assets/images/logo.png";
import Image from "../../../../../common/image/Image";
import { FaArrowDown } from "react-icons/fa";

export default function WelcomeModal({ redirectToGuide, userdata, skipGuide }) {
	const handleModalClick = () => {
		redirectToGuide();
	};

	const handleSkipClick = () => {
		skipGuide();
	};

	return (
		<ModalContainer>
			<Typo type="primary" level={2}>
				Te damos la bienvenida a
			</Typo>
			<Image src={MarketLogo} alt="Logo Market" height={60} />

			{userdata?.isSeller ? (
				<Typo type="darkMuted" level={5}>
					Para que puedas disfrutar de nuestro sitio y acceder a todas sus
					funcionalidades, <br />
					te recomendamos comenzar por la "Guia de Inicio". <br />
					Hace click en el siguiente botón para acceder a ella
				</Typo>
			) : (
				<Typo type="darkMuted" level={5}>
					Para que puedas disfrutar de nuestro sitio y acceder a los mejores
					descuentos, <br />
					te recomendamos comenzar por la "Guia de Inicio". <br />
					Hace click en el siguiente botón para acceder a ella
				</Typo>
			)}
			<Section>
				<Arrow>
					<FaArrowDown />
				</Arrow>

				<AntdButton
					type="primary"
					bg="Primary"
					color="White"
					onClick={handleModalClick}
					height={60}
					width={180}
					borderRadius={20}
				>
					Ir a la Guía de Inicio
				</AntdButton>
			</Section>
			<AntdButton
				type="link"
				onClick={handleSkipClick}
				borderRadius={20}
				height={60}
				width={180}
			>
				Omitir
			</AntdButton>
		</ModalContainer>
	);
}
