import React from "react";
import { Link } from "react-router-dom";
import { Typo } from "../../../../../layout/theme";
import { Inner, Section } from "./styles";

export default function TermsModal({ user }) {
	function Mailto({ email, subject, body, ...props }) {
		return (
			<a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
				{props.children}
			</a>
		);
	}

	return (
		<Inner>
			<Section>
				<Typo type="primary" level={2}>
					Términos y Condiciones
				</Typo>
				<Typo level={5}>
					Los Términos y Condiciones que se describen a continuación tienen por
					objeto proporcionar información para que de manera anticipada la
					persona que desee ingresar a{" "}
					<Link
						to={{ pathname: "https://www.checkpos.com.ar" }}
						target="_blank"
					>
						www.checkpos.com.ar
					</Link>{" "}
					a partir de este momento ( “EL USUARIO”) disponga del total
					conocimiento de las regulaciones y requerimientos del sitio. También
					se propone regular el uso que realicen LOS USUARIOS que ingresen al
					Marketplace WEB de ONETRASNFER.COM.AR (en adelante, "MARKET PLACE "),
					propiedad de ONE MARKET VENTURES S.A. (En adelante "CHECKPOS
					TRANSFER"), y domicilio en la calle Helguera 2840 de la Ciudad
					Autonoma de Buenos Aires
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Propósito:
				</Typo>
				<Typo level={5}>
					One Market Ventures S.A, sociedad legalmente constituida en Argentina,
					desarrollo un MARKETPLACE, el cual es ofrecido para utilizar de manera
					gratuita para personas mayores de edad que puedan comprobar ser
					propietarios de negocios farmacéuticos registrados y habilitados por
					los Entes nacionales de salud, empleados farmacéuticos habilitados,
					distribuidoras y empresas productoras de productos de venta
					farmacéutica (laboratorios y otras), en el territorio de la República
					Argentina. El sólo hecho de acceder a el MARKETPLACE implica una
					manifestación de conformidad con el cumplimiento de los Términos y
					Condiciones del uso de la aplicación Web, Aplicación Mobile, y todas
					las opciones digitales implementadas para tal fin.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Objeto y uso del Marketplace:
				</Typo>
				<Typo level={5}>
					CheckposTransfer es una plataforma que promociona ofertas de
					medicamentos, productos cosméticos y productos afines a la salud
					(pueden ser productos tecnológicos, alimenticios u otros), para ello
					las personas que deseen registrarse como usuarios de la plataforma
					deberán estar reconocidos legalmente por la secretaría de salud
					Pública, preferentemente ser farmacéuticos matriculados o registrados
					y sus locales comerciales (farmacias o perfumerías) estar debiamente
					habilitados. La sola utilización del sitio atribuye la condición de
					usuario del MARKETPLACE y expresa la aceptación plena y sin reservas
					por éste de todas y cada una de las Condiciones Generales en la
					versión publicada en el momento mismo en que el Usuario acceda al
					sitio. En consecuencia, el Usuario debe leer atentamente las
					Condiciones Generales ya que con su registro estará otorgando su
					consentimiento a los Términos y Condiciones de uso.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Informaciones expuestas en la WEB, el MARKETPLACE y sus sub-sitios
					online.
				</Typo>
				<Typo level={5}>
					Algunos datos e informaciones expuestas en la web de Checkpos,EL
					MARKETPLACE y posibles publicidades, son suministrados por terceros,
					Laboratorios y empresas proveedoras de productos los cuales se ofrecen
					en EL MARKETPLACE. checkpos no es responsable de los descuentos
					ofrecidos en el MARKETPLACE, tampoco es responsable del precio de los
					productos promocionados, sus descuentos, Stocks distribuidos ni de la
					logística de entrega de los mismos, los cuales serán responsables las
					empresas oferentes, las empresas de logística contratadas por ellos y
					elegidas por el usuario y los distribuidores y droguerías
					seleccionadas por EL USUARIO para tal fin. La Validez de los datos
					aportados por estos serán de exclusiva responsabilidad de los mismos
					aportantes Algunos datos e informaciones que son expuestos en el
					MARKETPLACE son suministrados por diversas fuentes y checkpos no
					garantizan ni se responsabiliza respecto de la veracidad de dichos
					datos e informaciones
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Protección de Datos
				</Typo>
				<Typo level={5}>
					Checkpos conoce la importancia de la seguridad y confidencialidad de
					la información. Por ello, en el momento en que usted transfiere
					información a nuestro servidor, la misma es incorporada a nuestros
					archivos internos con la exclusiva finalidad de ser utilizada para
					poder administrar y organizar el funcionamiento del MARKETPLACE y
					eventualmente, ofrecer información vinculada a sus productos y
					servicios. OneTrasnfer toma los recaudos de seguridad necesarios para
					proteger su información del acceso de terceros o personas no
					autorizadas. Al enviar sus datos a través de nuestro sitio, ud. está
					manifestando en forma expresa el consentimiento para el tratamiento de
					dicha información y se responsabiliza por su veracidad. Usted podrá,
					en cualquier momento, con intervalos no inferiores a 6 (seis meses),
					salvo que se acredite un interés legítimo al efecto conforme lo
					establecido en el artículo 14, inciso 3 de la Ley N°25.326, tener
					acceso a sus datos y/o solicitar la eliminación, corrección o
					modificación de los mismos. Será requerido en estos casos, que envíe
					un correo electrónico a la dirección{" "}
					<Mailto email="contacto@checkpos.com" subject="" body="">
						contacto@checkpos.com
					</Mailto>{" "}
					con su solicitud.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Conservación de Datos de Usuarios.
				</Typo>
				<Typo level={5}>
					checkpos conservará su información personal por el término necesario
					para el cumplimiento de las finalidades descriptas en la presente
					Política de Privacidad y/u obligaciones legales. La información
					personal que se recabe del Usuario será destruida cuando haya dejado
					de ser necesaria o pertinente a los fines para los cuales hubiese sido
					recolectada, salvo que exista una obligación legal de conservarla por
					un término mayor.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Modificaciones del acuerdo
				</Typo>
				<Typo level={5}>
					Checkpos podrá modificar en cualquier momento los términos y
					condiciones del sitio y notificará los cambios al Usuario registrado,
					publicando una versión actualizada de dichos términos y condiciones en
					este sitio web. Si el usuario no aceptara las modificaciones
					introducidas, dentro de los 5 (cinco) días siguientes a la publicación
					de las mismas, deberá comunicarlo por e-mail a contacto@checkpos.com,
					en ese caso,checkpos quedará liberado de prestar al Usuario Registrado
					el servicio de la Plataforma. Vencido este plazo, el silencio del
					Usuario se considerará como aceptación de los nuevos términos y
					condiciones, siéndole plenamente aplicables, y manteniendo el vínculo
					entre ambas partes.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Contenidos - Propiedad intelectual
				</Typo>
				<Typo level={5}>
					Se entenderá por "Contenidos" a todas las informaciones, mensajes,
					gráficos, dibujos, diseños, logotipos, nombres, marcas, archivos de
					sonido y/o imagen, fotografías, grabaciones, software y, en general,
					cualquier clase de material accesible a través del MARKETPLACE, que se
					encuentran protegidos por las leyes de derecho de autor y propiedad
					industrial e intelectual, reconocidas en los ámbitos nacional e
					internacional. Checkpos no será responsable en forma alguna por la
					propiedad intelectual de los Contenidos provistos por Terceros,
					sirviendo los presentes Términos y Condiciones de Uso como declaración
					expresa y manifiesta de Terceros en cuanto a su total y exclusiva
					responsabilidad por la violación de los derechos de propiedad
					intelectual, patentes y/o marcas que sean propiedad de otras personas
					físicas o jurídicas. Los Terceros asumen en este acto la
					responsabilidad de mantener indemne a Checkpos por cualquier acción,
					reclamo o gasto incurrido como consecuencia de una violación por parte
					de los Terceros de cualquier derecho de propiedad intelectual.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Uso de los Contenidos
				</Typo>
				<Typo level={5}>
					El Usuario es el único responsable de utilizar los Contenidos de forma
					correcta y lícita y, en particular, se compromete a abstenerse de
					<br />
					<br />
					<br />
					(a) suprimir, eludir o manipular el "copyright" y demás datos
					identificatorios de los derechos de Checkpos sobre el MARKETPLACE.
					<br />
					<br />
					(b) emplear los Contenidos y, en particular, la información de
					cualquier clase obtenida a través del MARKETPLACE o de los Servicios,
					para remitir publicidad, comunicaciones con fines de venta directa o
					con cualquier otra clase de finalidad comercial.
					<br />
					<br />
					Checkpos NO SERÁ RESPONSABLES POR EL USO INDEBIDO QUE HAGAN LOS
					USUARIOS DEL CONTENIDO INCLUÍDO EN LA PLATAFORMA. EL USUARIO ES
					CONSCIENTE Y ACEPTA VOLUNTARIAMENTE QUE EL USO QUE HACE DEL SITIO Y DE
					SUS CONTENIDOS SON SIEMPRE DE SU ÚNICA Y EXCLUSIVA RESPONSABILIDAD.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Publicidad
				</Typo>
				<Typo level={5}>
					El Usuario acepta que la publicidad de terceros que aparezca en la
					pantalla es parte inseparable del sitio.
					<br />
					<br />
					Checkpos NO GARANTIZA LA VERACIDAD DE LA PUBLICIDAD DE TERCEROS Y NO
					SERÁ RESPONSABLE POR LA CORRESPONDENCIA, CONTRATOS O ACTOS QUE EL
					USUARIO CELEBRE CON TERCEROS.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Exclusión de garantías y de responsabilidad.
				</Typo>
				<Typo level={5}>
					Checkpos tomará todos los recaudos a su alcance para evitar, reducir
					y/o eliminar toda posible infección, propagación y/o transmisión de
					virus informáticos en todas sus formas y versiones en los Contenidos
					de el MARKETPLACE.
					<br />
					<br />
					Sin embargo, no garantiza en forma alguna la inexistencia de virus ni
					de otros elementos en los Contenidos que pudieren producir
					alteraciones en el sistema informático del Usuario, ya sea en el
					software o en el hardware, o en los documentos electrónicos y
					documentos almacenados en su sistema informático. Checkpos no tendrá
					responsabilidad alguna por los daños y perjuicios de cualquier
					naturaleza que puedan ocasionarse por los motivos expuestos
					anteriormente. En caso de que el Usuario recibiere algún virus por
					cualquier medio relacionado con el MARKETPLACE, deberá informar de
					inmediato a la casilla de correo:{" "}
					<Mailto email="contacto@checkpos.com" subject="" body="">
						contacto@checkpos.com
					</Mailto>
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Denegación y retirada del acceso a La Plataforma y/o a Servicios.
				</Typo>
				<Typo level={5}>
					Checkpos se reserva el derecho a denegar o retirar el acceso del
					MARKETPLACE, en cualquier momento y sin necesidad de preaviso a
					aquellos Usuarios que incumplan los Términos y condiciones.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Registración
				</Typo>
				<Typo level={5}>
					Para ser un Usuario del MARKETPLACE y utilizar los servicios que
					ofrece es necesario completar el formulario de registración.
					<br />
					<br />
					El usuario siempre tendrá la opción de elegir si desea o no
					proporcionar información personal, en caso de elegir no proporcionar
					la información personal solicitada, es posible que e no pueda acceder
					a ciertas secciones del MARKETPLACE o utilizar los servicios que se
					brindan.
					<br />
					<br />
					A los efectos del registro del usuario y la Farmacia se le solicitará
					que cree una cuenta, debiendo ingresar en el Formulario de
					Registración (en adelante, el “Formulario de Registración”) la
					totalidad de los siguientes datos de carácter personal (los “Datos
					Personales”): Nombre y Apellido, Dirección de correo electrónico
					(e-mail) y Teléfono.
					<br />
					<br />
					Checkpos Validará dentro de un tiempo razonable, la veracidad de los
					datos aportados por El Usuario. Luego de esto, El usuario recibirá un
					correo con la Verificación aprobada y checkpos le solicitará los datos
					de la farmacia.
					<br />
					<br />
					Razón Social, CUIT, Dirección de Entrega, Nombre comercial de la
					farmacia. Durante el registro de la Farmacia, podrá seleccionar la
					Droguerías con la cual desea operar, completando su número de cuenta.
					<br />
					<br />
					Una vez validados estos datos por checkpos, recibirá un correo
					electrónico que le permitirá empezar a operar.
					<br />
					<br />
					Los Datos Personales de los Usuarios serán utilizados a los efectos de
					prestar los servicios del MARKETPLACE. Al enviar sus datos, el Usuario
					manifiesta en forma expresa el consentimiento para el tratamiento de
					dicha información para cumplir con la finalidad de la aplicación y su
					correcto funcionamiento.
					<br />
					<br />
					El Usuario Registrado será el único responsable de mantener los datos
					actualizados, de manera que la información permanezca exacta, veraz,
					actualizada y completa.En caso que los datos no puedan ser verificados
					o la misma sea inexacta, checkpos se reserva el derecho de excluir a
					todo Usuario que haya facilitado datos falsos.
					<br />
					<br />
					El Usuario Registrado se obliga a mantener la confidencialidad de su
					Clave y será responsable por todos los cambios efectuados en su
					Cuenta, pues el acceso a la misma está restringido al ingreso y uso de
					su Clave, de conocimiento exclusivo del Usuario Registrado.
					<br />
					<br />
					Checkpos se reserva el derecho de rechazar cualquier solicitud de
					registro o de cancelar una registración previamente aceptada, sin
					previo aviso.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					USO DE LA PLATAFORMA.
				</Typo>
				<Typo level={5}>
					a. CONEXIÓN DE INTERNET
					<br />
					<br />
					Es requerimiento obligatorio, para el funcionamiento del Marketplace y
					su coneccíon con el usuario que accede al sistema, que la farmacia y
					el computador o tableta que se conecte al MARKETPLACE dispongan de
					conexión a internet de calidad.
					<br />
					<br />
					El Usuario será responsable de la disponibilidad y costo de la
					conexión de Internet del dispositivo en el cual utiliza el
					MARKETPLACE. La Plataforma no estará disponible si su dispositivo no
					está conectado a Internet por cualquier motivo.
					<br />
					<br />
					b. FACTURACION Y GESTION DE ENTREGA
					<br />
					<br />
					Los pedidos realizados en el MARKETPLACE son transferidos a la
					Droguería seleccionada, la cual es responsable del precio final y
					condiciones de pago finales (según lo que el Usuario tenga convenido
					habitualmente con la Farmacia), así como el tiempo y forma de entrega.
					OneTranser no será responsables frente a los usuarios por: (a) el
					cumplimiento por parte de cada droguería en la cantidad y tiempo de
					entrega de los pedidos efectuados por las Farmacias; (b) las
					diferencias de precio que pudieran existir entre el precio de venta al
					público (PVP) publicado en la plataforma al momento de realizar el
					pedido y el efectivamente facturado a cada usuario por sus droguerías
					elegidas; (c) la falta de cumplimiento por parte de las farmacias,
					droguerías y/o distribuidores a sus obligaciones contractuales,
					legales, laborales, impositivas, de seguridad social; (d) los pagos,
					cobros, incumplimientos de las partes y reclamos por los pedidos
					efectuados a través del MARKETPLACE; y (e) cualquier otro supuesto de
					supuestos de caso fortuito o fuerza mayor.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Responsabilidad
				</Typo>
				<Typo level={5}>
					El MARKETPLACE{" "}
					<Link to={{ pathname: "https://www.checkpos.com" }} target="_blank">
						www.checkpos.com
					</Link>{" "}
					es gratuito y se limita a poner a disposición de las farmacias un
					medio para que las mismas realicen pedidos a las droguerías
					seleccionadas, Checkpos, sus afiliadas, directores, gerentes,
					empleados y subcontratistas no asumirán responsabilidad alguna frente
					a los usuarios por:
					<br />
					<br />
					(a) cualquier daño o perjuicio derivado del mal uso de la plataforma.
					<br />
					<br />
					(b) el incumplimiento por parte de cada droguería en la cantidad y
					tiempo de entrega de los pedidos efectuados por las farmacias.
					<br />
					<br />
					(c) cualquier daño, perjuicio o pérdida al usuario causados por fallas
					en el sistema, en el servidor o internet del servidor que aloja El
					MARKETPLACE.
					<br />
					<br />
					(d) El incumplimiento por parte de las droguerías o distribuidores a
					sus obligaciones contractuales, legales, laborales, impositivas, de
					seguridad social que afecten la entrega o la calidad de la misma
					frente a los usuarios registrados en El Marketplace.
					<br />
					<br />
					(e) actos o hechos ilícitos imputables a, o sufridos por el usuario
					y/o un tercero que utilice la cuenta de la tienda;
					<br />
					<br />
					(f) los plazos de pagos, situaciones de crédito de la farmacia con las
					droguerías y reclamos por las transacciones efectuadas a través del
					MARKETPLACE; y (g) supuestos de fuerza mayor o caso fortuito.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					Ley aplicable y jurisdicción
				</Typo>
				<Typo level={5}>
					Estas Condiciones Generales se rigen en su totalidad por la ley de la
					República Argentina, con renuncia expresa a cualquier otro fuero o
					competencia que pudiera corresponder, se someten Tribunales Nacionales
					Ordinarios de la Ciudad autónoma de Buenos Aires.
				</Typo>
			</Section>
		</Inner>
	);
}
