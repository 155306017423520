import React, { useEffect, useState } from "react";
import {
	Link,
	useParams,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { Form, Input, Row, Col, Space, Modal, Select } from "antd";
import { AntdButton, InnerForm, ProductData, PublishSettings } from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import COLORS from "../../../../layout/theme/colors/Colors";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Typo } from "../../../../layout/theme";
import {
	get_administration_key_by_id,
	update_administration_key,
} from "../../../../../api/endpoints/administrationKeys";
import { generateRandomKey } from "../../../../../utils/randomKey";
import { get_access_types } from "../../../../../api/endpoints/accessTypes";
import DebounceSelect from "../../../../common/debounce-select/DebounceSelect";
import { get_clients_simplified_filtered } from "../../../../../api/endpoints/clients";
import { BsLockFill, BsUnlockFill } from "react-icons/bs";

const GUTTER = 10;

export default function UpdateAdministrationKey() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [submitting, setSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const [accessTypes, setAccessTypes] = useState(null);
	const [selectedLaboratory, setSelectedLaboratory] = useState(null);
	const [laboratories, setLaboratories] = useState(null);
	const [editUnlock, setEditUnlock] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const fetch_administration_key_by_id = async () => {
			const data = await get_administration_key_by_id(id);

			const administrationKey = {
				title: data.name,
				key: data.value,
				description: data.description,
			};

			setAccessTypes(
				data.accessTypes?.map((i) => ({
					key: i.id,
					value: i.id,
					label: i.name,
				}))
			);

			setSelectedLaboratory(data.assignedId ?? null);

			setInitialValues(administrationKey);
		};

		const fetch_laboratories = async () => {
			const res = await get_clients_simplified_filtered("approved");
			setLaboratories(
				res?.map((c) => ({
					label: c.name,
					value: c.id,
				}))
			);
		};

		if (id) {
			fetch_administration_key_by_id();
			fetch_laboratories();
		}
	}, []);

	const fetch_access_types = async (term) => {
		const res = await get_access_types(term);

		return res.map((r) => ({
			label: r.name,
			value: r.id,
		}));
	};

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const putData = {
				id,
				title: values.title,
				value: values.key,
				description: values.description,
				assignedId: selectedLaboratory,
				accessTypes: accessTypes?.map((i) => ({ accessTypeId: i.value })),
			};

			await update_administration_key(id, putData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Clave de Acceso actualizada con éxito",
				"La Clave de Acceso se actualizó con éxito"
			);
			navigate(`/admin/administrationKeys?uri=${uri}`);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al actualizar la Clave de Acceso",
				"Un error ocurrio al intentar actualizar la Clave de Acceso, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/administrationKeys?uri=" + uri);
			},
		});
	};

	const handleGenerateKey = () => {
		const randomKey = generateRandomKey();
		form.setFieldsValue({ key: randomKey });
	};

	const handleLaboratorySelect = (value) => {
		setSelectedLaboratory(value);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/administrationKeys?uri=" + uri}>
							Claves de Acceso
						</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Editar Clave de Acceso
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<InnerForm>
							<ProductData>
								<Row gutter={GUTTER}>
									<Col span={12}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Nombre
												</label>
											}
											name="title"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(
														/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
													),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input placeholder="Nombre" />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Key
												</label>
											}
											name="key"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Input
												placeholder="Clave"
												prefix={
													editUnlock ? (
														<BsLockFill
															onClick={() => setEditUnlock((prev) => !prev)}
															color={COLORS.Secondary}
															style={{ cursor: "pointer" }}
														/>
													) : (
														<BsUnlockFill
															onClick={() => setEditUnlock((prev) => !prev)}
															color={COLORS.Secondary}
															style={{ cursor: "pointer" }}
														/>
													)
												}
												disabled={editUnlock}
												addonAfter={
													<AntdButton
														onClick={handleGenerateKey}
														disabled={editUnlock}
													>
														Generar
													</AntdButton>
												}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={GUTTER}>
									<Col span={12}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Descripcion
												</label>
											}
											name="description"
											rules={[]}
										>
											<Input placeholder="Descripcion" />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label={
												<label
													style={{
														color: COLORS.Primary,
														fontWeight: "600",
													}}
												>
													Cliente asociado
												</label>
											}
											rules={[]}
										>
											<Select
												showSearch
												allowClear={true}
												optionFilterProp="children"
												placeholder="Seleccione un laboratorio"
												onChange={handleLaboratorySelect}
												value={selectedLaboratory}
												filterOption={(input, option) =>
													(option?.label ?? "")
														.toLowerCase()
														.includes(input.toLowerCase())
												}
												options={laboratories}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={GUTTER}>
									<Col span={24}>
										<Form.Item
											label={
												<label
													style={{
														color: COLORS.Primary,
														fontWeight: "600",
													}}
												>
													Tipos de Acceso
												</label>
											}
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<DebounceSelect
												fetchCallback={fetch_access_types}
												placeholder="Buscar tipos de acceso"
												onValuesChange={setAccessTypes}
												initialValues={accessTypes}
											/>
										</Form.Item>
									</Col>
								</Row>
							</ProductData>
							<PublishSettings>
								<Space
									direction="vertical"
									size="middle"
									style={{ display: "flex" }}
								>
									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
											block
										>
											Guardar Cambios
										</SignInTemplate.AntdButton>
									</Form.Item>
									<SignInTemplate.AntdButton
										type="Primary"
										loading={submitting}
										onClick={handleBack}
										block
									>
										<Typo type="danger" level={6}>
											Volver sin guardar
										</Typo>
									</SignInTemplate.AntdButton>
								</Space>
							</PublishSettings>
						</InnerForm>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
