import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Row, Col, Space, message } from "antd";
import { create_brand } from "../../../../../../../../api/endpoints/brands";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../../../utils/notificationToast";
import { PreviewImage } from "./styles";
import UploadFile from "../../../../../../../common/upload-file/UploadFile";
import { COLORS } from "../../../../../../../layout/theme";

const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function CreateBrandModal({ state, clientId }) {
	const [form] = Form.useForm();

	const [submitting, setSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState();

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				name: values.name,
				clientId: clientId,
			};

			const formData = new FormData();
			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);

			formData.append("data", JSON.stringify(postData));

			const res = await create_brand(formData);
			state(false);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Marca creada con exito",
				"La marca se creo con exito"
			);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear la marca",
				"Un error ocurrio al intentar crear la marca, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Form
			form={form}
			name="basic"
			onFinish={onFinish}
			autoComplete="off"
			requiredMark={false}
			layout="vertical"
		>
			<Space direction="vertical" size="middle" style={{ display: "flex" }}>
				<Row gutter={GUTTER}>
					<Col span={12}>
						<Form.Item
							name="file"
							label={
								<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
									Imagen de la marca
								</label>
							}
						>
							<UploadFile onChange={handleImageChange} />
						</Form.Item>
					</Col>
				</Row>

				<Row>
					<Col>
						<Row>
							{imageUrl && <PreviewImage src={imageUrl} alt="avatar" />}
						</Row>
					</Col>
				</Row>

				<Row gutter={GUTTER}>
					<Col span={12}>
						<Form.Item
							label={
								<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
									Nombre de la marca
								</label>
							}
							name="name"
							rules={[
								{
									required: true,
									message: "Este campo es obligatorio",
								},
								{
									pattern: new RegExp(
										/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
									),
									message:
										"No se aceptan caracteres especiales. Solo letras y numeros",
								},
							]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>

				<Form.Item>
					<Button type="primary" htmlType="submit" loading={submitting}>
						Guardar
					</Button>
				</Form.Item>
			</Space>
		</Form>
	);
}
