import React, { useState } from "react";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import Image from "../../../common/image/Image";
import MarketLogo from "../../../../assets/images/logo.png";
import DropShadow from "../../../common/drop-shadow/DropShadow";
import { Col, Form, Input, Row } from "antd";
import { Typo } from "../../../layout/theme";
import { send_password_reset_email } from "../../../../api/endpoints/auth";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";

const GUTTER = 10;

export default function ForgotPassword() {
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const prueba = await send_password_reset_email(values.email);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Correo enviado",
				"Verifique su casilla de correo"
			);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"No se pudo enviar el correo",
				"Un error ocurrio al intentar enviar el reset de password."
			);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<SignInTemplate>
			<SignInTemplate.Main>
				<SignInTemplate.Top>
					<SignInTemplate.Title>Restablezca su contraseña</SignInTemplate.Title>
					<SignInTemplate.Desc>
						Ingrese su Mail para restablecer su contraseña
					</SignInTemplate.Desc>
				</SignInTemplate.Top>

				<SignInTemplate.Body>
					<DropShadow>
						<SignInTemplate.Logo>
							<Image
								src={MarketLogo}
								alt="Logo FarmaTransfer"
								height="45px"
								width="220px"
							/>
						</SignInTemplate.Logo>
						<SignInTemplate.Formulario>
							<Form
								form={form}
								name="complete-user-registration"
								onFinish={onFinish}
								requiredMark={false}
								layout="vertical"
								wrapperCol={{ span: 24 }}
							>
								<Row gutter={GUTTER}>
									<Col span={6} offset={9} align="center">
										<Typo level={5}>Ingrese su Mail</Typo>
									</Col>
								</Row>
								<SignInTemplate.Break height="15px" />
								<Row gutter={GUTTER}>
									<Col span={5} offset={8} align="center">
										<SignInTemplate.AntdFormItem name="email">
											<Input placeholder="Mail" type="email" />
										</SignInTemplate.AntdFormItem>
									</Col>
									<Col align="center" span={1}>
										<SignInTemplate.AntdFormItem>
											<SignInTemplate.AntdButton
												color="White"
												bg="Primary"
												type="primary"
												htmlType="submit"
												loading={submitting}
											>
												Restablecer Contraseña
											</SignInTemplate.AntdButton>
										</SignInTemplate.AntdFormItem>
									</Col>
								</Row>
								<SignInTemplate.Break height="15px" />
							</Form>
						</SignInTemplate.Formulario>
					</DropShadow>
				</SignInTemplate.Body>
			</SignInTemplate.Main>
		</SignInTemplate>
	);
}
