import React, { useEffect, useState } from "react";
import {
	Col,
	Form,
	Input,
	Row,
	Select,
	Space,
	Checkbox,
	message,
	DatePicker,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import { COLORS, Typo } from "../../../../layout/theme";
import Main from "../../../../layout/main/Main";
import {
	InnerForm,
	LeftData,
	RightData,
	Container,
	NewMarketing,
	AllProductsContainer,
	PreviewImage,
	Footer,
} from "./styles";
import moment from "moment";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";
import { get_brands_products } from "../../../../../api/endpoints/products";
import {
	get_marketing_by_id,
	update_marketing,
} from "../../../../../api/endpoints/marketing";
import { get_brands } from "../../../../../api/endpoints/brands";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";

const GUTTER = 10;
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function UpdateMarketing() {
	const [form] = Form.useForm();
	const { id } = useParams();
	const [prodsForCampaign, setProdsForCampaign] = useState(null);
	const [checkboxState, setCheckboxState] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [imageToSend, setImageToSend] = useState(null);
	const [products, setProducts] = useState(null);
	const [initialValues, setInitialValues] = useState(null);
	const [initialBrand, setInitialBrand] = useState(null);
	const [brands, setBrands] = useState(null);
	const [submitting, setSubmitting] = useState(false);

	const navigate = useNavigate();

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const disabledDate = (current) => {
		return current && current < moment().add(-1, "days").endOf(-1, "day");
	};

	const fetch_brands = async () => {
		const data = await get_brands();
		setBrands(data || []);
	};

	const fetch_brand_products = async (id) => {
		const res = await get_brands_products(id);
		setProducts(res);
	};

	const fetch_marketing_by_id = async (id) => {
		const res = await get_marketing_by_id(id);
		const init = {
			title: res.title,
			link: res?.link === undefined ? null : res?.link?.slice(8),
			publish: [moment(res.publishFrom), moment(res.publishTo)],
			brandId: res.brandId,
			products: res.products.map((i) => ({
				key: i.id,
				label: i.name,
				value: i.id,
			})),
		};

		setInitialBrand({
			label: res.brandName,
			key: res.brandId,
			id: res.brandId,
		});

		setInitialValues(init);
		setImageUrl(res.photoUrl);
		setProdsForCampaign(res.products.map((i) => i.id));
	};

	useEffect(() => {
		fetch_marketing_by_id(id);
	}, []);

	useEffect(() => {
		initialValues?.brandId && fetch_brand_products(initialValues.brandId);
	}, [initialValues]);

	const handleCheckbox = (e) => {
		setCheckboxState(e.target.checked);
		e.target.checked === true
			? setProdsForCampaign(products.map((e) => e.id))
			: setProdsForCampaign(null);
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
			setImageToSend(info.fileList[info.fileList.length - 1].originFileObj);
		} catch (error) {
			console.log(error);
		}
	};

	const onFinish = async (values) => {
		try {
			const postData = {
				title: values?.title,
				brand: initialValues.brandId,
				products: prodsForCampaign,
				link: values.link ? `https://${values.link}` : null,
				publishFrom: values.publish[0].format("YYYY-MM-DD"),
				publishTo: values.publish[1].format("YYYY-MM-DD"),
			};

			setSubmitting(true);

			const formData = new FormData();

			formData.append("data", JSON.stringify(postData));
			formData.append("file", imageToSend);
			const res = await update_marketing(id, formData);
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Publicidad actualizada con exito",
				"La publicidad se actualizo con exito"
			);
			navigate("/admin/marketing");
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al actualizar la publicidad",
				"Un error ocurrio al intentar actualizar la publicidad, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	if (!initialValues) return <p>Cargando...</p>;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to="/admin/marketing">Marketing</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Actualizar Publicidad
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<NewMarketing>
					<Form
						form={form}
						name="create-product"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						// setFieldsValue={{ products: undefined }}
						initialValues={initialValues}
					>
						<InnerForm>
							<LeftData>
								<Space
									direction="vertical"
									size="middle"
									style={{ display: "flex" }}
								>
									<Row gutter={GUTTER}>
										<Col md={12} xl={12}>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Nombre de la campaña
													</label>
												}
												name="title"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														pattern: new RegExp(
															/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
														),
														message:
															"No se aceptan caracteres especiales. Solo letras y numeros",
													},
												]}
											>
												<Input placeholder="Nombre" />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={GUTTER}>
										<Col md={12} xl={12}>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Marca
													</label>
												}
												name="brand"
											>
												<Select
													allowClear={true}
													placeholder="Marca"
													onDropdownVisibleChange={fetch_brands}
													defaultValue={initialBrand}
													showSearch={true}
													optionFilterProp="children"
													disabled={true}
												>
													{brands?.map((item) => (
														<Option key={item.id} value={item.id}>
															{item.name}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>

										<Col md={12} xl={12}>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Productos
													</label>
												}
												name="products"
												rules={[
													{
														required: prodsForCampaign ? false : true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												{checkboxState ? (
													<Select
														mode="multiple"
														allowClear
														style={{
															width: "100%",
														}}
														placeholder="Productos"
														disabled={checkboxState}
														defaultValue={initialValues?.products}
														value={products.map((e) => ({
															key: e.id,
															value: e.id,
														}))}
													>
														{products?.map((i) => (
															<Option value={i.id}>{i.title}</Option>
														))}
													</Select>
												) : (
													<Select
														mode="multiple"
														allowClear
														style={{
															width: "100%",
														}}
														placeholder="Productos"
														defaultValue={initialValues?.products}
														disabled={checkboxState}
														onChange={(e) => setProdsForCampaign(e)}
													>
														{products?.map((i) => (
															<Option value={i.id}>{i.title}</Option>
														))}
													</Select>
												)}
												<AllProductsContainer>
													<Typo type="primary">Seleccionar todos</Typo>{" "}
													<Checkbox onChange={(e) => handleCheckbox(e)} />
												</AllProductsContainer>
											</Form.Item>
										</Col>
									</Row>
									<Footer>
										<Row gutter={GUTTER}>
											<Col md={12} xl={12}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Imagen
														</label>
													}
													name="file"
												>
													<UploadFileImage onChange={handleImageChange} />
													{imageUrl && (
														<PreviewImage
															style={{ marginLeft: "20px" }}
															src={imageUrl}
															alt="avatar"
														/>
													)}
												</Form.Item>
											</Col>
											<Col md={12} xl={12}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Link
														</label>
													}
													name="link"
												>
													<Input
														placeholder="ejemplo.com"
														addonBefore="https://"
													/>
												</Form.Item>
											</Col>
										</Row>
									</Footer>
								</Space>
							</LeftData>
							<RightData>
								<Container>
									<Space
										direction="vertical"
										size="middle"
										style={{
											display: "flex",
										}}
									>
										<Typo level={5} type="primary">
											Publicación
										</Typo>
										<Typo level={6} type="primary">
											Duración
										</Typo>
										<Form.Item
											name="publish"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<RangePicker
												disabledDate={disabledDate}
												format="DD/MM/YYYY"
												style={{
													width: "100%",
												}}
											/>
										</Form.Item>

										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
											block
										>
											Guardar cambios
										</SignInTemplate.AntdButton>
									</Space>
								</Container>
							</RightData>
						</InnerForm>
					</Form>
				</NewMarketing>
			</Main.Body>
		</Main>
	);
}
