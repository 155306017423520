import { Button, Input, Select, Upload } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES } from "../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	overflow: hidden;
`;

export const Container = styled.div`
	display: flex;
	gap: 20px;
`;

export const OptionSection = styled.div`
	display: flex;
	flex: 0 0 100%;

	span {
		max-width: 620px;
		height: 100%;
	}
`;

export const ProductSelect = styled(Select)`
  margin-bottom: 20px;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 2px solid ${COLORS.Grey};
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &..ant-select-arrow {
      
    }
  

  &.ant-select-selector{
    .ant-select-selection-item {
      font-size: ${SIZES.level7};
      color: ${COLORS.Primary};
    }
  }

  &.ant-select-selector{
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: ${SIZES.level7};
        color: ${COLORS.Primary};
      }
    }
  }
`;

export const LaboratoryBoxContainer = styled.div`
	position: absolute;
	display: block;
	left: 70px;
	max-height: 1250px;
	margin: 0;
	overflow-y: auto;
	overflow-x: hidden;
	transform: rotate(-90deg) translateY(-80px);
	transform-origin: right top;
	direction: rtl;

	div {
		transform: rotate(90deg);
		width: 100px;
		height: 100px;
		margin: 10px;
		color: ${COLORS.Secondary};
	}

	img {
		transform: rotate(180deg);
	}

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px #e3e3e3;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background: ${COLORS.Grey};
		border-radius: 10px;
	}
`;

export const RoundSearch = styled(Input)`
	width: 450px;
	border-radius: 20px;
`;

export const CompaginationDiv = styled.div`
	display: flex;
	gap: 4px;
`;

export const SearchContainer = styled.div`
	flex: 1 1 40%;
	align-items: center;
	align-content: center;
	justify-content: center;
	display: flex;
	align-content: space-evenly;
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
`;

export const AntdInput = styled(Input)`
	.ant-input-group .ant-input {
		border: 1px solid ${COLORS.Grey};
		border-radius: 10px;
		background-color: transparent;
		text-align: end;
		padding: 5px;
	}

	.ant-input-group .ant-input:hover {
		border: 1px solid ${COLORS.Secondary};
		border-radius: 10px;
	}

	.ant-input-group .ant-input:focus {
		border-radius: 10px;
	}

	.ant-input-group-addon {
		border: 0;
		background-color: transparent;
		padding: 5px;
	}
`;

export const UploadFile = styled(Upload)`
	width: 100%;
	min-height: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 2px solid ${COLORS.LightGrey};
	border-radius: 10px;

	& span {
		color: ${COLORS.Primary};
	}

	position: relative;
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: row;
	}
`;

export const UploadIconContainer = styled.div`
	display: flex;
	width: 200px;
	justify-content: flex-start;
	align-items: center;
	position: absolute;
	left: 115px;
	padding-top: 8px;
`;

export const AntdButton = styled(Button)`
	cursor: pointer;

	&:hover {
		color: ${(props) => (props.hoverColor ? props.hoverColor : COLORS.Primary)};
		border: 2px solid
			${(props) =>
				props.hoverBorderColor ? props.hoverBorderColor : COLORS.White};
		background-color: ${(props) =>
			props.hoverBackgroundColor ? props.hoverBackgroundColor : COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}

	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : COLORS.Primary};
	color: ${(props) => (props.color ? props.color : COLORS.White)};
	border-radius: 5px;
	box-shadow: 0 3px 3px 0 #c7c7c7;
`;
