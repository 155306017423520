import React, { useEffect, useState } from "react";
import {
	Link,
	useParams,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { Form, Input, Row, Col, Space, Modal } from "antd";
import { InnerForm, ProductData, PublishSettings } from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import COLORS from "../../../../layout/theme/colors/Colors";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import {
	get_active_ingredient_by_id,
	update_active_ingredient,
} from "../../../../../api/endpoints/activeIngredients";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Typo } from "../../../../layout/theme";

const GUTTER = 10;

export default function UpdateActiveIngredient() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [submitting, setSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const fetch_active_ingredient_by_id = async () => {
			const data = await get_active_ingredient_by_id(id);

			const saleDepartment = {
				title: data.name,
			};

			setInitialValues(saleDepartment);
		};

		if (id) {
			fetch_active_ingredient_by_id();
		}
	}, []);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const putData = {
				id,
				title: values.title,
			};

			await update_active_ingredient(id, putData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Principio Activo actualizado con éxito",
				"El Principio Activo se actualizó con éxito"
			);
			navigate(`/admin/activeIngredients?uri=${uri}`);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al actualizar el Principio Activo",
				"Un error ocurrio al intentar actualizar el Principio Activo, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/activeIngredients?uri=" + uri);
			},
		});
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/activeIngredients?uri=" + uri}>
							Principios Activos
						</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Editar Principio Activo
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<InnerForm>
							<ProductData>
								<Space
									direction="vertical"
									size="middle"
									style={{ display: "flex" }}
								>
									<Row gutter={GUTTER}>
										<Col span={22}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Nombre
													</label>
												}
												name="title"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														pattern: new RegExp(
															/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
														),
														message:
															"No se aceptan caracteres especiales. Solo letras y numeros",
													},
												]}
											>
												<Input placeholder="Nombre" />
											</Form.Item>
										</Col>
									</Row>
								</Space>
							</ProductData>
							<PublishSettings>
								<Space
									direction="vertical"
									size="middle"
									style={{ display: "flex" }}
								>
									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
											block
										>
											Guardar Cambios
										</SignInTemplate.AntdButton>
									</Form.Item>
									<SignInTemplate.AntdButton
										type="Primary"
										loading={submitting}
										onClick={handleBack}
										block
									>
										<Typo type="danger" level={6}>
											Volver sin guardar
										</Typo>
									</SignInTemplate.AntdButton>
								</Space>
							</PublishSettings>
						</InnerForm>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
