import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Form, Input, Row, Col, Space, Modal, message, Select } from "antd";
import { InnerForm, ProductData, PublishSettings } from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import COLORS from "../../../../layout/theme/colors/Colors";
import { Typo } from "../../../../layout/theme";
import { CloseCircleOutlined } from "@ant-design/icons";
import { create_biller } from "../../../../../api/endpoints/billers";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";
import { CONSTANTS } from "../../../../../utils/constants";

const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function CreateBiller() {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [submitting, setSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState(null);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				title: values.title,
				key: values.key || "",
				connectionType: values.connectionType,
			};

			const formData = new FormData();
			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);

			formData.append("data", JSON.stringify(postData));

			const res = await create_biller(formData);
			navigate(`/admin/billers?uri=${uri}`);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Facturador creado con éxito",
				"El facturador se creo con éxito"
			);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear el facturador",
				"Un error ocurrio al intentar crear el facturador, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido completados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/billers?uri=" + uri);
			},
		});
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/billers?uri=" + uri}>Facturadores</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Crear Facturador
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Form
					form={form}
					name="create-biller"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
				>
					<InnerForm>
						<ProductData>
							<Space
								direction="vertical"
								size="middle"
								style={{ display: "flex" }}
							>
								<Row gutter={GUTTER}>
									<Col md={4} xl={4}>
										<Form.Item
											name="file"
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Imagen principal
												</label>
											}
										>
											<UploadFileImage
												onChange={handleImageChange}
												urlImage={imageUrl}
												imageHeight="100%"
												imageWidth="100%"
											/>
										</Form.Item>
									</Col>
									<Col span={20}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Nombre
												</label>
											}
											name="title"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input placeholder="Nombre" />
										</Form.Item>
										{/* <Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Key
												</label>
											}
											name="key"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Input placeholder="Nombre" />
										</Form.Item> */}
										<Form.Item
											label={
												<label
													style={{
														color: COLORS.Primary,
														fontWeight: "600",
													}}
												>
													Tipo de conexión
												</label>
											}
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
											name="connectionType"
										>
											<Select placeholder="Seleccione el tipo de conexión">
												{CONSTANTS.BILLER_CONNECTIONS_TYPE.map((b, idx) => {
													return (
														<Select.Option key={idx} value={b.value}>
															{b.label}
														</Select.Option>
													);
												})}
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</Space>
						</ProductData>
						<PublishSettings>
							<Space
								direction="vertical"
								size="middle"
								style={{ display: "flex" }}
							>
								<Form.Item>
									<SignInTemplate.AntdButton
										type="Primary"
										color="white"
										bg="Primary"
										htmlType="submit"
										loading={submitting}
										block
									>
										Crear Facturador
									</SignInTemplate.AntdButton>
								</Form.Item>
								<SignInTemplate.AntdButton
									type="Primary"
									loading={submitting}
									onClick={handleBack}
									block
								>
									<Typo type="danger" level={6}>
										Volver sin guardar
									</Typo>
								</SignInTemplate.AntdButton>
							</Space>
						</PublishSettings>
					</InnerForm>
				</Form>
			</Main.Body>
		</Main>
	);
}
