import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Inner, Section } from "./styles";
import Main from "../../../layout/main/Main";
import { COLORS, Typo } from "../../../layout/theme";
import ModuleErp from "./components/module-erp/ModuleErp";

const { Title, Paragraph } = Typography;

export default function GeneralConfig({
	userdata,
	clientId,
	clientContract,
	clientName,
}) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Configuración General
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
				<Container>
					<Section>
						<Title style={{ color: COLORS.Primary, marginTop: 30 }} level={3}>
							Configuración General
						</Title>
						<Paragraph>
							<blockquote>
								En esta sección podrás configurar el sistema
							</blockquote>
						</Paragraph>
					</Section>
				</Container>
			</Main.Header>

			<Inner>
				<ModuleErp clientContract={clientContract} />
			</Inner>
		</Main>
	);
}
