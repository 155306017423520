import React from "react";
import { Inner } from "./styles";
import SellerMobile from "../seller-mobile/SellerMobile";
import { useAuth } from "../../../../contexts/authContext";

export default function HomeMobile({ clientId }) {
	const { user } = useAuth();

	return (
		<Inner>
			<SellerMobile clientId={clientId} userId={user.uid} />
		</Inner>
	);
}
