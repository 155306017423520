import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Modal } from "antd";
import {
	InnerForm,
	OrderData,
	InfoText,
	LineBreak,
	TitleContainer,
	SubTitleContainer,
	InfoContainer,
	OptionsButtonsContainer,
	Inner,
	InfoIva,
	LeftSection,
	PriceContainer,
	Container,
	RightSection,
	DistributorInfo,
	Section,
	AntdCheckbox,
	QtyContainer,
	SubSection,
	MainContainer,
	MainBody,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import COLORS from "../../../../layout/theme/colors/Colors";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import {
	get_order_by_id_with_status,
	update_order_state,
} from "../../../../../api/endpoints/orders";
import OrderTable from "./components/order-table/OrderTable";
import OneTransferLogo from "../../../../../assets/images/logo-box.png";
import Image from "../../../../common/image/Image";
import { Typo } from "../../../../layout/theme";
import { numberWithDotAndCommas } from "../../../../../utils/numberWithDotAndComas";
import moment from "moment";
import { InfoCircleOutlined } from "@ant-design/icons";
import { CONSTANTS } from "../../../../../utils/constants";

const { confirm } = Modal;

export default function OrderDetail() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [submitting, setSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [initialValues, setInitialValues] = useState(null);
	const [orderStateChange, setOrderStateChange] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		const fetch_order_by_id = async () => {
			const data = await get_order_by_id_with_status(id);
			const order = {
				id: data.order.id,
				created: moment(data.order.created).format("dddd, DD MMMM YYYY"),
				client: data.order.clientName,
				photoUrl: data.order.clientPhotoUrl,
				name: data.order.userFirstName + " " + data.order.userLastName,
				email: data.order.userEmail,
				distributorId: data.order.distributorId,
				distributorName: data.order.distributorName,
				distributorPhoto: data.order.distributorPhotoUrl,
				pointOfSaleId: data.order.pointOfSaleId,
				pointOfSaleName: data.order.pointOfSaleName,
				pointOfSaleAddress: data.order.pointOfSaleAddress,
				quantityItems: data.order.quantityItems,
				subTotal: data.order.subtotal,
				total: data.order.total,
				orderNumber: data.order.orderNumber,
				orderNumId: data.order.orderNumId,
				orderState: data.order.orderState,
				pointOfSaleProvince: data.order.pointOfSaleProvince,
				pointOfSaleLocality: data.order.pointOfSaleLocality,
				items: data.items,
			};

			setImageUrl(data.order.clientPhotoUrl);

			setInitialValues(order);
			setLoading(false);
		};

		if (id) {
			fetch_order_by_id();
		}
	}, [orderStateChange]);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);
			navigate("/admin/compras");
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(TYPE.ERROR, "Error");
		} finally {
			setSubmitting(false);
		}
	};

	const handleCancelOrder = (key, nombre, precio) => {
		confirm({
			title: `¿Seguro que quiere cancelar esta orden?`,
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El pedido será anulado y no podrá reestablecerse",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				cancelOrderAction();
			},
		});
	};

	const cancelOrderAction = async () => {
		const _state = {
			id: initialValues.id,
			state: CONSTANTS.ORDER_STATE_CANCELED,
		};
		await update_order_state(initialValues.id, _state);
		setOrderStateChange((prev) => !prev);
	};

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendiente";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activa";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviada a Droguería";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Cancelada";

			default:
				return;
		}
	};

	if (!id) navigate("/admin/compras");

	return (
		<div style={{ backgroundColor: COLORS.LightGrey }}>
			<MainContainer>
				<MainBody>
					{!initialValues ? (
						<LoadingSkeleton />
					) : (
						<Form
							form={form}
							name="basic"
							onFinish={onFinish}
							autoComplete="off"
							requiredMark={false}
							layout="vertical"
						>
							<InnerForm>
								<OrderData>
									<SubTitleContainer
										style={{ justifyContent: "space-between" }}
									>
										<Section style={{ flex: "1 1 30%" }}>
											<SubSection>
												<Typo
													fontWeight={600}
													fontSize={16}
													style={{ color: "#6e6d6d" }}
												>
													Datos de Comercio:{" "}
												</Typo>
												<InfoContainer>
													<Form.Item name="pointOfSaleName">
														<Typo type="muted" level={6}>
															{initialValues.pointOfSaleName} -
														</Typo>
													</Form.Item>
													<Form.Item name="pointOfSaleAddress">
														<Typo type="muted" level={6}>
															{initialValues.pointOfSaleAddress}
														</Typo>
													</Form.Item>
												</InfoContainer>
											</SubSection>
											<SubSection>
												<InfoContainer>
													<Form.Item name="pointOfSaleProvince">
														<Typo type="muted" level={6}>
															{initialValues.pointOfSaleProvince} -
														</Typo>
													</Form.Item>
													<Form.Item name="pointOfSaleLocality">
														<Typo type="muted" level={6}>
															{initialValues.pointOfSaleLocality}
														</Typo>
													</Form.Item>
												</InfoContainer>
											</SubSection>
										</Section>
										<TitleContainer style={{ flex: "1 1 30%" }}>
											<Image
												src={OneTransferLogo}
												style={{ width: "50px", height: "50px" }}
											/>
										</TitleContainer>
										<SubSection style={{ flex: "1 1 30%" }}>
											<Typo
												fontWeight={600}
												fontSize={16}
												style={{ color: "#6e6d6d" }}
											>
												Fecha de pedido:{" "}
											</Typo>
											<InfoContainer>
												<Form.Item name="created">
													<InfoText>Creada el {initialValues.created}</InfoText>
												</Form.Item>
											</InfoContainer>
										</SubSection>
									</SubTitleContainer>

									<SubTitleContainer
										style={{
											display: "flex",
											justifyContent: "space-between",
											marginTop: "10px",
											marginBottom: "-30px",
										}}
									>
										<Form.Item>
											<Typo
												fontWeight={600}
												fontSize={16}
												style={{ color: "#6e6d6d" }}
											>
												DETALLE DE ORDEN #{initialValues.orderNumId}
											</Typo>
										</Form.Item>
										<Form.Item name="name">
											<Typo
												fontWeight={600}
												fontSize={16}
												style={{ color: "#6e6d6d" }}
											>
												USUARIO: {initialValues?.name}
											</Typo>
										</Form.Item>
									</SubTitleContainer>
									<LineBreak />
									<Inner>
										<RightSection>
											<DistributorInfo>
												<Container>
													<Typo type="default" level={5}>
														ENVIADA A:{" "}
													</Typo>
													<Typo type="secondary" level={5}>
														{initialValues.distributorName.toUpperCase()}
													</Typo>
												</Container>
												<Container>
													<QtyContainer>
														<Typo type="default" level={6}>
															Cantidad de Productos:{"  "}
														</Typo>
														<Typo type="primary" level={4}>
															{initialValues?.items?.length}
														</Typo>
													</QtyContainer>
												</Container>
												<Container>
													<QtyContainer>
														<Typo type="default" level={6}>
															Cantidad de Unidades:{"  "}
														</Typo>
														<Typo type="primary" level={4}>
															{initialValues?.quantityItems}
														</Typo>
													</QtyContainer>
												</Container>
												<Container>
													<Typo type="defaul" level={5}>
														ESTADO:{" "}
													</Typo>
													<Typo type="secondary" level={5}>
														{selectStateToShow(initialValues.orderState)}
													</Typo>
												</Container>
												{(initialValues.orderState ===
													CONSTANTS.ORDER_STATE_ACTIVE ||
													initialValues.orderState ===
														CONSTANTS.ORDER_STATE_PENDING) && (
													<Container>
														(
														<SignInTemplate.AntdButton
															type="Warning"
															color="white"
															bg="Danger"
															loading={submitting}
															onClick={handleCancelOrder}
														>
															Cancelar pedido
														</SignInTemplate.AntdButton>
														)
													</Container>
												)}
											</DistributorInfo>
										</RightSection>
										<LeftSection>
											<OrderTable data={initialValues} />
											<PriceContainer>
												<Container style={{ alignItems: "flex-end" }}>
													<Typo type="default" level={6}>
														Subtotal:{" "}
													</Typo>
													<Typo type="default" level={6}>
														{" "}
														$ {numberWithDotAndCommas(initialValues.subTotal)}
													</Typo>
												</Container>
												<Container style={{ alignItems: "flex-end" }}>
													<Typo type="default" level={6}>
														Descuento:{" "}
													</Typo>
													<Typo type="secondary" level={5}>
														${" "}
														{numberWithDotAndCommas(
															Number(
																Math.round(
																	parseFloat(
																		((initialValues.subTotal -
																			initialValues.total) *
																			100) /
																			100 +
																			"e" +
																			2
																	)
																) +
																	"e-" +
																	2
															).toFixed(2)
														)}
													</Typo>
												</Container>
												<Container style={{ alignItems: "flex-end" }}>
													<Typo type="default" level={6}>
														Total:{" "}
													</Typo>
													<Typo type="default" level={4}>
														$ {numberWithDotAndCommas(initialValues.total)}
													</Typo>
												</Container>
											</PriceContainer>
											<InfoIva>IVA no incluido</InfoIva>
										</LeftSection>
									</Inner>
								</OrderData>

								<OptionsButtonsContainer>
									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
										>
											Volver
										</SignInTemplate.AntdButton>
									</Form.Item>
								</OptionsButtonsContainer>
							</InnerForm>
						</Form>
					)}
				</MainBody>
			</MainContainer>
		</div>
	);
}
