import React, { useState, useEffect } from "react";
import {
	useParams,
	Link,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import {
	Form,
	Input,
	DatePicker,
	Select,
	Row,
	Col,
	Space,
	message,
	Modal,
	InputNumber,
} from "antd";
import {
	InnerForm,
	ProductData,
	PublishSettings,
	AntdCheckbox,
	Container,
	CardContainer,
} from "./styles";
import { get_market_brands } from "../../../../../../api/endpoints/brands";
import { get_categories } from "../../../../../../api/endpoints/categories";
import { get_active_ingredients } from "../../../../../../api/endpoints/activeIngredients";
import { get_presentations_types } from "../../../../../../api/endpoints/productpresentations";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import {
	get_market_products,
	get_products_by_id,
	update_market_product_state,
	update_product,
} from "../../../../../../api/endpoints/products";
import moment from "moment";
import DebounceSelect from "../../../../../common/debounce-select/DebounceSelect";
import Main from "../../../../../layout/main/Main";
import ImageGallery from "../../../../../common/image-gallery/ImageGallery";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { get_tags } from "../../../../../../api/endpoints/tags";
import COLORS from "../../../../../layout/theme/colors/Colors";
import { Typo } from "../../../../../layout/theme";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import UploadFileImage from "../../../../../common/upload-file-with-image/UploadFileImage";
import {
	get_clients_name_list,
	get_client_by_id,
} from "../../../../../../api/endpoints/clients";
import {
	calculatePercentage,
	calculatePrice,
} from "../../../../../../utils/calculatePrice";
import { BsLockFill, BsUnlockFill } from "react-icons/bs";
import DistributorsFilteredByClient from "../../../../../common/distributor-code-by-client/DistributorsFilteredByClient";
import { CloseCircleOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

const states = [
	{
		id: "PAUSED",
		name: "Pausado",
	},
	{
		id: "PUBLISHED",
		name: "Publicado",
	},
];

const statesWithDraft = [
	{
		id: "PAUSED",
		name: "Pausado",
	},
	{
		id: "PUBLISHED",
		name: "Publicado",
	},
	{
		id: "DRAFT",
		name: "Borrador",
	},
];

export default function UpdateMarketProduct() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	const [submitting, setSubmitting] = useState(false);
	const [brands, setBrands] = useState([]);
	const [presentationTypes, setPresentationTypes] = useState([]);
	const [imageUrl, setImageUrl] = useState();
	const [distributors, setDistributors] = useState(null);
	const [categories, setCategories] = useState(null);
	const [activeIngredients, setActiveIngredients] = useState(null);
	const [relatedProducts, setRelatedProducts] = useState(null);
	const [initialValues, setInitialValues] = useState(null);
	const [photoGallery, setPhotoGallery] = useState([]);
	const [tags, setTags] = useState([]);
	const [show, setShow] = useState(null);
	const [clients, setClients] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [publish, setPublish] = useState(null);
	const [offer, setOffer] = useState(null);
	const [defaultTag, setDefaultTag] = useState(null);
	const [offerPriceByDiscount, setOfferPriceByDiscount] = useState(null);
	const [productPrice, setProductPrice] = useState(null);
	const [unitName, setUnitName] = useState(null);
	const [clientSelected, setClientSelected] = useState(null);
	const [commisionInput, setCommisionInput] = useState(null);
	const [productDiscount, setProductDiscount] = useState(0);
	const [showTriggerQtyDiscount, setShowTriggerQtyDiscount] = useState(false);
	const [aICheckbox, setAICheckbox] = useState(false);
	const [eanCode, setEanCode] = useState("");
	const [laboratoryCode, setLaboratoryCode] = useState("");
	const [editUnlock, setEditUnlock] = useState(true);
	const [showFirstDiscount, setShowFirstDiscount] = useState(false);
	const [editUnlockLaboratoryCode, setEditUnlockLaboratoryCode] =
		useState(true);

	let triggerDiscount = Form.useWatch("triggerDiscount", form);
	let discount = Form.useWatch("discount", form);

	useEffect(() => {
		const fetch_product_by_id = async () => {
			const data = await get_products_by_id(id);
			setPublish(moment(data.publishFrom, "YYYY-MM-DD"));
			setOffer([
				moment(data.offerFrom, "YYYY-MM-DD"),
				moment(data.offerTo, "YYYY-MM-DD"),
			]);

			//console.log("data", data);

			const product = {
				title: data.title,
				presentationType: data.presentation?.id,
				presentationQty: data.presentationQty,
				brand: data.brandId,
				ean13: data.ean13,
				shortDesc: data.shortDescription,
				longDesc: data.longDescription,
				price: data.price,
				offerPrice: data.offerPrice,
				discount: data.discount,
				minQuantity: data.minQuantity,
				maxQuantity: data.maxQuantity,
				offerFrom: data.offerFrom
					? moment(data.offerFrom).format("YYYY-MM-DD")
					: null,
				offerTo: data.offerTo
					? moment(data.offerTo).format("YYYY-MM-DD")
					: null,
				state: data.state,
				publishFrom: data.publishFrom
					? moment(data.publishFrom, "YYYY-MM-DD")
					: null,
				publishTo: data.publishTo ? moment(data.publishTo, "YYYY-MM-DD") : null,
				client: data.client,
				clientId: data.client.id,
				saleCommision: data.saleCommision,
				triggerQty: data.triggerQty,
				triggerDiscount: data.triggerDiscount,
				tagId: data.tagId,
			};

			setShow(data.offerTo && data.offerFrom);

			setSelectedClient(data.client?.id);

			setClientSelected(data.client);

			setProductDiscount(data.discount);

			setShowFirstDiscount(data && data.discount > 0);

			setShowTriggerQtyDiscount(data && data.triggerQty !== 0);

			setCategories(
				data.categories?.map((i) => ({
					key: i.id,
					value: i.id,
					label: i.name,
				}))
			);

			setActiveIngredients(
				data.activeIngredients?.map((i) => ({
					key: i.id,
					value: i.id,
					label: i.name,
				}))
			);

			setAICheckbox(
				data.activeIngredients[0]?.id === "839218AB-7A6E-432B-AF8C-D41BBBE000D7"
					? true
					: false
			);

			setDistributors(
				data.distributors?.map((i) => ({
					key: i.distributorId,
					id: i.distributorId,
					code: i.code,
					name: i.name,
					photoUrl: i.photoUrl,
				}))
			);

			setRelatedProducts(
				data.relatedProductsOf?.map((i) => ({
					key: i.id,
					value: i.id,
					label: i.title,
				}))
			);

			setImageUrl(data.photoUrl);

			setPhotoGallery(
				data.photoGallery?.map((f) => ({
					uid: f.id,
					name: f.fileName,
					status: "done",
					url: f.url,
				})) || []
			);

			setInitialValues(product);

			fetch_tags("", data.tagId);

			setOfferPriceByDiscount(data.offerPrice);

			setProductPrice(data.price);

			setPublish(moment(data.publishFrom, "YYYY-MM-DD"));

			setUnitName(data.presentation?.unit?.name);

			setEanCode(data.ean13);

			setLaboratoryCode(data.laboratoryCode);
		};

		const fetch_presentation_types = async () => {
			const data = await get_presentations_types();
			setPresentationTypes(data || []);
		};

		const fetch_tags = async (term, data) => {
			const response = await get_tags(term);

			setDefaultTag(response.find((i) => i.id === data));
			setTags(response || []);
		};

		fetch_presentation_types();
		//fetch_tags();

		if (id) {
			fetch_product_by_id();
		}
	}, [selectedClient]);

	useEffect(() => {
		const fetch_contract = async () => {
			const data = await get_client_by_id(selectedClient);
			setClientSelected(data.client);
		};

		if (selectedClient) fetch_contract();
	}, [selectedClient]);

	useEffect(() => {
		const fetch_brands = async () => {
			const data = await get_market_brands();
			setBrands(data.filter((b) => b.clientId === selectedClient) || []);
		};

		fetch_brands();
	}, [selectedClient]);

	useEffect(() => {
		const fetch_market_products_total = async () => {
			const res = await get_clients_name_list();
			setClients(
				res?.map((r) => ({
					clientId: r.id,
					clientName: r.name,
				}))
			);
		};

		fetch_market_products_total();
		window.scrollTo(0, 0);
	}, []);

	const fetch_products = async (term) => {
		const res = await get_market_products(term);

		return res.map((r) => ({
			label: r.title,
			value: r.id,
		}));
	};

	const fetch_categories = async (term) => {
		const res = await get_categories(term);

		return res.map((r) => ({
			label: r.name,
			value: r.id,
		}));
	};

	const fetch_active_ingredients = async (term) => {
		const res = await get_active_ingredients(term);

		return res.map((r) => ({
			label: r.name,
			value: r.id,
		}));
	};

	const disabledDate = (current) => {
		return current && current < moment().add(-1, "days").endOf(-1, "day");
	};

	const handlePublish = (e) => {
		setPublish(e);
	};

	const disabledDateOffer = (current) => {
		return publish
			? current < publish.valueOf()
			: current < moment().add(-1, "days").endOf(-1, "day");
	};

	const ofertaHandler = () => {
		setShow(true);
	};

	const handleTagSelect = (e, i) => {
		setDefaultTag(e);
	};

	const handleAICheckbox = (e) => {
		setAICheckbox(e);
	};

	const onFinish = async (values) => {
		try {
			if (
				(offerPriceByDiscount > 0 && values.maxQuantity < 0) ||
				(offerPriceByDiscount > 0 && values.minQuantity < 0)
			) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Las cantidades deben ser unidades mayores a cero"
				);
				setSubmitting(false);
				return;
			}
			if (
				offerPriceByDiscount > 0 &&
				values.maxQuantity - values.minQuantity < 0
			) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"La cantidad mínima no puede superar a la máxima"
				);
				setSubmitting(false);
				return;
			}
			if (eanCode === "" || eanCode.length !== 13) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"El código Ean13 no puede ser vacío o tiene que tener 13 caracteres"
				);
				setSubmitting(false);
				return;
			}

			// if (values.brand === null || values.brand === undefined) {
			//   openNotificationWithIcon(TYPE.ERROR, "La marca es obligatoria");
			//   setSubmitting(false);
			//   return;
			// }

			const _aux = distributors.filter(
				(i) =>
					i.code === "" ||
					i.code === "si" ||
					i.code === null ||
					i.code === undefined
			);

			if (distributors.length - _aux.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe colocar al menos un código de Drogueria"
				);
				return;
			}

			setSubmitting(true);
			const postData = {
				activeIngredients: aICheckbox
					? ["839218AB-7A6E-432B-AF8C-D41BBBE000D7"]
					: activeIngredients?.map((i) => i.value),
				brandId: values.brand,
				categories: categories?.map((i) => i.value),
				ean13: eanCode,
				photoUrl: imageUrl,
				shortDescription: values.shortDesc,
				longDescription: values.longDesc,
				offerFrom: values.offer
					? moment(values.offer[0]).format("YYYY-MM-DD")
					: initialValues.offerFrom,
				offerTo: values.offer
					? moment(values.offer[1]).format("YYYY-MM-DD")
					: initialValues.offerTo,
				publishFrom: values.publishFrom
					? moment(values.publishFrom).format("YYYY-MM-DD")
					: initialValues.publishFrom,
				publishTo: values.publish
					? moment(values.publishFrom).add(3000, "y").format("YYYY-MM-DD")
					: initialValues.publishTo,
				price: parseFloat(productPrice),
				discount: showFirstDiscount ? parseFloat(values.discount) : 0,
				presentationId: values.presentationType,
				presentationQty: values.presentationQty,
				state: values.state,
				title: values.title,
				distributors: distributors.map((d) => ({
					id: d.id,
					code: d.code === "si" ? "" : d.code,
				})),
				relatedProductsOf: relatedProducts?.map((i) => i.value),
				existingPhotoGallery: photoGallery?.map((i) => i.uid),
				tag: defaultTag.id || defaultTag,
				clientId: initialValues.clientId,
				saleCommision: initialValues.saleCommision,
				minQuantity:
					showFirstDiscount && offerPriceByDiscount && offerPriceByDiscount > 0
						? parseInt(values.minQuantity)
						: 1,
				maxQuantity:
					showFirstDiscount && offerPriceByDiscount && offerPriceByDiscount > 0
						? parseInt(values.maxQuantity)
						: 999,
				triggerQty:
					values.triggerDiscount > 0 && showTriggerQtyDiscount
						? parseInt(values.triggerQty)
						: 0,
				triggerDiscount:
					values.triggerQty > 0 && showTriggerQtyDiscount
						? parseFloat(values.triggerDiscount)
						: 0,
				laboratoryCode: laboratoryCode,
			};

			const formData = new FormData();
			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);
			photoGallery.forEach((f) => {
				formData.append("files", f.originFileObj);
			});

			formData.append("data", JSON.stringify(postData));

			await update_product(id, formData);

			const _state = { id: id, state: values.state };

			await update_market_product_state(id, _state);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Producto actualizado con éxito",
				"El producto se actualizó con éxito"
			);
			navigate(`/admin/marketProductsIndex?uri=${uri}`);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al actualizar el producto",
				"Un error ocurrio al intentar actualizar el producto, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handlePhotoGalleryChange = (values) => {
		setPhotoGallery(values);
	};

	if (!id) navigate("/admin/marketProducts?uri=" + uri);

	const handleClientSelect = (value, object) => {
		setSelectedClient(object.value);
	};

	const handleDiscountInput = (e) => {
		if (clientSelected) {
			setProductDiscount(e.target.value);
			setOfferPriceByDiscount(
				calculatePrice(
					productPrice,
					clientSelected.generalDiscount,
					e.target.value,
					0,
					0
				)
			);
		}
	};

	const handlePriceInput = (e) => {
		if (clientSelected) {
			setProductPrice(e);
			setOfferPriceByDiscount(
				calculatePrice(e, clientSelected.generalDiscount, productDiscount, 0, 0)
			);
		}
	};

	const handlePresentationChange = (value) => {
		setUnitName(presentationTypes.filter((p) => p.id === value)[0]?.unit.name);
	};

	const firstDiscountHandler = () => {
		setShowFirstDiscount((prev) => !prev);
	};

	const qtyDiscountHandler = () => {
		setShowTriggerQtyDiscount((prev) => !prev);
	};

	const handleEanCodeInput = (e) => {
		setEanCode(e.target.value);
	};

	const handleLaboratoryCodeInput = (e) => {
		setLaboratoryCode(e.target.value);
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido cargados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/marketProductsIndex?uri=" + uri);
			},
		});
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/marketProductsIndex?uri=" + uri}>
							{uri ? "Productos y ofertas" : "Productos y Precios"}
						</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Editar Producto</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
						style={{
							position: "sticky",
							position: "-webkit-sticky",
							top: "10px",
							overflow: "none",
						}}
					>
						<InnerForm>
							<ProductData>
								<CardContainer>
									<Row gutter={GUTTER}>
										<Col span={24}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Laboratorio
													</label>
												}
												name="clientId"
												// rules={[
												// 	{
												// 		required: true,
												// 		message: "Este campo es obligatorio",
												// 	},
												// ]}
											>
												<Select
													allowClear={false}
													placeholder="Laboratorio"
													value={initialValues.client.name}
													//onChange={handleClientSelect}
													disabled
												>
													{clients?.map((item) => (
														<Option key={item.clientId} value={item.clientId}>
															{item.clientName}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={GUTTER}>
										<Col span={24}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Nombre
													</label>
												}
												name="title"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													// {
													// 	pattern: new RegExp(
													// 		/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
													// 	),
													// 	message:
													// 		"No se aceptan caracteres especiales. Solo letras y numeros",
													// },
												]}
											>
												<Input placeholder="Nombre" />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={GUTTER}>
										<Col span={8}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Contenido del Envase
													</label>
												}
												name="presentationType"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												<Select
													allowClear={true}
													placeholder="Contenido del Envase"
													onChange={handlePresentationChange}
												>
													{presentationTypes.map((item) => (
														<Option key={item.id} value={item.id}>
															{item.name}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>
										<Col
											md={6}
											xl={6}
											style={{
												display: "flex",
												flexDirection: "column",
												gap: 7,
											}}
										>
											<div
												style={{
													color: COLORS.Primary,
													fontWeight: "600",
													whiteSpace: "nowrap",
													marginTop: 1,
												}}
											>
												Cantidad de presentación
											</div>

											<Form.Item
												name="presentationQty"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														pattern: new RegExp(/^(?!\s*$)[0-9\s]*$/),
														message: "Solo numeros positivos",
													},
												]}
											>
												<Input
													type="number"
													placeholder="Cantidad"
													suffix={
														<Typo type="muted" level={6} texto="inputLabel">
															{unitName}
														</Typo>
													}
													style={{ width: 100, marginRight: 10 }}
													min={1}
												/>
											</Form.Item>
										</Col>

										<Col md={5} xl={5}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Código EAN
													</label>
												}
												name="ean13"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														len: 13,
														message: "El ean debe de ser de 13 digitos",
													},
												]}
											>
												<Input
													value={eanCode}
													defaultValue={eanCode}
													onChange={handleEanCodeInput}
													placeholder="Código EAN"
													suffix={
														editUnlock ? (
															<BsLockFill
																onClick={() => setEditUnlock((prev) => !prev)}
																color={COLORS.Secondary}
																style={{ cursor: "pointer" }}
															/>
														) : (
															<BsUnlockFill
																onClick={() => setEditUnlock((prev) => !prev)}
																color={COLORS.Secondary}
																style={{ cursor: "pointer" }}
															/>
														)
													}
													disabled={editUnlock}
												/>
											</Form.Item>
										</Col>
										<Col md={5} xl={5}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Código Laboratorio
													</label>
												}
												name="laboratoryCode"
												rules={[]}
											>
												<Input
													value={laboratoryCode}
													defaultValue={laboratoryCode}
													onChange={handleLaboratoryCodeInput}
													placeholder=""
													suffix={
														editUnlockLaboratoryCode ? (
															<BsLockFill
																onClick={() =>
																	setEditUnlockLaboratoryCode((prev) => !prev)
																}
																color={COLORS.Secondary}
																style={{ cursor: "pointer" }}
															/>
														) : (
															<BsUnlockFill
																onClick={() =>
																	setEditUnlockLaboratoryCode((prev) => !prev)
																}
																color={COLORS.Secondary}
																style={{ cursor: "pointer" }}
															/>
														)
													}
													disabled={editUnlockLaboratoryCode}
												/>
											</Form.Item>
										</Col>
									</Row>
								</CardContainer>
								<CardContainer>
									<Row>
										<Col span={24}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Descripción Corta
													</label>
												}
												name="shortDesc"
											>
												<Input placeholder="Descripción Corta" />
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col span={24}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Descripción Larga
													</label>
												}
												name="longDesc"
											>
												<TextArea rows={3} placeholder="Descripción Larga" />
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={GUTTER}>
										<Col md={4} xl={4}>
											<Form.Item
												name="file"
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Imagen principal
													</label>
												}
											>
												<UploadFileImage
													onChange={handleImageChange}
													urlImage={imageUrl}
													imageHeight="100%"
													imageWidth="100%"
												/>
											</Form.Item>
										</Col>
										{/* 
										{imageUrl && (
											<PreviewImage
												style={{ marginLeft: "-20px" }}
												src={imageUrl}
												alt="avatar"
											/>
										)} */}
										<Col md={14} xl={14}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Galeria
													</label>
												}
											>
												<ImageGallery
													data={photoGallery}
													onChange={handlePhotoGalleryChange}
												/>
											</Form.Item>
										</Col>
									</Row>
								</CardContainer>
								<CardContainer>
									<Row gutter={GUTTER} style={{ alignItems: "flex-end" }}>
										<Col md={5} xl={5} style={{ marginRight: "10px" }}></Col>
										<Col md={5} xl={5} style={{ marginRight: "10px" }}>
											<AntdCheckbox
												style={{
													color: COLORS.Secondary,
													fontWeight: "600",
												}}
												onClick={firstDiscountHandler}
												value={showFirstDiscount}
												checked={showFirstDiscount}
											>
												Activar
												<br />
												oferta
											</AntdCheckbox>
										</Col>
										<Col md={5} xl={5} style={{ marginRight: "10px" }}>
											<AntdCheckbox
												style={{
													color: COLORS.Secondary,
													fontWeight: "600",
												}}
												onClick={qtyDiscountHandler}
												value={showTriggerQtyDiscount}
												checked={showTriggerQtyDiscount}
											>
												Descuento por cantidad
											</AntdCheckbox>
										</Col>
										<Col md={5} xl={5} style={{ marginRight: "10px" }}></Col>
									</Row>
									<Row gutter={GUTTER} style={{ alignItems: "flex-end" }}>
										<Col
											md={5}
											xl={5}
											style={{
												borderRight: `2px solid ${COLORS.LightGrey}`,
												marginRight: "10px",
											}}
										>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Precio
													</label>
												}
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												<InputNumber
													prefix="$"
													placeholder="Precio"
													value={productPrice}
													onChange={handlePriceInput}
													precision={2}
													min={1}
													style={{ width: "70%" }}
												/>
											</Form.Item>
										</Col>
										<Col
											md={5}
											xl={5}
											style={{
												borderRight: `2px solid ${COLORS.LightGrey}`,
												marginRight: "10px",
											}}
										>
											{showFirstDiscount && (
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															<div>1° Descuento</div>
														</label>
													}
													name="discount"
													rules={[
														{
															pattern: new RegExp(/^[0-9]*(\.[0-9]{1,2})?$/),
															message: "Solo numeros positivos",
														},
													]}
												>
													<Input
														type="number"
														suffix="%"
														onChange={handleDiscountInput}
														style={{ width: "70%" }}
													/>
												</Form.Item>
											)}
										</Col>
										<Col
											md={5}
											xl={5}
											style={{
												borderRight: `2px solid ${COLORS.LightGrey}`,
												marginRight: "10px",
											}}
										>
											{showTriggerQtyDiscount && (
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Descuento a aplicar
														</label>
													}
													name="triggerDiscount"
													rules={[
														{
															pattern: new RegExp(/^[0-9]*(\.[0-9]{1,2})?$/),
															message: "Solo numeros positivos",
														},
													]}
												>
													<Input
														suffix="%"
														type="number"
														style={{ width: "70%" }}
													/>
												</Form.Item>
											)}
										</Col>
										<Col md={6} xl={6}></Col>
									</Row>
									<Row gutter={GUTTER} style={{ alignItems: "flex-end" }}>
										<Col
											md={5}
											xl={5}
											style={{
												borderRight: `2px solid ${COLORS.LightGrey}`,
												marginRight: "10px",
											}}
										>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Descuento Base
													</label>
												}
											>
												<Input
													value={clientSelected?.generalDiscount}
													suffix="%"
													disabled
													style={{ width: "70%" }}
												/>
											</Form.Item>
										</Col>
										<Col
											md={5}
											xl={5}
											style={{
												borderRight: `2px solid ${COLORS.LightGrey}`,
												marginRight: "10px",
											}}
										>
											{showFirstDiscount && productDiscount > 0 && (
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Cantidad mínima
														</label>
													}
													name="minQuantity"
												>
													<Input
														type="number"
														placeholder="Cant. mínima"
														defaultValue={1}
														suffix={"un"}
														style={{ width: "70%" }}
													/>
												</Form.Item>
											)}
										</Col>
										<Col
											md={5}
											xl={5}
											style={{
												borderRight: `2px solid ${COLORS.LightGrey}`,
												marginRight: "10px",
											}}
										>
											{showTriggerQtyDiscount && (
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Cantidad mínima
														</label>
													}
													name="triggerQty"
												>
													<Input
														suffix="un"
														type="number"
														style={{ width: "70%" }}
													/>
												</Form.Item>
											)}
										</Col>
										<Col md={6} xl={6}></Col>
									</Row>
									<Row gutter={GUTTER} style={{ alignItems: "flex-end" }}>
										<Col
											md={5}
											xl={5}
											style={{
												borderRight: `2px solid ${COLORS.LightGrey}`,
												marginRight: "10px",
											}}
										>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Precio farmacia
													</label>
												}
											>
												<Input
													type="number"
													prefix="$"
													disabled={true}
													value={offerPriceByDiscount}
													style={{ width: "70%" }}
												/>
											</Form.Item>
										</Col>
										<Col
											md={5}
											xl={5}
											style={{
												borderRight: `2px solid ${COLORS.LightGrey}`,
												marginRight: "10px",
											}}
										>
											{showFirstDiscount && productDiscount > 0 && (
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Cantidad máxima
														</label>
													}
													name="maxQuantity"
												>
													<Input
														type="number"
														placeholder="Cant. máxima"
														suffix={"un"}
														style={{ width: "70%" }}
													/>
												</Form.Item>
											)}
										</Col>
										<Col md={6} xl={6}></Col>
										<Col md={6} xl={6}></Col>
									</Row>
									<Row gutter={GUTTER}>
										<Col
											md={5}
											xl={5}
											style={{
												borderRight: `2px solid ${COLORS.LightGrey}`,
												marginRight: "10px",
											}}
										></Col>
										<Col
											md={5}
											xl={5}
											style={{
												borderRight: `2px solid ${COLORS.LightGrey}`,
												marginRight: "10px",
											}}
										>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														% Descuento aplicado
													</label>
												}
											>
												<Input
													suffix="%"
													disabled={true}
													value={calculatePercentage(
														productPrice,
														clientSelected?.generalDiscount,
														discount
													)}
													style={{ width: "70%" }}
												/>
											</Form.Item>
										</Col>
										<Col md={5} xl={5}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														% Descuento total
													</label>
												}
											>
												<Input
													suffix="%"
													disabled={true}
													value={calculatePercentage(
														productPrice,
														clientSelected?.generalDiscount,
														discount,
														triggerDiscount
													)}
													style={{ width: "70%" }}
												/>
											</Form.Item>
										</Col>
										<Col md={6} xl={6}></Col>
									</Row>
								</CardContainer>
								<CardContainer>
									{selectedClient && (
										<Row gutter={GUTTER}>
											<Col span={18}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Droguerías
														</label>
													}
												>
													<DistributorsFilteredByClient
														initialValues={distributors}
														onChange={setDistributors}
														ean13={eanCode}
														clientId={selectedClient}
													/>
												</Form.Item>
											</Col>
										</Row>
									)}
								</CardContainer>
								{/* <Row gutter={GUTTER}>
										<Col span={24}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Productos Relacionados
													</label>
												}
											>
												<DebounceSelect
													fetchCallback={fetch_products}
													placeholder="Buscar productos relacionados"
													onValuesChange={setRelatedProducts}
													initialValues={relatedProducts}
												/>
											</Form.Item>
										</Col>
									</Row> */}
							</ProductData>

							<PublishSettings>
								<Container>
									<Space
										direction="vertical"
										size="middle"
										style={{ display: "flex" }}
									>
										<Typo level={5} type="primary">
											Publicación
										</Typo>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Publicar desde
												</label>
											}
											name="publishFrom"
										>
											<DatePicker
												style={{
													width: "100%",
												}}
												onChange={handlePublish}
												disabledDate={disabledDate}
												defaultValue={publish[0]}
											/>
										</Form.Item>
										<Typo level={5} type="primary">
											Oferta
										</Typo>
										{!show ? (
											<AntdCheckbox
												style={{ color: COLORS.Secondary, fontWeight: "600" }}
												onClick={ofertaHandler}
											>
												Periodo de Oferta
											</AntdCheckbox>
										) : (
											<>
												{initialValues.offerFrom && initialValues.offerTo ? (
													<Form.Item name="offer">
														<RangePicker
															disabledDate={disabledDateOffer}
															format="DD/MM/YYYY"
															defaultValue={offer}
														/>
													</Form.Item>
												) : (
													<Form.Item name="offer">
														<RangePicker
															disabledDate={disabledDateOffer}
															format="DD/MM/YYYY"
														/>
													</Form.Item>
												)}
											</>
										)}

										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Estado del producto
												</label>
											}
											name="state"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Select
												allowClear={true}
												placeholder="Estado del producto"
											>
												{initialValues.state === "DRAFT"
													? statesWithDraft.map((item) => (
															<Option key={item.id} value={item.id}>
																{item.name}
															</Option>
													  ))
													: states.map((item) => (
															<Option key={item.id} value={item.id}>
																{item.name}
															</Option>
													  ))}
											</Select>
										</Form.Item>

										<Row gutter={GUTTER}>
											<Col span={24}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Marca
														</label>
													}
													name="brand"
												>
													<Select allowClear={true} placeholder="Marca">
														{brands.map((item) => (
															<Option key={item.id} value={item.id}>
																{item.name}
															</Option>
														))}
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={GUTTER}>
											<Col span={16}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Categorías
														</label>
													}
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
												>
													<DebounceSelect
														fetchCallback={fetch_categories}
														placeholder="Buscar categorias"
														onValuesChange={setCategories}
														initialValues={categories}
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={GUTTER}>
											<Col span={24}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Principios Activos
														</label>
													}
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
												>
													<DebounceSelect
														fetchCallback={fetch_active_ingredients}
														placeholder="Buscar principios activos"
														onValuesChange={setActiveIngredients}
														initialValues={activeIngredients}
														disabled={aICheckbox}
													/>
													{/* <Checkbox
														onChange={(e) => handleAICheckbox(e.target.checked)}
														checked={aICheckbox}
													>
														No Aplica
													</Checkbox> */}
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={GUTTER}>
											<Col span={24}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Tipo de Producto
														</label>
													}
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
													name="tagId"
												>
													{tags && (
														<Select
															allowClear={true}
															placeholder="Tipo de Producto"
															defaultValue={defaultTag?.name}
															onChange={(e) => setDefaultTag(e)}
														>
															{tags.map((item) => (
																<Option key={item.id} value={item.id}>
																	{item.name}
																</Option>
															))}
														</Select>
													)}
												</Form.Item>
											</Col>
										</Row>

										<Form.Item>
											<SignInTemplate.AntdButton
												type="Primary"
												color="white"
												bg="Primary"
												htmlType="submit"
												loading={submitting}
												block
											>
												Guardar Cambios
											</SignInTemplate.AntdButton>
										</Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											onClick={handleBack}
											loading={submitting}
											block
										>
											<Typo type="danger" level={6}>
												Volver sin guardar
											</Typo>
										</SignInTemplate.AntdButton>
									</Space>
								</Container>
							</PublishSettings>
						</InnerForm>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
