import React, { useEffect, useState } from "react";
import {
	AntdButton,
	AntdModal,
	Container,
	DistributorContainer,
	DistributorText,
	InnerForm,
	Section,
} from "./styles";
import { Form } from "antd";
import { COLORS, Typo } from "../../../../../layout/theme";
import { AiFillPlusCircle } from "react-icons/ai";
import {
	get_point_of_sale_by_id,
	update_point_of_sale_distributors,
} from "../../../../../../api/endpoints/pointsofsale";
import DistributorCodeLabSellers from "../../../../../common/distributors-code-lab-sellers/DistributorCodeLabSellers";
import DistributorModal from "./components/distributors-modal/DIstributorModal";
import { update_client_distributors } from "../../../../../../api/endpoints/clients";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";

export default function PharmacyUpdateStep({
	pharmacyInfo,
	setStep,
	selectedClient,
	handleBack,
}) {
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const [distributors, setDistributors] = useState(null);
	const [showDistributorsModal, setShowDistributorsModal] = useState(false);
	const [flag, setFlag] = useState(false);

	useEffect(() => {
		const fetch_pointofsale_by_id = async () => {
			const dataPOS = await get_point_of_sale_by_id(
				pharmacyInfo.id,
				pharmacyInfo.userId
			);

			const formData = {
				distributors: dataPOS.distributors?.map((i) => ({
					key: i.id,
					id: i.id,
					code: i.code,
					name: i.name,
					photoUrl: i.photoUrl,
				})),
			};

			setInitialValues(formData);
		};

		if (pharmacyInfo) fetch_pointofsale_by_id();
	}, []);

	const handleClick = () => {
		setShowDistributorsModal(true);
	};

	const handleOk = async () => {
		const newDistributor = distributors.map((d) =>
			d.checked === true ? { ...d, code: "si" } : { ...d, code: null }
		);
		const data = {
			id: selectedClient,
			distributors: newDistributor.map((d) => ({
				id: d.id,
				code: d.code,
				selectedMail: d.selectedMail,
			})),
		};
		await update_client_distributors(data);
		setFlag(!flag);
		setShowDistributorsModal(false);
	};

	const handleCancel = () => {
		setShowDistributorsModal(false);
	};

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				id: pharmacyInfo.id,
				distributors:
					distributors?.map((d) => ({
						id: d.id,
						code: d.code,
					})) || [],
			};

			await update_point_of_sale_distributors(pharmacyInfo.id, postData);

			setStep(4);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Punto de venta editado con exito",
				"El punto de venta se editó con exito"
			);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al editar el punto de venta",
				"Un error ocurrio al intentar editar el punto de venta, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	if (!pharmacyInfo || !initialValues) return <div>Loading...</div>;

	return (
		<Section>
			<Form
				form={form}
				name="basic"
				onFinish={onFinish}
				autoComplete="off"
				requiredMark={false}
				layout="vertical"
				initialValues={initialValues}
			>
				<InnerForm>
					<Typo type="primary" level={4}>
						Droguerías activas para {pharmacyInfo?.name}
					</Typo>
					<DistributorContainer>
						<Form.Item>
							<DistributorCodeLabSellers
								onChange={setDistributors}
								initialValues={initialValues.distributors}
								clientId={selectedClient}
								flag={flag}
							/>
						</Form.Item>
						<DistributorText
							onClick={handleClick}
							textColor={1}
							marginBottom={5}
						>
							<AiFillPlusCircle /> Agregar Droguería
						</DistributorText>
					</DistributorContainer>
					<AntdModal
						title={`Droguerías`}
						visible={showDistributorsModal}
						onOk={handleOk}
						onCancel={handleCancel}
						width={900}
						centered={true}
						destroyOnClose={false}
					>
						<DistributorModal
							blockedValues={initialValues?.distributors}
							setDistributors={setDistributors}
							distributors={distributors}
							isSeller={false}
							isOneMarket={false}
						/>
					</AntdModal>
					<Container>
						<AntdButton
							backGroundColor={COLORS.White}
							loading={submitting}
							onClick={handleBack}
							width={200}
						>
							<Typo type="danger" level={6}>
								{initialValues?.distributors?.filter((d) => d.code !== null)
									?.length !== distributors?.length
									? "Volver sin guardar"
									: "Volver"}
							</Typo>
						</AntdButton>
						<Form.Item>
							<AntdButton
								type="primary"
								htmlType="submit"
								loading={submitting}
								width={200}
							>
								{initialValues?.distributors?.filter((d) => d.code !== null)
									?.length !== distributors?.length
									? "Guardar y continuar"
									: "Continuar"}
							</AntdButton>
						</Form.Item>
					</Container>
				</InnerForm>
			</Form>
		</Section>
	);
}
