import axiosInstance from "../axiosInstance";

const CONTROLLER = "products";

const get_products = async (search, state, clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}&state=${state || ""}&clientId=${
			clientId || ""
		}`
	);
	return response.data;
};

const get_products_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPageCT?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_products_header_info = async (clientId, includeCombo) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetProductsHeader?clientId=${
			clientId || ""
		}&includeCombo=${includeCombo}`
	);
	return response.data;
};

const delete_product = async (id) => {
	const response = await axiosInstance.delete(`${CONTROLLER}/${id}`);
	return response.data;
};

const update_product = async (id, product) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, product, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_product_state = async (state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateProductStatus`,
		state
	);
	return response.data;
};

const update_masive_product_state = async (clientId) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/MasivePublishStateCT?client=${clientId}`
	);
	return response.data;
};

const create_product = async (product) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, product, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const get_products_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const get_group_of_products_by_id = async (ids) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetByIdGroup`, ids, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const get_market_products_page = async (search, state, page, client) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetTotalPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}&client=${client}`
	);
	return response.data;
};

const get_market_products_header_info = async (client) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetTotalProductsHeader?search=${client}`
	);
	return response.data;
};

const get_market_products = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetTotal?search=${search || ""}`
	);
	return response.data;
};

const get_market_products_dapper = async (termino, skip, take) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetTotalDapper`, {
		params: {
			searchTerm: termino,
			skip: skip,
			take: take,
		},
	});
	return response.data;
};

const get_market_products_simplified = async (search, skip, take) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetSimplifiedTotal`, {
		params: {
			search: search,
			skip: skip,
			take: take,
		},
	});
	return response.data;
};

const get_simplified_products_list = async (search, clientId, skip, take) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetSimplifiedList`, {
		params: {
			search: search,
			clientId: clientId,
			skip: skip,
			take: take,
		},
	});
	return response.data;
};

const update_market_product_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateMarketProductState/${id}`,
		state
	);
	return response.data;
};

const add_view = async (id) => {
	const response = await axiosInstance.put(`${CONTROLLER}/AddView`, { id: id });
	return response.data;
};

const export_prices = async (clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/ExportPrices?clientId=${clientId || ""}`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const check_new_prices = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/CheckNewPrices`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const update_prices = async (data) => {
	const response = await axiosInstance.post(`${CONTROLLER}/UpdatePrices`, data);
	return response.data;
};

const get_history_prices = async (clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/HistoryPrices?clientId=${clientId || ""}`
	);
	return response.data;
};

const export_csv = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/ToCsv`);
	return response.data;
};

const get_history_prices_detail = async (data) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPricesHistoryDetail/${data.id}`,
		data
	);
	return response.data;
};

const get_brands_products = async (id, clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetBrandProducts/${id}?c=${clientId}`
	);
	return response.data;
};

const get_products_by_max_discount = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetMaxDiscount`);
	return response.data;
};

const get_client_products = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetClientsProducts?search=${search || ""}`
	);
	return response.data;
};

const get_sliders_products = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetSlidersProducts`);
	return response.data;
};

const get_import_history = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/ImportHistory`);
	return response.data;
};

const get_import_history_detail = async (id) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetImportHistoryDetail/${id}`
	);
	return response.data;
};

const get_market_products_filter = async (client) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetFiltersDapper`, {
		params: {
			Client: client,
		},
	});
	return response.data;
};

const get_market_products_report = async (
	term,
	skip,
	take,
	state,
	productId,
	brandName,
	clientName,
	dateToSearch,
	clientType
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMarketProductsReport`,
		{
			params: {
				searchTerm: term,
				skip: skip,
				take: take,
				state: state,
				productId: productId,
				brandName: brandName,
				clientName: clientName,
				dateToSearch: dateToSearch,
				clientType: clientType,
			},
		}
	);
	return response.data;
};

const export_products_report = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ExportProductsFileReport`,
		data,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const export_products_with_discount = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/ExportProductsWithDiscount`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const update_mobile_discounts = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/UpdateMobileDiscounts`,
		data
	);
	return response.data;
};

const export_mobile_discounts = async (clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/ExportMobileDiscounts?clientId=${clientId || ""}`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const check_new_mobile_discounts = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/CheckNewMobileDiscounts`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const get_laboratory_products_dapper = async (
	termino,
	clientId,
	userId,
	skip,
	page
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetLaboratoryProductsCT`,
		{
			params: {
				searchTerm: termino,
				clientId: clientId,
				userId: userId,
				skip: skip,
				page: page,
			},
		}
	);
	return response.data;
};

const get_products_by_laboratory_and_brand = async (
	termino,
	clientId,
	brandId,
	userId,
	includeCombo,
	skip,
	page
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetProductsByLaboratoryAndBrand`,
		{
			params: {
				searchTerm: termino,
				clientId: clientId,
				brandId: brandId,
				userId: userId,
				includeCombo: includeCombo,
				skip: skip,
				page: page,
			},
		}
	);
	return response.data;
};

const get_laboratory_expo_products_dapper = async (
	termino,
	clientId,
	expoId,
	skip,
	page
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetLaboratoryExpoProducts`,
		{
			params: {
				searchTerm: termino,
				clientId: clientId,
				expoId: expoId,
				skip: skip,
				page: page,
			},
		}
	);
	return response.data;
};

const export_user_discounts = async (clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/ExportUserDiscounts?clientId=${clientId || ""}`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const check_new_user_discounts = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/CheckNewUserDiscounts`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const get_products_trigger_coupon = async (clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetProductListForCouponTrigger?clientId=${clientId}`
	);
	return response.data;
};

const get_seller_pharmacy_products_dapper = async (
	laboratoryId,
	productCondition,
	skip,
	page
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetSellerMarketplaceProductsList`,
		{
			params: {
				laboratoryId: laboratoryId,
				productCondition: productCondition,
				skip: skip,
				page: page,
			},
		}
	);
	return response.data;
};

const export_products_with_discount_mobile = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/ExportProductsWithDiscountMobile`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const create_combo_product = async (product) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/CreateCombo`,
		product,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const update_combo_product = async (id, product) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateCombo/${id}`,
		product,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const import_order_items_quantities = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ImportOrderItemFile`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

export {
	get_products,
	get_products_by_id,
	get_group_of_products_by_id,
	get_products_page,
	delete_product,
	update_product,
	create_product,
	get_products_header_info,
	get_market_products_page,
	get_market_products_header_info,
	get_market_products,
	get_market_products_dapper,
	get_market_products_simplified,
	update_market_product_state,
	update_product_state,
	add_view,
	export_prices,
	check_new_prices,
	update_prices,
	get_history_prices,
	export_csv,
	get_history_prices_detail,
	get_brands_products,
	update_masive_product_state,
	get_products_by_max_discount,
	get_client_products,
	get_sliders_products,
	get_import_history,
	get_import_history_detail,
	get_market_products_filter,
	get_market_products_report,
	get_simplified_products_list,
	export_products_report,
	export_products_with_discount,
	update_mobile_discounts,
	export_mobile_discounts,
	check_new_mobile_discounts,
	get_laboratory_products_dapper,
	get_products_by_laboratory_and_brand,
	get_laboratory_expo_products_dapper,
	export_user_discounts,
	check_new_user_discounts,
	get_products_trigger_coupon,
	get_seller_pharmacy_products_dapper,
	export_products_with_discount_mobile,
	create_combo_product,
	update_combo_product,
	import_order_items_quantities,
};
