import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../../contexts/authContext";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import { ValidatingMessage } from "./styles";
import Image from "../../../common/image/Image";
import MarketLogo from "../../../../assets/images/logo.png";
import { Typo } from "../../../layout/theme";

export default function ConfirmEmail() {
	const { applyCode, user, reloadUser } = useAuth();
	const [submitting, setSubmitting] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const oobCode = searchParams.get("oobCode");

	useEffect(() => {
		if (user?.emailVerified) navigate("/");

		const validate = async () => {
			try {
				setSubmitting(true);
				await applyCode(oobCode);
				await reloadUser();
				navigate("/");
			} catch (error) {
				console.log(error);
			}
		};

		validate();
	}, [applyCode, navigate, oobCode, reloadUser]);

	return (
		<SignInTemplate>
			<SignInTemplate.Main>
				<SignInTemplate.Logo>
					<Image
						src={MarketLogo}
						alt="Logo FarmaTransfer"
						height="45px"
						width="220px"
					/>
				</SignInTemplate.Logo>
				<SignInTemplate.Top>
					<SignInTemplate.Title>
						Validando correo electronico
					</SignInTemplate.Title>
					{!user?.emailVerified && submitting && (
						<ValidatingMessage>Validando...</ValidatingMessage>
					)}
					<Typo level={4}>
						{user?.emailVerified && "Cuenta de correo validada con exito"}
					</Typo>
				</SignInTemplate.Top>
			</SignInTemplate.Main>
		</SignInTemplate>
	);
}
