import React, { useState } from "react";
import {
	AntdButton,
	AntdSteps,
	IconContainer,
	Inner,
	Section,
	StepContainer,
	StepsContainer,
	TitleContainer,
} from "./styles";
import { useCart } from "../../../../contexts/cartContext";
import { useAuth } from "../../../../contexts/authContext";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import {} from "../../../../api/endpoints/templates";
import PharmacySelectionStep from "./components/pharmacy-selection-step/PharmacySelectionStep";
import ProductsToCartStep from "./components/products-to-cart-step/ProductsToCartStep";
import PharmacyCreateStep from "./components/pharmacy-create-step/PharmacyCreateStep";
import { Steps } from "antd";
import Number1 from "../../../../assets/images/number-one-icon.svg";
import Number1Filled from "../../../../assets/images/number-one-solid.svg";
import Number2Grey from "../../../../assets/images/number-two.svg";
import Number2Filled from "../../../../assets/images/number-two-solid.svg";
import Number3Grey from "../../../../assets/images/number-three-icon.svg";
import Number3Filled from "../../../../assets/images/number-three-icon-selected.svg";
import StepCalendarIcon from "../../../../assets/images/calendar-solid.svg";
import StepCalendarIconSelected from "../../../../assets/images/calendar-solid-selected.svg";
import StepPharmacyIcon from "../../../../assets/images/house-chimney-medical-solid.svg";
import StepProductTableIcon from "../../../../assets/images/table-list-solid.svg";
import StepPharmacyIconSelected from "../../../../assets/images/house-chimney-medical-solid-selected.svg";
import StepProductTableIconSelected from "../../../../assets/images/table-list-solid-selected.svg";
import { useNavigate } from "react-router-dom";
import EventSelectionStep from "./components/event-selection-step/EventSelectionStep";
import { CONSTANTS } from "../../../../utils/constants";
import PharmacyUpdateStep from "./components/pharmacy-update-step/PharmacyUpdateStep";

const { Step } = Steps;

export default function SellerExpo({ clientId }) {
	const { user } = useAuth();
	const {
		addProductsToCart,
		emptyCart,
		marketSellerClient,
		selectedClient,
		setSelectedClient,
		qty,
		templateProducts,
		setTemplateProducts,
		expoSellerSteps,
		setExpoSellerSteps,
		expoSellerClientInfo,
		setExpoSellerClientInfo,
		expoSelected,
		setExpoSelected,
		eventProducts,
		setEventProducts,
	} = useCart();
	const navigate = useNavigate();
	const [clients, setClients] = useState(null);
	const [orderTemplate, setOrderTemplate] = useState(false);

	const handleOrderToCart = async () => {
		try {
			const _cartOrder = {
				prods: templateProducts
					.filter((i) => i.quantity > 0)
					.map((i) => ({
						id: i.id,
						quantity: i.quantity,
					})),
				origin: CONSTANTS.PRODUCTS_ORIGIN_EXPO_EVENT,
				originId: expoSelected.id,
			};

			await addProductsToCart(_cartOrder);

			//setExpoSellerSteps(1);
			setTemplateProducts(eventProducts);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Pedido agregado al carro de compras"
			);

			window.scrollTo(0, 0);

			navigate("/cart");
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al enviar pedido al carro de compras",
				"Un error ocurrio al intentar avanzar con el pedido, contacte con soporte a soporte@checkpos.com."
			);
		}
	};

	const handleBack = () => {
		setExpoSellerSteps(1);
		setTemplateProducts(eventProducts);
	};

	const handleBackToDistributorStep = () => {
		setExpoSellerSteps(3);
	};

	return (
		<Inner>
			<StepsContainer>
				<AntdSteps current={expoSellerSteps} onChange={setExpoSellerSteps}>
					<Step
						icon={
							<IconContainer
								src={expoSellerSteps === 0 ? Number1Filled : Number1}
								alt="Step 0"
								style={{ width: 28 }}
							/>
						}
						title={
							<StepContainer>
								<IconContainer
									src={
										expoSellerSteps === 0
											? StepCalendarIconSelected
											: StepCalendarIcon
									}
									alt="Step 1"
									style={{ width: 19 }}
								/>
								<TitleContainer>Evento</TitleContainer>
							</StepContainer>
						}
					/>
					<Step
						icon={
							<IconContainer
								src={expoSellerSteps === 1 ? Number2Filled : Number2Grey}
								alt="Number1"
								style={{ width: 28 }}
							/>
						}
						title={
							<StepContainer>
								<IconContainer
									src={
										expoSellerSteps === 1
											? StepPharmacyIconSelected
											: StepPharmacyIcon
									}
									alt="Step 1"
									style={{ width: 19 }}
								/>
								<TitleContainer>Punto de Venta</TitleContainer>
							</StepContainer>
						}
					/>
					<Step
						icon={
							<IconContainer
								src={expoSellerSteps === 4 ? Number3Filled : Number3Grey}
								alt="Step 3"
								style={{ width: 28 }}
							/>
						}
						title={
							<StepContainer>
								<IconContainer
									src={
										expoSellerSteps === 4
											? StepProductTableIconSelected
											: StepProductTableIcon
									}
									alt="Step 2"
									style={{ width: 19 }}
								/>
								<TitleContainer>Productos</TitleContainer>
							</StepContainer>
						}
					/>
				</AntdSteps>
			</StepsContainer>
			{expoSellerSteps === 0 && (
				<EventSelectionStep
					clientId={clientId}
					setStep={setExpoSellerSteps}
					expoSelected={expoSelected}
					setExpoSelected={setExpoSelected}
					setEventProducts={setEventProducts}
				/>
			)}
			{expoSellerSteps === 1 && (
				<PharmacySelectionStep
					clientId={clientId}
					expoSelected={expoSelected}
					clients={clients}
					setClients={setClients}
					selectedClient={selectedClient}
					setSelectedClient={setSelectedClient}
					setClientInfo={setExpoSellerClientInfo}
					marketSellerClient={marketSellerClient}
					setStep={setExpoSellerSteps}
					eventProducts={eventProducts}
					setTemplateProducts={setTemplateProducts}
					emptyCart={emptyCart}
					qty={qty}
				/>
			)}
			{expoSellerSteps === 2 && (
				<PharmacyCreateStep
					laboratoryId={clientId}
					pharmacyInfo={expoSellerClientInfo}
					setPharmacyInfo={setExpoSellerClientInfo}
					setStep={setExpoSellerSteps}
					setClientInfo={setExpoSellerClientInfo}
					setSelectedClient={setSelectedClient}
					marketSellerClient={marketSellerClient}
				/>
			)}
			{expoSellerSteps === 3 && (
				<PharmacyUpdateStep
					pharmacyInfo={expoSellerClientInfo}
					setStep={setExpoSellerSteps}
					selectedClient={selectedClient}
					handleBack={handleBack}
				/>
			)}
			{expoSellerSteps === 4 && selectedClient && (
				<ProductsToCartStep
					clientInfo={expoSellerClientInfo}
					expoSelected={expoSelected?.id}
					templateProducts={templateProducts}
					setTemplateProducts={setTemplateProducts}
					orderTemplate={orderTemplate}
					user={user}
					selectedClient={selectedClient}
					clientId={clientId}
					step={expoSellerSteps}
					handleOrderToCart={handleOrderToCart}
					eventProducts={eventProducts}
					handleBack={handleBack}
					handleBackToDistributorStep={handleBackToDistributorStep}
				/>
			)}
			{expoSellerSteps === 4 &&
				templateProducts &&
				templateProducts.length > 0 && (
					<Section onClick={handleOrderToCart} style={{ alignItems: "center" }}>
						<AntdButton>Enviar pedido al carro</AntdButton>
					</Section>
				)}
		</Inner>
	);
}
