import React, { useEffect, useMemo, useState } from "react";
import {
	ButtonContainer,
	CompaginationDiv,
	ContainerTitle,
	InfoItemNumber,
	InfoProductNumber,
	InfoSection,
	InfoSubSection,
	InfoTitle,
	InfoTitleContainer,
	InfoTitleItemQuantity,
	InfoTitleProductQuantity,
	Inner,
	LeftSection,
	ModalContainer,
	OptionsSection,
	ProductsSection,
	RightSection,
	SearchContainer,
	Section,
	TitleSectionContainer,
} from "./styles";
import { useNavigate } from "react-router-dom";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { Button, Input, Modal, Space } from "antd";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import { create_template } from "../../../../../api/endpoints/templates";
import { get_market_products_simplified } from "../../../../../api/endpoints/products";
import Image from "../../../../common/image/Image";
import {
	CloseCircleOutlined,
	LeftOutlined,
	RightCircleOutlined,
	RightOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Main from "../../../../layout/main/Main";
import TemplatesTable from "../components/templates-table/TemplatesTable";
import ProductsTable from "./components/products-table/ProductsTable";
import Slider from "../../../../common/slider/Slider";
import { SwiperSlide } from "swiper/react";
import TemplatesLaboratoriesSlideContainer from "../../../../containers/product-slide/TemplatesLaboratoriesSlideContainer";
import { get_stores } from "../../../../../api/endpoints/stores";
import TemplateModal from "../../../../common/template-modal/TemplateModal";
import { COLORS, Typo } from "../../../../layout/theme";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { useAuth } from "../../../../../contexts/authContext";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import debounce from "lodash/debounce";
let productIndex = null;

export default function CreateTemplate() {
	const [templateProducts, setTemplateProducts] = useState([]);
	const navigate = useNavigate();
	const { userdata, user, updateStartGuideProgress } = useAuth();
	const [submitting, setSubmitting] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [searchByStore, setSearchByStore] = useState(null);
	const [posTitle, setPosTitle] = useState(null);
	const [show, setShow] = useState(false);
	const [modalID, setModalID] = useState(null);
	const [products, setProducts] = useState(null);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(50);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchModalShow, setSearchModalShow] = useState(false);

	const handleDetail = (id) => {
		setShow(true);
		setModalID(id);
	};

	const handleOk = () => {
		setShow(false);
	};

	const handleCancel = () => {
		setShow(false);
	};

	const productColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			width: 100,
			render: (value, record, index) => (
				<div key={`${value}`}>
					<Image
						width={40}
						height={40}
						src={value}
						alt={record.title}
						onClick={() => handleDetail(record.id)}
					/>
					{modalID === record.id && show === true ? (
						<Modal
							open={show}
							onOk={handleOk}
							onCancel={handleCancel}
							width={900}
							centered={true}
							footer={null}
							destroyOnClose={true}
						>
							<TemplateModal id={record.id} />
						</Modal>
					) : null}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t.length < 15 ? 18 : 16}
					>
						{t}
					</Typo>
					{"  "}
					<Typo type="muted" lineHeight={1} level={5}>
						{item?.presentationName}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			width: 100,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(index);
						}}
					>
						<RightCircleOutlined />
					</Button>
				</Space>
			),
		},
	];

	const fetch_products = async (search, skipValue) => {
		setLoading(true);
		try {
			const res = await get_market_products_simplified(
				search,
				skipValue !== null ? skipValue : skip,
				take
			);
			setProducts(
				res.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);
			setData(
				res.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setSearchModalShow(true);
		}
	};

	//////**********************************************************//////

	const handleTableChange = (values) => {
		const _template = templateProducts.map((p, idx) =>
			idx === values.index
				? {
						...p,
						quantity: parseInt(values.value),
				  }
				: { ...p }
		);
		setTemplateProducts(_template);
	};

	//////******************************************************** */

	const handleAddToTemplate = async (e, q) => {
		const _template = data?.filter((p, idx) => idx === e);
		productIndex = _template;
		const auxTemplate = templateProducts.concat(_template);

		const TemplateSinDuplicados = auxTemplate.reduce(
			(acumulador, valorActual) => {
				const elementoYaExiste = acumulador.find(
					(elemento) => elemento.id === valorActual.id
				);
				if (elementoYaExiste) {
					return acumulador.map((elemento) => {
						if (elemento.id === valorActual.id) {
							return {
								...elemento,
								quantity: elemento.quantity + valorActual.quantity,
							};
						}
						return elemento;
					});
				}

				return [...acumulador, valorActual];
			},
			[]
		);
		setTemplateProducts(
			TemplateSinDuplicados.sort((a, b) => {
				const titleA = a.title.toLowerCase();
				const titleB = b.title.toLowerCase();
				if (titleA < titleB) {
					return -1;
				}
				if (titleA > titleB) {
					return 1;
				}

				return 0;
			})
		);
	};

	//////******************************************************** */

	const handleDelete = (index) => {
		const _template = templateProducts.filter((p, idx) => idx !== index);
		setTemplateProducts(_template);
	};

	//////******************************************************** */

	const onFinish = async (values) => {
		try {
			if (posTitle === null || posTitle === "") {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"El Nombre de la Plantilla de Compras es obligatorio"
				);
				return;
			}

			if (templateProducts.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"La Plantilla de Compras debe contener al menos un producto"
				);
				return;
			}

			setSubmitting(true);

			const postData = {
				name: posTitle,
				products:
					templateProducts?.map((element) => {
						return {
							quantity: element.quantity,
							id: element.id,
						};
					}) || [],
			};

			const res = await create_template(postData);

			if (userdata?.guideProgress === 2) {
				updateStartGuideProgress(user.uid, 3);
				navigate("/startGuide");
			} else {
				navigate(`/admin/templates/`);
			}

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Plantilla de compra creada con exito",
				"La plantilla de compra se creo con exito"
			);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear la plantilla de compra",
				"Un error ocurrio al intentar crear la plantilla de compra, contacte con soporte a soporte@checkpos.com."
			);
			console.log(error);
		} finally {
			setSubmitting(false);
		}
	};

	const handleTitleInput = (e) => {
		setPosTitle(e.target.value);
	};

	useEffect(() => {
		if (productIndex) {
			setData(data?.filter((p, idx) => p.id !== productIndex[0]?.id));
		}
	}, [productIndex]);

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido completados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/templates/");
			},
		});
	};

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const searchClickHandler = () => {
		setCurrentPage(1);
		fetch_products(searchByStore, 0);
	};

	const eventHandler = (e) => {
		setCurrentPage(1);
		setSearchByStore(e.target.value);
		fetch_products(e.target.value, 0);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 600), []);

	const handleDecrement = () => {
		if (currentPage > 1) {
			const cp = currentPage - 1;
			const skipValue = skip - 50;

			fetch_products(searchByStore, skipValue);

			setCurrentPage(cp);
			setSkip((prev) => prev - 50);
		}
	};

	const handleIncrement = () => {
		const cp = currentPage + 1;
		const skipValue = skip + 50;

		fetch_products(searchByStore, skipValue);

		setCurrentPage(cp);
		setSkip((prev) => prev + 50);
	};

	const handleModalOk = () => {
		setSearchByStore(null);
		setSearchModalShow(false);
	};

	const handleModalCancel = () => {
		setSearchByStore(null);
		setSearchModalShow(false);
	};

	return (
		<Main>
			<Main.Breadcrumb>
				<Main.BreadcrumbItem>
					<Link to="/">Home</Link>
				</Main.BreadcrumbItem>
				<Main.BreadcrumbSeparator />
				<Main.BreadcrumbItem>
					<Link to="/admin/templates">Plantillas de compra</Link>
				</Main.BreadcrumbItem>
				<Main.BreadcrumbSeparator />
				<Main.ActiveBreadcrumbItem>
					Crear Plantilla de compra
				</Main.ActiveBreadcrumbItem>
			</Main.Breadcrumb>
			<Main.Body>
				<Inner>
					<LeftSection></LeftSection>
					<ProductsSection>
						<TitleSectionContainer>
							<InfoTitleContainer>
								<InfoTitle>Nombre plantilla</InfoTitle>
								<Input
									placeholder="Ingrese el nombre de la plantilla"
									onChange={handleTitleInput}
									value={posTitle}
									style={{ width: "250px" }}
								/>
							</InfoTitleContainer>
							<SearchContainer>
								<AntdSearch
									allowClear
									placeholder="Buscar productos"
									width={459}
									onChange={(e) => setSearchByStore(e.target.value)}
									value={searchByStore}
									onPressEnter={(e) =>
										e.target.value.toUpperCase() === "RX" ||
										e.target.value.toUpperCase() === "OTC"
											? debouncedEventHandler(e)
											: e.target.value.length > 3
											? debouncedEventHandler(e)
											: console.log(e.target.value)
									}
									disabled={loading}
									callbackSearch={searchClickHandler}
								/>
							</SearchContainer>
						</TitleSectionContainer>

						<Modal
							open={searchModalShow}
							onOk={handleModalOk}
							onCancel={handleModalCancel}
							width={900}
							centered={true}
							footer={null}
							destroyOnClose={true}
						>
							<ModalContainer>
								{data && data.length > 0 && (
									<CompaginationDiv>
										{currentPage !== 1 && (
											<Button
												size="small"
												shape="circle"
												onClick={handleDecrement}
											>
												<LeftOutlined />
											</Button>
										)}
										{currentPage}
										{data.length === 50 && (
											<Button
												size="small"
												shape="circle"
												onClick={handleIncrement}
												disabled={data.length < 50 ? true : false}
											>
												<RightOutlined />
											</Button>
										)}
									</CompaginationDiv>
								)}
								<ProductsTable
									data={data}
									columns={productColumns}
									loading={loading}
									scrollData={{ y: 350 }}
								/>
							</ModalContainer>
						</Modal>
						<InfoSection>
							<InfoSubSection>
								<InfoTitleProductQuantity>
									Cantidad de productos:
								</InfoTitleProductQuantity>
								<InfoProductNumber>
									{templateProducts?.length}
								</InfoProductNumber>
							</InfoSubSection>
							<InfoSubSection>
								<InfoTitleItemQuantity>
									Cantidad de unidades:
								</InfoTitleItemQuantity>
								<InfoItemNumber>
									{templateProducts?.reduce(function (prev, current) {
										return prev + +current.quantity;
									}, 0)}
								</InfoItemNumber>
							</InfoSubSection>
							<TemplatesTable
								data={templateProducts}
								onDelete={handleDelete}
								onChange={handleTableChange}
								scrollData={{ y: 360 }}
							></TemplatesTable>
							<ButtonContainer>
								<SignInTemplate.AntdButton
									type="Primary"
									color="white"
									bg="Primary"
									onClick={onFinish}
									loading={submitting}
								>
									Crear plantilla de compra
								</SignInTemplate.AntdButton>
								<SignInTemplate.AntdButton
									type="Primary"
									loading={submitting}
									onClick={handleBack}
								>
									<Typo type="danger" level={6}>
										Volver sin guardar
									</Typo>
								</SignInTemplate.AntdButton>
							</ButtonContainer>
						</InfoSection>
					</ProductsSection>
					<RightSection></RightSection>
				</Inner>
			</Main.Body>
		</Main>
	);
}
