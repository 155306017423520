import axiosInstance from "../axiosInstance";

const CONTROLLER = "cart";

const get_cart = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetCartCT`);
	return response.data;
};

const add_product_to_cart = async (productId, quantity, isPlus) => {
	const response = await axiosInstance.post(CONTROLLER, {
		productId,
		quantity,
		isPlus,
	});
	return response.data;
};

const add_products_to_cart = async (prods) => {
	const response = await axiosInstance.post(`${CONTROLLER}/AddItems`, {
		prods,
	});
	return response.data;
};

const add_items_group_to_cart = async (prods) => {
	const response = await axiosInstance.post(`${CONTROLLER}/AddProducts`, prods);
	return response.data;
};

const update_cart_options = async (data) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateOptionsCT`,
		data
	);
	return response.data;
};

const delete_cart_item = async (data) => {
	const response = await axiosInstance.put(`${CONTROLLER}/Delete`, data);
	return response.data;
};

const empty_cart = async (data) => {
	const response = await axiosInstance.put(`${CONTROLLER}/Empty`, data);
	return response.data;
};

const update_cart_item = async (data) => {
	const response = await axiosInstance.put(`${CONTROLLER}/UpdateItemCT`, data);
	return response.data;
};

const update_cart_client = async (data) => {
	const response = await axiosInstance.put(`${CONTROLLER}/UpdateClient`, data);
	return response.data;
};

export {
	get_cart,
	add_product_to_cart,
	add_products_to_cart,
	update_cart_options,
	delete_cart_item,
	update_cart_item,
	empty_cart,
	add_items_group_to_cart,
	update_cart_client,
};
