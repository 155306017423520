import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ImageGallery from "../../../common/image-gallery/ImageGallery";
import {
	get_client,
	update_client_store,
} from "../../../../api/endpoints/clients";
import {
	AlertContainer,
	ImageSection,
	Inner,
	PublishSection,
	StoreAntdButton,
} from "./styles";
import { Checkbox, Form } from "antd";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import { COLORS, Typo } from "../../../layout/theme";
import { useAuth } from "../../../../contexts/authContext";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { GoAlert } from "react-icons/go";

export default function LaboratoryStore() {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const { user, userdata, updateStartGuideProgress } = useAuth();
	const [submitting, setSubmitting] = useState(false);
	const [photoGallery, setPhotoGallery] = useState([]);
	const [activeBannerStore, setActiveBannerStore] = useState(false);
	const [data, setData] = useState(null);
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	useEffect(() => {
		const fetch_client = async () => {
			const data = await get_client();
			setData(data);

			setPhotoGallery(
				data.client.photoGallery?.map((f) => ({
					uid: f.id,
					name: f.fileName,
					status: "done",
					url: f.url,
				})) || []
			);

			setActiveBannerStore(
				data.client.activeBannerStore ? data.client.activeBannerStore : false
			);
		};

		fetch_client();
	}, []);

	const handlePhotoGalleryChange = (values) => {
		setPhotoGallery(values);
	};

	const handleChange = (e) => {
		setActiveBannerStore(e.target.checked);
	};

	const onFinish = async (values) => {
		try {
			if (activeBannerStore && photoGallery.length === 0) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Esta intentado guardar una sección de Banners activa sin imágenes cargadas"
				);
				setSubmitting(false);
				return;
			}

			setSubmitting(true);

			const _store = {
				activeBannerStore: activeBannerStore,
				existingPhotoGallery: photoGallery?.map((i) => i.uid),
			};

			const formData = new FormData();

			photoGallery.forEach((f) => {
				formData.append("files", f.originFileObj);
			});

			formData.append("data", JSON.stringify(_store));

			const res = await update_client_store(data.id, formData);

			// if (userdata?.guideProgress === 7) {
			// 	updateStartGuideProgress(user.uid, 8);
			// 	navigate("/startGuide");
			// } else {
			navigate(`/${uri}`);
			// }

			openNotificationWithIcon(TYPE.SUCCESS, "Tienda actualizada con éxito");
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al actualizar la tienda",
				"Un error ocurrio al intentar actualizar la tienda, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	// const handleBackToTheGuide = () => {
	// 	updateStartGuideProgress(user.uid, 8);
	// 	navigate("/startGuide");
	// };

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						{uri ? "Banner Tienda" : "Tienda"}
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<Form
					form={form}
					name="edit-store"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
				>
					<Inner>
						<ImageSection>
							<Typo type="primary" level={5}>
								{uri ? "Banners para la tienda" : "Imágenes para la Tienda"}
							</Typo>
							<ImageGallery
								data={photoGallery}
								onChange={handlePhotoGalleryChange}
							/>
						</ImageSection>

						<PublishSection>
							<Form.Item>
								<Checkbox onChange={handleChange} checked={activeBannerStore}>
									<Typo type="secondary" level={6}>
										Banner Activos
									</Typo>
								</Checkbox>
							</Form.Item>
							<StoreAntdButton
								type="Primary"
								color="white"
								bg="Primary"
								htmlType="submit"
								loading={submitting}
								style={{ width: "150px" }}
							>
								Guardar
							</StoreAntdButton>
						</PublishSection>
					</Inner>
				</Form>
				{/* {userdata?.guideProgress === 7 && (
					<SignInTemplate.AntdButton
						type="primary"
						bg="Primary"
						color="White"
						icon={<QuestionCircleOutlined />}
						onClick={handleBackToTheGuide}
						style={{
							width: "250px",
							fontWeight: "400",
							marginTop: "30px",
						}}
					>
						Volver a la Guia de Inicio
					</SignInTemplate.AntdButton>
				)} */}
				<AlertContainer>
					<GoAlert style={{ color: COLORS.Secondary }} />{" "}
					<Typo type="secondary" level={6}>
						La imagen debe tener 1500px de ancho y 200px de alto para una
						correcta visualización en la tienda
					</Typo>
				</AlertContainer>
			</Main.Body>
		</Main>
	);
}
