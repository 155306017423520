import React, { useState, useEffect } from "react";
import { Form, Modal, Divider, Space, Button, Tooltip, Checkbox } from "antd";
import {
	delete_cart_item,
	get_cart,
	update_cart_item,
	update_cart_options,
} from "../../../../../../api/endpoints/cart";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import {
	AddTemplateIconContainer,
	AddTemplateIconParagraph,
	CartAntdButton,
	CartDelete,
	CartTempButton,
	Container,
	DiscountsAlertContainer,
	DiscountsContainer,
	DistributorError,
	DistributorErrorContainer,
	DistributorSelect,
	DistributorTableSelect,
	InfoIva,
	InfoNumber,
	InfoNumberItems,
	InfoSection,
	InfoSubSection,
	InfoSubTitle,
	InfoTitle,
	InfoTitleBuy,
	InfoTitleProductQuantity,
	InfoTitleProductQuantityItems,
	InfoTitleTotal,
	Inner,
	OptionsSection,
	PointOfSaleSelect,
	PresentationTitle,
	PriceContainer,
	ProductsSection,
	Section,
	SectionTitle,
	SelectContainer,
	TriggerQtyContainer,
	WhiteSpace,
} from "./styles";
import ProductsTable from "./components/products-table/ProductsTable";
import ModalTemplates from "./components/modal-templates/ModalTemplates";
import {
	add_template_to_cart,
	get_templates,
} from "../../../../../../api/endpoints/templates";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import debounce from "lodash/debounce";
import { useCart } from "../../../../../../contexts/cartContext";
import {
	get_points_of_sale_cart,
	get_point_of_sale_by_id,
} from "../../../../../../api/endpoints/pointsofsale";
import Image from "../../../../../common/image/Image";
import AddTempIcon from "../../../../../../assets/images/circle-plus-solid.svg";
import { COLORS, Typo } from "../../../../../layout/theme";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FaImages } from "react-icons/fa";
import SelectQuantity from "./components/select-quantity/SelectQuantity";
import SliderProduct from "../../../../../common/slider-products/SliderProducts";
import { SwiperSlide } from "swiper/react";
import OfferSlideContainer from "../../../../../containers/product-slide/OfferSlideContainer";
import { get_sliders_products } from "../../../../../../api/endpoints/products";
import { calculatePSLFinalDiscount } from "../../../../../../utils/calculatePrice";

export default function CartProducts({
	onSubmitSuccess,
	selectedPointsOfSale,
	setSelectedPointsOfSale,
	pointOfSaleDistributors,
	setPointOfSaleDistributors,
	selector,
	setSelector,
	products,
	setProducts,
	setCheckoutDetail,
	//userId = "",
	userdata,
}) {
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const [totals, setTotals] = useState({
		totalProducts: 0,
		subtotal: 0,
		saved: 0,
		total: 0,
	});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [templateList, setTemplateList] = useState([]);
	const [selectTemplate, setSelectTemplate] = useState("");
	const [initialModalValues, setInitialModalValues] = useState(null);
	const [flag, setFlag] = useState(false);
	const [pointOfSale, setPointOfSale] = useState(null);
	const { qty, setQty, userId } = useCart();
	const [templateName, setTemplateName] = useState(null);
	const [sliderData, setSliderData] = useState(null);
	const [distributorsCheck, setDistributorsCheck] = useState(false);
	let _products = [];

	const columns = [
		{
			title: () => {
				return (
					<div
						style={{
							fontSize: "18px",
						}}
					>
						<FaImages style={{ color: COLORS.White }} />
					</div>
				);
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			heigth: 100,
			align: "center",
			render: (photoUrl) => <Image src={photoUrl} width={80} height={80} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "title",
			key: "title",
			width: 140,
			align: "center",
			render: (value, record) => (
				<>
					<InfoTitle>{value}</InfoTitle>
					<PresentationTitle>{record.presentationName}</PresentationTitle>
					<Typo type="muted" level={7}>
						{record.clientName}
					</Typo>
				</>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Precio</div>,
			dataIndex: "price",
			key: "price",
			align: "center",
			render: (value, record) => (
				<Typo type="muted" level={6}>
					$ {numberWithDotAndCommas(value.toFixed(2))}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "price",
			key: "discount",
			align: "center",
			render: (value, record) => (
				<>
					{record.campaignDiscount > 0 && (
						<DiscountsAlertContainer>
							<Tooltip
								title={`${record.campaignDiscount}% de descuento por campaña ${record.campaignTitle}`}
								placement="topLeft"
								arrowPointAtCenter
							>
								<ExclamationCircleOutlined />
							</Tooltip>
						</DiscountsAlertContainer>
					)}
					{record.isComboProduct ? (
						<DiscountsContainer>
							<Typo type="success" level={9}>
								{record.productNotes}
							</Typo>
						</DiscountsContainer>
					) : (
						<DiscountsContainer>
							<PriceContainer>
								{record.generalDiscount !== 0 ? (
									<Typo type="secondary" level={10} translate="no">
										PVP
									</Typo>
								) : (
									<Typo type="secondary" level={10} translate="no">
										PSD
									</Typo>
								)}
								<Typo type="secondary" level={6}>
									{numberWithDotAndCommas(
										(100 - (record.estimatedPrice * 100) / value).toFixed(2)
									)}{" "}
									%
								</Typo>
							</PriceContainer>
							{record.generalDiscount !== 0 && (
								<PriceContainer>
									<Typo type="success" level={11}>
										PSL
									</Typo>
									<Typo type="success" level={9}>
										{numberWithDotAndCommas(
											calculatePSLFinalDiscount(
												record.estimatedPrice,
												value,
												record.generalDiscount
											).toFixed(2)
										)}{" "}
										%
									</Typo>
								</PriceContainer>
							)}
						</DiscountsContainer>
					)}
				</>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Final</div>,
			dataIndex: "offerPrice",
			key: "offerPrice",
			align: "center",
			render: (value, record) =>
				record.isComboProduct ? (
					<Typo type="muted" level={6}>
						-
					</Typo>
				) : (
					<Typo type="primary" level={6}>
						$ {numberWithDotAndCommas(record.estimatedPrice.toFixed(2))}
					</Typo>
				),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cantidad</div>,
			dataIndex: "quantity",
			key: "quantity",
			align: "center",
			render: (quantity, record, index) => (
				<SelectContainer>
					<SelectQuantity
						product={{ quantity, record, index }}
						onChange={handleTableChange}
					/>
					{record.triggerQty !== 0 && (
						//quantity < record.triggerQty &&
						<TriggerQtyContainer translate="no">
							A partir de {record.triggerQty} un. %{record.triggerDiscount} OFF
						</TriggerQtyContainer>
					)}
				</SelectContainer>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White, textAlign: "center" }}>
					Droguería
				</div>
			),
			key: "action",
			width: 180,
			render: (_p, record, index) => (
				<Space size="middle">
					{selector ? (
						_p.distributors?.find((d) => d.id === selector) ? (
							_p.distributors?.map((d) => {
								if (d.id === selector) {
									return (
										<DistributorTableSelect
											style={{ width: 150 }}
											onChange={handleDistributorTableChange}
											disabled={!distributorsCheck}
											defaultValue={d.id + "@" + _p.productId}
											condition={distributorsCheck}
										>
											{pointOfSaleDistributors
												?.filter((el) => {
													return _p.distributors.some((f) => {
														return f.id === el.id && f.isActive;
													});
												})
												.map((d) => {
													return (
														<DistributorTableSelect.Option
															key={d.id}
															value={d.id + "@" + _p.productId}
														>
															{d.name}
														</DistributorTableSelect.Option>
													);
												})}
										</DistributorTableSelect>
									);
								} else {
									return null;
								}
							})
						) : (
							<DistributorErrorContainer>
								{_p.distributorName || _p.distributorId ? (
									<></>
								) : (
									<DistributorError type="warning" level={8}>
										Producto no disponible para esta droguería
									</DistributorError>
								)}
								<DistributorTableSelect
									style={{ width: 150 }}
									onChange={handleDistributorTableChange}
									disabled={false}
									condition={distributorsCheck}
									//defaultValue={_p.distributorName}
								>
									{pointOfSaleDistributors
										?.filter((el) => {
											return _p.distributors.some((f) => {
												return f.id === el.id && f.isActive;
											});
										})
										.map((d) => {
											return (
												<DistributorTableSelect.Option
													key={d.id}
													value={d.id + "@" + _p.productId}
												>
													{d.name}
												</DistributorTableSelect.Option>
											);
										})}
								</DistributorTableSelect>
							</DistributorErrorContainer>
						)
					) : (
						<></>
					)}
				</Space>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}></div>,
			key: "action",
			width: 60,
			align: "center",
			render: (_, record, index) => (
				<Space size="small">
					<Button type="link" onClick={() => handleTableDelete(index)}>
						<CartDelete>X</CartDelete>
					</Button>
				</Space>
			),
		},
	];

	useEffect(() => {
		const fetch_slider_data = async () => {
			const res = await get_sliders_products();
			setSliderData(res);
		};
		fetch_slider_data();
	}, [flag]);

	const fetch_data_template = async () => {
		const res = await get_templates();
		setTemplateList(
			res
				?.map((c) => ({
					key: c.id,
					id: c.id,
					name: c.name,
					deleted: c.deleted,
					quantityItems: c.quantityItems,
					totalProductQuantity: c.items?.reduce(function (prev, current) {
						return prev + +current.quantity;
					}, 0),
				}))
				.filter((template) => template.deleted === false)
		);
	};

	useEffect(() => {
		fetch_data_template();
	}, []);

	useEffect(() => {
		if (!products) return;

		const totalProducts = products.reduce(
			(acc, item) => acc + item.quantity,
			0
		);

		const totalItems = products.length;

		const subtotal = products.reduce(
			(acc, item) => acc + item.quantity * item.price,
			0
		);

		const offers = products.reduce(
			(acc, item) =>
				acc +
				(item.estimatedPrice
					? item.quantity * item.estimatedPrice
					: item.quantity * item.price),
			0
		);

		const saved = Number(
			Math.round(parseFloat(subtotal - offers + "e" + 2)) + "e-" + 2
		).toFixed(2);

		const total = Number(
			Math.round(parseFloat(((subtotal - saved) * 100) / 100 + "e" + 2)) +
				"e-" +
				2
		).toFixed(2);

		setTotals({
			totalProducts,
			totalItems,
			subtotal,
			total,
			saved,
		});

		setQty(totalProducts);
	}, [products, flag]);

	useEffect(() => {
		const fetch_data = async () => {
			const res = await get_cart();
			setProducts(
				res.cart?.items?.map((c) => ({
					key: c.product.id,
					id: c.productId,
					productId: c.productId,
					photoUrl: c.product.photoUrl,
					ean13: c.product.ean13,
					presentationName: c.product.presentation?.name,
					quantity: c.quantity,
					price: c.product.price,
					offerPrice: c.product.offerPrice,
					generalDiscount: c.product.client.generalDiscount,
					discount: c.product.discount,
					offerFrom: c.product.publishFrom, //descuentos mobile no tienen fecha limite asociada
					offerTo: c.product.publishTo,
					total: c.product.price * c.quantity,
					title: c.product.title,
					clientName: c.product.client.name,
					clientId: c.product.client.id,
					minQuantity: c.product.minQuantity,
					maxQuantity: c.product.maxQuantity,
					triggerQty: c.product.triggerQty,
					triggerDiscount: c.product.triggerDiscount,
					saleCommision: c.product.saleCommision,
					campaignDiscount: 0,
					quantityDiscount: 0,
					couponDiscount: 0,
					isComboProduct: c.product.isComboProduct,
					productNotes: c.product.notes,
					estimatedPrice:
						c.quantity >= c.product.triggerQty
							? c.product.offerPrice -
							  c.product.offerPrice * (c.product.triggerDiscount / 100)
							: c.product.offerPrice,
					distributorId: c.distributor?.id,
					distributorName: c.distributor?.name,
					distributors: c.product.distributors
						?.map((d) => ({
							key: d.distributor.id,
							id: d.distributor.id,
							name: d.distributor.name,
							isActive: d.distributor.isActive,
							code: d.code,
						}))
						?.filter((o) => o.code !== "" && o.code !== null && o.isActive),
				})) || []
			);
		};

		fetch_data();
	}, [flag]);

	useEffect(() => {
		const fetch_points_of_sale = async () => {
			const points = await get_points_of_sale_cart("", userId);

			setPointOfSale(
				points
					.filter((p) => p.deleted === false)
					.map((p) => ({ ...p, key: p.id }))
			);
		};

		fetch_points_of_sale();
	}, [userId]);

	useEffect(() => {
		const fetch_pointofsale_by_id = async () => {
			const dataPOS = await get_point_of_sale_by_id(
				selectedPointsOfSale,
				userId
			);

			setPointOfSaleDistributors(
				dataPOS.distributors
					?.filter((o) => o.selected)
					?.map((i) => ({
						key: i.id,
						id: i.id,
						code: i.code,
						name: i.name,
					}))
					?.filter((o) => o.code !== "" && o.code !== null && o.code !== "si")
			);
		};

		setSelector(null);
		setDistributorsCheck(false);

		if (selectedPointsOfSale) {
			fetch_pointofsale_by_id();
		}
	}, [selectedPointsOfSale]);

	useEffect(() => {
		if (pointOfSale && pointOfSale.length === 1) {
			//setFlag(!flag);
			setSelectedPointsOfSale(pointOfSale[0].id);
			setCheckoutDetail((c) => ({
				...c,
				pointOfSale: pointOfSale[0].name,
			}));
		}
	}, [pointOfSale]);

	const handleTableChange = debounce(async (values) => {
		let _campaignsProducts = [];
		const _products = products.map((p, idx) =>
			idx === values.index
				? {
						...p,
						quantity: parseInt(values.value),
						total: parseInt(values.value) * p.price,
				  }
				: { ...p }
		);

		const _selectedProduct = _products.find((p, idx) => idx === values.index);

		_campaignsProducts = await update_cart_item({
			product: {
				id: _selectedProduct.id,
				quantity: parseInt(values.value),
				distributorId: _selectedProduct.distributorId,
				pointOfSaleCart: selectedPointsOfSale,
			},
		});

		_products.forEach((prodInCart) => {
			let prodFromCampaign = _campaignsProducts?.campaignsProducts?.find(
				(prodInCampaign) => prodInCampaign.productId === prodInCart.id
			);
			if (prodFromCampaign) {
				prodInCart.campaignTitle = prodFromCampaign.campaignTitle;
				prodInCart.campaignDiscount = prodFromCampaign.campaignDiscount;
				prodInCart.quantityDiscount = prodFromCampaign.quantityDiscount;
				prodInCart.estimatedPrice =
					prodInCart.offerPrice -
					prodFromCampaign.quantityDiscount * (prodInCart.offerPrice / 100) -
					(prodFromCampaign.campaignDiscount / 100) *
						(prodInCart.offerPrice -
							prodFromCampaign.quantityDiscount *
								(prodInCart.offerPrice / 100));
			}
		});

		setProducts(_products);
	}, 300);

	const handleTableDelete = debounce(async (index) => {
		const _products = products.filter((p, idx) => idx !== index);
		const _delete = products.filter((p, idx) => idx === index);

		setProducts(_products);

		await delete_cart_item({ productId: _delete[0].id });
	}, 300);

	const fetch_template_to_cart = async (templateId) => {
		await add_template_to_cart(templateId);
		setSelectedPointsOfSale(null);
		setProducts(null);
		setDistributorsCheck(false);
		setFlag(!flag);
	};

	const onFinish = async (values) => {
		try {
			if (products.length === 0 || !products) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"No hay productos en el carro de compras"
				);
				return;
			}

			if (selectedPointsOfSale === null) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe seleccionar un Punto de Entrega"
				);
				return;
			}

			if (selector === null) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe seleccionar una droguería"
				);
				return;
			}

			if (
				products.find(
					(d) => d.distributorId === null || d.distributorId === undefined
				)
			) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Por favor quite los productos que no poseen droguería"
				);
				return;
			}

			setSubmitting(true);

			onSubmitSuccess();
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error con el carro de compras",
				"Un error ocurrio al intentar avanzar con el carro de compras, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	if (!products) return <LoadingSkeleton />;

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = async () => {
		setIsModalVisible(false);
		fetch_template_to_cart(selectTemplate);
	};

	const handleCancel = () => {
		setTemplateName(null);
		setIsModalVisible(false);
	};

	const handlePointOfSaleSelect = debounce(async (value, object) => {
		setFlag(!flag);
		setSelectedPointsOfSale(value);
		setCheckoutDetail((c) => ({
			...c,
			pointOfSale: object?.children,
		}));
	}, 300);

	// ********************************************** //

	const handleDistributorTableChange = debounce(async (_p, record, index) => {
		let recordValue = record.value.split("@");
		let _campaignsProducts = [];
		let _products = products.map((p) =>
			p.productId === recordValue[1]
				? {
						...p,
						distributorId: recordValue[0],
				  }
				: { ...p }
		);

		const _selectedProduct = _products.find(
			(p) => p.productId === recordValue[1]
		);

		_campaignsProducts = await update_cart_item({
			product: {
				id: _selectedProduct.id,
				quantity: _selectedProduct.quantity,
				distributorId: recordValue[0],
				pointOfSaleCart: selectedPointsOfSale,
			},
		});

		_products.forEach((prodInCart) => {
			let prodFromCampaign = _campaignsProducts?.campaignsProducts?.find(
				(prodInCampaign) => prodInCampaign.productId === prodInCart.id
			);
			if (prodFromCampaign) {
				prodInCart.campaignTitle = prodFromCampaign.campaignTitle;
				prodInCart.campaignDiscount = prodFromCampaign.campaignDiscount;
				prodInCart.quantityDiscount = prodFromCampaign.quantityDiscount;
				prodInCart.estimatedPrice =
					prodInCart.offerPrice -
					prodFromCampaign.quantityDiscount * (prodInCart.offerPrice / 100) -
					(prodFromCampaign.campaignDiscount / 100) *
						(prodInCart.offerPrice -
							prodFromCampaign.quantityDiscount *
								(prodInCart.offerPrice / 100));
			}
		});

		setProducts(_products);
	}, 300);

	const handleDistributorSelect = debounce(async (value, object) => {
		setSelector(value);
		_products = products.map((p) => ({
			...p,
			distributorId: p.distributors.find((d) => d.id === value)?.id,
			distributorName: p.distributors.find((d) => d.id === value)?.name,
			campaignDiscount: 0,
			estimatedPrice:
				p.quantity >= p.triggerQty
					? p.offerPrice - p.offerPrice * (p.triggerDiscount / 100)
					: p.offerPrice,
		}));

		const _campaignsProducts = await update_cart_options({
			pointOfSaleCart: selectedPointsOfSale,
			distributorCart: value,
		});

		_products.forEach((prodInCart) => {
			let prodFromCampaign = _campaignsProducts?.campaignsProducts?.find(
				(prodInCampaign) => prodInCampaign.productId === prodInCart.id
			);
			if (prodFromCampaign) {
				prodInCart.campaignTitle = prodFromCampaign.campaignTitle;
				prodInCart.campaignDiscount = prodFromCampaign.campaignDiscount;
				// prodInCart.estimatedPrice =
				// 	prodInCart.offerPrice -
				// 	prodInCart.offerPrice * (prodFromCampaign.campaignDiscount / 100);
				prodInCart.estimatedPrice =
					prodInCart.offerPrice -
					prodFromCampaign.quantityDiscount * (prodInCart.offerPrice / 100) -
					(prodFromCampaign.campaignDiscount / 100) *
						(prodInCart.offerPrice -
							prodFromCampaign.quantityDiscount *
								(prodInCart.offerPrice / 100));
			}
		});

		setProducts(_products);

		setCheckoutDetail((c) => ({
			...c,
			distributor: object?.children,
		}));
	}, 300);

	const handleDistributorCheck = (value) => {
		setDistributorsCheck(value);
	};

	return (
		<Form
			form={form}
			name="create-cart"
			onFinish={onFinish}
			autoComplete="off"
			requiredMark={false}
			layout="vertical"
			initialValues={initialValues}
		>
			<OptionsSection>
				{!userdata.isOneMarket ? (
					!userdata.isSeller ? (
						<CartTempButton
							type="link"
							color="white"
							bg="Secondary"
							onClick={showModal}
						>
							<AddTemplateIconContainer>
								<Image
									src={AddTempIcon}
									alt="Menu"
									style={{
										height: "100%",
										width: "80%",
									}}
								/>
								<AddTemplateIconParagraph>
									Agregar productos desde plantilla
								</AddTemplateIconParagraph>
							</AddTemplateIconContainer>
						</CartTempButton>
					) : (
						<WhiteSpace></WhiteSpace>
					)
				) : (
					<WhiteSpace></WhiteSpace>
				)}
				<Modal
					title={
						<Typo type="primary" level={6}>
							Plantillas de Carrito
						</Typo>
					}
					open={isModalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					<ModalTemplates
						initialModalValues={initialModalValues}
						setInitialModalValues={setInitialModalValues}
						selectTemplate={selectTemplate}
						setSelectTemplate={setSelectTemplate}
						templateList={templateList}
						templateName={templateName}
						setTemplateName={setTemplateName}
					/>
				</Modal>
				<PointOfSaleSelect
					allowClear={false}
					placeholder={
						<Typo type="danger" level={9}>
							Seleccione punto de entrega
						</Typo>
					}
					value={selectedPointsOfSale}
					onChange={handlePointOfSaleSelect}
					style={{ width: "260px" }}
				>
					{pointOfSale?.map((item) => (
						<PointOfSaleSelect.Option key={item.key} value={item.id}>
							{`Suc: ${item.name}`}
						</PointOfSaleSelect.Option>
					))}
				</PointOfSaleSelect>
				<DistributorSelect
					allowClear={false}
					onChange={handleDistributorSelect}
					value={selector}
					placeholder={
						<Typo type="danger" level={9}>
							Seleccione droguería
						</Typo>
					}
					style={{ width: "260px" }}
					disabled={selectedPointsOfSale === null ? true : false}
				>
					{pointOfSaleDistributors?.map((item) => (
						<DistributorSelect.Option key={item.key} value={item.id}>
							{item.name}
						</DistributorSelect.Option>
					))}
				</DistributorSelect>
				{selector &&
					pointOfSaleDistributors?.length > 1 &&
					products.length > 1 && (
						<Checkbox
							checked={distributorsCheck}
							onChange={(e) => handleDistributorCheck(e.target.checked)}
							style={{ width: 195 }}
						>
							Multiples droguerias
						</Checkbox>
					)}
			</OptionsSection>
			{/* <Divider /> */}
			<Inner>
				<ProductsSection>
					<ProductsTable data={products} columns={columns} />
				</ProductsSection>
				<InfoSection>
					<Container>
						<InfoSubSection>
							<InfoTitleBuy>DETALLE TRANSFER:</InfoTitleBuy>
						</InfoSubSection>
						<Divider />
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0 40px 0 40px",
							}}
						>
							<InfoTitleProductQuantity>
								Cantidad de productos:
							</InfoTitleProductQuantity>
							<InfoNumber>{totals.totalItems}</InfoNumber>
						</InfoSubSection>
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "20px 40px 0px 40px",
							}}
						>
							<InfoTitleProductQuantityItems>
								Cantidad de unidades:
							</InfoTitleProductQuantityItems>
							<InfoNumberItems>{totals.totalProducts}</InfoNumberItems>
						</InfoSubSection>
						<Divider />
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0px 40px 30px 40px",
							}}
						>
							<InfoSubTitle>SUBTOTAL:</InfoSubTitle>
							<InfoSubTitle>
								$ {numberWithDotAndCommas(totals.subtotal.toFixed(2))}
							</InfoSubTitle>
						</InfoSubSection>
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0 40px 0 40px",
							}}
						>
							<InfoTitle>AHORRO:</InfoTitle>
							<InfoTitle>$ {numberWithDotAndCommas(totals.saved)}</InfoTitle>
						</InfoSubSection>
						<Divider />
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0px 40px 30px 40px",
							}}
						>
							<InfoTitleTotal>TOTAL:</InfoTitleTotal>
							<InfoTitleTotal>
								$ {numberWithDotAndCommas(totals.total)}
							</InfoTitleTotal>
						</InfoSubSection>
						<InfoIva translate="no">IVA no incluido</InfoIva>
						<InfoIva translate="no">
							Los precios PVP, PSD y PSL son de referencia y pueden variar
							dependiendo de la droguería seleccionada.
						</InfoIva>
						<Divider />
						<InfoSubSection>
							<Form.Item>
								<CartAntdButton
									type="Primary"
									color="white"
									bg="Primary"
									htmlType="submit"
									loading={submitting}
									style={{ width: "150px" }}
								>
									Continuar
								</CartAntdButton>
							</Form.Item>
						</InfoSubSection>
					</Container>
				</InfoSection>
			</Inner>
			{sliderData?.mejoresOfertas?.length > 4 ? (
				<Section>
					<SectionTitle>
						<Typo level={4} type="primary">
							Volvé a comprar
						</Typo>
					</SectionTitle>
					<SliderProduct
						slidesPerView={6}
						slidesPerGroup={6}
						delay={false}
						navigation={true}
					>
						{sliderData?.mejoresOfertas &&
							sliderData?.mejoresOfertas?.map((d, idx) => (
								<SwiperSlide key={`offersInCart_slider_${idx}`}>
									<OfferSlideContainer
										id={d.productId}
										title={d.title}
										image={d.photoUrl}
										price={d.price}
										offerPrice={d.offerPrice}
										offerTo={d.offerTo}
										discount={d.discount}
										labImage={d.clientPhotoUrl}
										qtyInCart={d.productQtyInCart}
										clientName={d.clientName}
										productPresentation={d.presentationName}
										productQty={d.presentationQty}
										productUnit={d.presentationUnit}
										marketing={d.marketingId}
										clientTypeOfSale={d.clientTypeOfSale}
										triggerQty={d.triggerQty}
										triggerDiscount={d.triggerDiscount}
										minQuantity={d.minQuantity}
										setFlag={setFlag}
										setSelectedPointsOfSale={setSelectedPointsOfSale}
										setProducts={setProducts}
										setSliderData={setSliderData}
									/>
								</SwiperSlide>
							))}
					</SliderProduct>
				</Section>
			) : null}
		</Form>
	);
}
