import React, { useState } from "react";
import { Dropdown, Menu, Modal } from "antd";
import { Link } from "react-router-dom";
import LoadingSkeleton from "../loading-sekeleton/LoadingSkeleton";
import { COLORS, Typo } from "../../../../../../layout/theme";
import { ActionButton, AntdTable, Container } from "./styles";
import { BsThreeDotsVertical } from "react-icons/bs";
import { numberWithDotAndCommas } from "../../../../../../../utils/numberWithDotAndComas";
import { MdComputer } from "react-icons/md";
import { BiMobile } from "react-icons/bi";
import moment from "moment";
import { FiPhoneCall } from "react-icons/fi";
import { FaHeadset } from "react-icons/fa";
import { AiOutlineAudit } from "react-icons/ai";

export default function MySalesTable({ data, ...props }) {
	const [key, setKey] = useState(null);

	//const [modalInfo, setModalInfo] = useState(null);

	//const [isModalOpen, setIsModalOpen] = useState(false);
	// const showModal = (r) => {
	// 	setIsModalOpen(true);
	// 	setModalInfo(r);
	// };
	// const handleOk = () => {
	// 	setIsModalOpen(false);
	// };
	// const handleCancel = () => {
	// 	setIsModalOpen(false);
	// };

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/ventas/${key}`}>
							<Typo type="secondary">Ver detalle</Typo>
						</Link>
					),
				},
			]}
		/>
	);

	const columns = [
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						N° TRF
					</div>
				);
			},
			dataIndex: "orderNumId",
			render: (f, r) => (
				<Container onMouseOver={() => setKey(r?.id)}>
					<Link to={`/admin/ventas/${key}`}>
						<Typo type="terciary" fontWeight={600} fontSize={14}>
							{r.orderNumMobile > 0 ? r.orderNumMobile : f}
						</Typo>
					</Link>
				</Container>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Origen
					</div>
				);
			},
			dataIndex: "typeOfSale",
			render: (f, r) => (
				<>
					<Typo
						type="muted"
						fontWeight={600}
						fontSize={14}
						//onClick={() => showModal(r)}
					>
						{f === "WEB" ? (
							<MdComputer />
						) : f === "WEBSELLER" ? (
							<FaHeadset />
						) : f === "EXPO" ? (
							<AiOutlineAudit />
						) : f === "MOBILE" ? (
							<>
								<BiMobile /> App
							</>
						) : (
							<FiPhoneCall />
						)}
					</Typo>
				</>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Usuario
					</div>
				);
			},
			dataIndex: "sellerFirstName",
			render: (f, r) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f} {r.sellerLastName}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Fecha
					</div>
				);
			},
			dataIndex: "created",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{moment(f).format("DD/MM/YYYY")}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Farmacia
					</div>
				);
			},
			dataIndex: "pointOfSaleName",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Dirección
					</div>
				);
			},
			dataIndex: "pointOfSaleAddress",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Provincia
					</div>
				);
			},
			dataIndex: "provinceName",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Items
					</div>
				);
			},
			dataIndex: "qtyItems",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Un.
					</div>
				);
			},
			dataIndex: "qtyUnits",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Droguería
					</div>
				);
			},
			dataIndex: "distributorName",
			render: (f, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Total
					</div>
				);
			},
			dataIndex: "total",
			width: 130,
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					$ {numberWithDotAndCommas(f)}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Estado
					</div>
				);
			},
			dataIndex: "orderState",
			render: (fila, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila === "Pending" && "Pendiente"}
					{fila === "Active" && "Activa"}
					{fila === "Sended" && "Enviada a Droguería"}
					{fila === "Canceled" && "Cancelada"}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			render: (f, r, i) => (
				<>
					<Dropdown overlay={menu} placement="bottomRight">
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	if (!data) return <LoadingSkeleton />;

	return (
		<>
			<AntdTable columns={columns} dataSource={data} {...props} />
			{/* {modalInfo && (
				<Modal
					title={
						<Typo type="primary" level={4}>
							Usuario de la compra
						</Typo>
					}
					visible={isModalOpen}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					<p>
						Nombre: {modalInfo.user.firstName + " " + modalInfo.user.lastName}
					</p>
					<p>Mail: {modalInfo.user.email}</p>
					<p>
						Usuario de:{" "}
						{modalInfo.userFrom === "web"
							? modalInfo.user.client.name + " - Usuario Web"
							: modalInfo.userFrom === "mobile"
							? modalInfo.user.client.name + " - Usuario Mobile"
							: "Usuario One Market"}
					</p>
				</Modal>
			)} */}
		</>
	);
}
