import React, { useEffect, useMemo, useState } from "react";
import {
	AntdButton,
	Container,
	Inner,
	OptionSection,
	SearchContainer,
	Section,
	TitleContainer,
	UploadFile,
	UploadIconContainer,
} from "./styles";
import ProductTable from "../product-table/ProductTable";
import debounce from "lodash/debounce";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import { COLORS, Typo } from "../../../../../layout/theme";
import { Modal, Spin } from "antd";
import {
	CloudUploadOutlined,
	InfoCircleFilled,
	InfoCircleOutlined,
	WarningOutlined,
} from "@ant-design/icons";
import Image from "../../../../../common/image/Image";
import { CONSTANTS } from "../../../../../../utils/constants";
import { import_order_items_quantities } from "../../../../../../api/endpoints/products";
import ErrorTable from "./error-table/ErrorTable";

export default function AddProducts({
	templateProducts,
	setTemplateProducts,
	laboratoryId,
}) {
	const [loading, setLoading] = useState(false);
	const [searchByStore, setSearchByStore] = useState(null);
	const [listedProducts, setListedProducts] = useState(templateProducts);
	const [showUploadFileModal, setShowUploadFileModal] = useState(false);
	const [showErrorsModal, setShowErrorsModal] = useState(false);
	const [errorList, setErrorList] = useState([]);
	const [successList, setSuccessList] = useState([]);

	function updateQuantities(baseArray, updatesArray) {
		const updatesMap = new Map(
			updatesArray.map((item) => [item.id, item.quantity])
		);

		const updatedProducts = baseArray.map((item) => {
			if (updatesMap.has(item.id)) {
				return { ...item, quantity: updatesMap.get(item.id) };
			}
			return item;
		});

		setTemplateProducts(updatedProducts);
	}

	function updateQuantitiesFromImport(baseArray, updatesArray) {
		const updatesMap = new Map(
			updatesArray.map((item) => [item.id, item.quantity])
		);

		const updatedProducts = baseArray.map((item) => {
			if (updatesMap.has(item.id)) {
				return { ...item, quantity: updatesMap.get(item.id), hasChanges: true };
			}
			return item;
		});

		setTemplateProducts(updatedProducts);
		setListedProducts(updatedProducts);
	}

	const handleTableChange = (values) => {
		const updatedProducts = listedProducts.map((product, index) =>
			product.id === values.id
				? { ...product, quantity: parseInt(values.value), hasChanges: true }
				: product
		);

		updateQuantities(templateProducts, updatedProducts);
		setListedProducts(updatedProducts);
	};

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		const searchValue = e.target.value.toLowerCase();
		setSearchByStore(searchValue);
		if (!searchValue) {
			updateQuantities(templateProducts, listedProducts);
			setListedProducts(templateProducts);
		} else {
			const filteredProducts = templateProducts.filter(
				(product) =>
					product.brandName.toLowerCase().includes(searchValue) ||
					product.title.toLowerCase().includes(searchValue) ||
					product.ean13.includes(searchValue)
			);
			setListedProducts(filteredProducts);
		}
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 600),
		[templateProducts, listedProducts]
	);

	const handleImportChange = (result) => {
		const updatesArray = result.products;
		const errors = result.errors;
		setErrorList(errors);
		setSuccessList(updatesArray);
		if (errors.length > 0) setShowErrorsModal(true);
		updateQuantitiesFromImport(listedProducts, updatesArray);
	};

	const handleUploadFile = async (e) => {
		let res;
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("file", e.fileList[e.fileList.length - 1].originFileObj);
			formData.append(
				"data",
				JSON.stringify({
					clientId: laboratoryId,
				})
			);
			res = await import_order_items_quantities(formData);
		} catch (err) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error en el archivo",
				"Verifique el formato enviado"
			);
		} finally {
			setLoading(false);
			setShowUploadFileModal(false);
			res && handleImportChange(res);
		}
	};

	const handleUploadModalOk = () => {
		setShowUploadFileModal(false);
	};

	const handleUploadModalCancel = () => {
		setShowUploadFileModal(false);
	};

	const handleShowUploadModal = () => {
		setSearchByStore(null);
		updateQuantities(templateProducts, listedProducts);
		setListedProducts(templateProducts);
		setShowUploadFileModal(true);
	};

	const handleErrorsModalOk = () => {
		setShowErrorsModal(false);
	};

	const handleErrorsModalCancel = () => {
		setShowErrorsModal(false);
	};

	return (
		<Inner>
			<OptionSection>
				<UploadIconContainer>
					<AntdButton
						color={COLORS.White}
						backgroundColor={COLORS.Success}
						borderColor={COLORS.StrongGreen}
						hoverColor={COLORS.Success}
						onClick={handleShowUploadModal}
					>
						<CloudUploadOutlined /> Importar pedido
					</AntdButton>
				</UploadIconContainer>
				<SearchContainer>
					<AntdSearch
						// allowClear
						placeholder="Buscar productos"
						width={459}
						onChange={(e) => {
							setSearchByStore(e.target.value);
							debouncedEventHandler(e);
						}}
						value={searchByStore}
						// onPressEnter={(e) => debouncedEventHandler(e)}
						disabled={loading}
					/>
				</SearchContainer>
				<Modal
					title={
						<TitleContainer>
							<Typo
								type="primary"
								level={6}
								style={{ cursor: "pointer", userSelect: "none" }}
							>
								Importar pedido <InfoCircleFilled />
							</Typo>
							<Typo level={7}>Información de archivo de subida</Typo>
							<Typo level={7}>Extensiones admitidas: .csv, .txt, .res</Typo>
							<Typo level={7}>
								Estructura de archivo: La primer columna debe ser el{" "}
								<b>Ean del producto</b> (código de barra), la segunda columna la{" "}
								<b>cantidad</b>
							</Typo>
						</TitleContainer>
					}
					open={showUploadFileModal}
					onOk={handleUploadModalOk}
					onCancel={handleUploadModalCancel}
					centered={false}
					footer={null}
					destroyOnClose={true}
				>
					<UploadFile
						multiple={false}
						showUploadList={false}
						beforeUpload={() => false}
						action={null}
						onChange={(e) => handleUploadFile(e)}
						disabled={loading}
					>
						{loading ? (
							<Spin size="default" />
						) : (
							<Section>
								<Image src={CONSTANTS.URL_SHEET_ICON} width="80px" />
								<Typo level={7}>
									Haga click para elegir el archivo desde el explorador
								</Typo>
								<Typo level={7}>
									También puede arrastrarlo y soltarlo en este espacio
								</Typo>
							</Section>
						)}
					</UploadFile>
				</Modal>
			</OptionSection>
			<Modal
				title={
					<TitleContainer>
						<Typo type="success" level={5}>
							{successList?.length} productos cargados correctamente{" "}
							<InfoCircleOutlined style={{ color: COLORS.Success }} />
						</Typo>
						<Typo type="danger" level={5}>
							{errorList?.length} productos con errores en importación{" "}
							<WarningOutlined style={{ color: COLORS.Danger }} />
						</Typo>
					</TitleContainer>
				}
				open={showErrorsModal}
				onOk={handleErrorsModalOk}
				onCancel={handleErrorsModalCancel}
				width={900}
				centered={false}
				footer={null}
				destroyOnClose={true}
			>
				<Container>
					<ErrorTable data={errorList} />
				</Container>
			</Modal>
			<Container>
				<ProductTable data={listedProducts} onChange={handleTableChange} />
			</Container>
		</Inner>
	);
}
