import React, { useState } from "react";
import { Input, Button, Form, Row, Col, message } from "antd";
import { validate_coupon_code } from "../../../api/endpoints/coupon";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../utils/notificationToast";
import { ValidatorAntdButton } from "./styles";

const CodeValidator = ({
	form,
	products,
	setProducts,
	code,
	setCode,
	coupon,
	setCoupon,
}) => {
	const [isValid, setIsValid] = useState(null);
	const [loading, setLoading] = useState(false);

	function updateCartProducts(baseArray, updatesArray) {
		const updatesMap = new Map(
			updatesArray.map((item) => [
				item.productId,
				{
					estimatedPrice: item.estimatedPrice,
					couponDiscount: item.couponDiscount,
				},
			])
		);

		const updatedProducts = baseArray.map((item) => {
			if (updatesMap.has(item.productId)) {
				return {
					...item,
					estimatedPrice: updatesMap.get(item.productId).estimatedPrice,
					couponDiscount: updatesMap.get(item.productId).couponDiscount,
				};
			}
			return item;
		});

		setProducts(updatedProducts);
	}

	const handleValidateCode = async () => {
		if (code.length > 30) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error con código",
				"El código no debe superar los 30 caracteres"
			);
			return;
		}

		const specialCharPattern = /[^a-zA-Z0-9]/;
		if (specialCharPattern.test(code)) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error con código",
				"El código contiene caracteres no permitidos"
			);
			return;
		}

		setLoading(true);
		try {
			const postData = {
				couponId: coupon?.id ?? null,
				code: code,
				products: products.map((p) => ({
					productId: p.productId,
					quantity: p.quantity,
					estimatedPrice: p.estimatedPrice,
					couponDiscount: 0,
					distributorId: p.distributorId,
				})),
			};
			const response = await validate_coupon_code(postData);
			if (response.isValid) {
				setIsValid(true);
				setCoupon(response.coupon);
				updateCartProducts(products, response.products);
				openNotificationWithIcon(
					TYPE.SUCCESS,
					"Código validado con éxito",
					response.message
				);
			} else {
				setIsValid(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Error al validar el código",
					response.message
				);
			}
		} catch (error) {
			setIsValid(false);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al validar el código",
				"Un error ocurrio al intentar aplicar el cupón, contacte con soporte a soporte@checkpos.com."
			);
		}
		setLoading(false);
	};

	return (
		<Row gutter={10}>
			<Col span={16}>
				<Form.Item
					validateStatus={
						isValid === false ? "error" : isValid === true ? "success" : ""
					}
					hasFeedback
				>
					<Input
						value={code}
						onChange={(e) => setCode(e.target.value)}
						placeholder="Ingrese el cupón"
						disabled={isValid}
					/>
				</Form.Item>
			</Col>
			<Col span={8}>
				<ValidatorAntdButton
					type="primary"
					onClick={handleValidateCode}
					loading={loading}
					disabled={isValid}
				>
					Validar cupón
				</ValidatorAntdButton>
			</Col>
		</Row>
	);
};

export default CodeValidator;
