import React, { useState, useEffect, useMemo } from "react";
import { Space, Dropdown, Menu, Modal, Button, Spin } from "antd";
import {
	CloseCircleOutlined,
	InfoCircleOutlined,
	LeftOutlined,
	LoadingOutlined,
	PlusOutlined,
	RightCircleOutlined,
	RightOutlined,
} from "@ant-design/icons";
import {
	ActionButton,
	AntdButton,
	AntdTable,
	ButtonsContainer,
	CompaginationDiv,
	ConfirmButton,
	Filters,
	Inner,
	ModalContainer,
	RadioOptions,
	SpinnerContainer,
	TableContainer,
	UploadFile,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import LoadingHeader from "./components/LoadingHeader";
import { COLORS, Typo } from "../../../../layout/theme";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import {
	add_user_route_by_cuit,
	delete_user_route,
	export_routes_to_assign,
	get_routes,
	get_routes_by_user_id,
	get_routes_by_user_id_header_info,
	update_routes_by_user_id,
} from "../../../../../api/endpoints/routes";
import { useAuth } from "../../../../../contexts/authContext";
import PrePosTable from "./components/pre-pos-table/PrePosTable";
import moment from "moment";

const { confirm } = Modal;
let productIndex = null;

const antIcon = (
	<LoadingOutlined
		style={{
			fontSize: 24,
		}}
		spin
	/>
);

export default function UserRouteList({
	clientId,
	client,
	userId,
	user,
	userType,
}) {
	const { userdata } = useAuth();
	const [loading, setLoading] = useState(false);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [clientUserPos, setClientUserPos] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [key, setKey] = useState("null");
	const [page, setPage] = useState(1);
	const [hasChanges, setHasChanges] = useState(false);
	const [data, setData] = useState(null);
	const [searchPrePos, setSearchPrePos] = useState(null);
	const [searchModalShow, setSearchModalShow] = useState(false);
	const [toogleAdd, setToogleAdd] = useState(false);
	const [pageAdd, setPageAdd] = useState(1);
	const [errors, setErrors] = useState(null);
	const [importResponse, setImportResponse] = useState(null);

	useEffect(() => {
		if (productIndex) {
			setData(data?.filter((p, idx) => p.id !== productIndex[0]?.id));
		}
	}, [productIndex]);

	const prePosColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			key: "name",
			render: (t, item) => (
				<Typo
					type="primary"
					lineHeight={1}
					texto="medium"
					fontSize={t.length < 15 ? 18 : 16}
				>
					{t}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			width: 100,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(index);
						}}
					>
						<RightCircleOutlined />
					</Button>
				</Space>
			),
		},
	];

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			render: (value, record) => (
				<Typo
					type={record.hasChanges ? "white" : "muted"}
					fontWeight={600}
					fontSize={14}
					onMouseOver={() => setKey(record)}
				>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cuit</div>,
			dataIndex: "cuit",
			render: (value, record) => (
				<Typo
					type={record.hasChanges ? "white" : "muted"}
					fontWeight={600}
					fontSize={14}
				>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Dirección</div>,
			dataIndex: "address",
			render: (value, record) => (
				<Typo
					type={record.hasChanges ? "white" : "muted"}
					fontWeight={600}
					fontSize={14}
				>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Provincia</div>,
			dataIndex: "province",
			render: (value, record) => (
				<Typo
					type={record.hasChanges ? "white" : "muted"}
					fontWeight={600}
					fontSize={14}
				>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Localidad</div>,
			dataIndex: "location",
			render: (value, record) => (
				<Typo
					type={record.hasChanges ? "white" : "muted"}
					fontWeight={600}
					fontSize={14}
				>
					{value}
				</Typo>
			),
		},
		productIndex || importResponse
			? {
					title: "",
					key: "action",
					width: 30,
					render: (_, record, index) => (
						<Space size="middle">
							{record.hasChanges && (
								<CloseCircleOutlined onClick={() => handleDelete(index)} />
							)}
						</Space>
					),
			  }
			: {
					title: "",
					key: "id",
					dataIndex: "id",
					width: 30,
					render: (f, r, i) => (
						<>
							<Dropdown overlay={menuActive} placement="bottomRight">
								<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
									<BsThreeDotsVertical />
								</ActionButton>
							</Dropdown>
						</>
					),
			  },
	];

	const menuActive = (
		<Menu
			items={[
				{
					key: "2",
					label: <Typo onClick={() => deleteRoute(key?.id)}>Eliminar</Typo>,
				},
			]}
		/>
	);

	const fetch_routes = async (search, state, userId) => {
		setLoading(true);
		try {
			const res = await get_routes_by_user_id(search, userId, state);
			setClientUserPos(
				res.map((r) => ({
					...r,
					key: r.id,
					name: r.preDefinedPointsOfSale?.name,
					cuit: r.preDefinedPointsOfSale?.cuit,
					address: r.preDefinedPointsOfSale?.address,
					province: r.preDefinedPointsOfSale?.province,
					location: r.preDefinedPointsOfSale?.location,
					hasChanges: false,
				}))
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const fetch_routes_header = async () => {
		const res = await get_routes_by_user_id_header_info(userId);
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_routes("", state, userId);
		fetch_routes_header();
		setLoading(false);
	}, [state, userId]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_routes(e.target.value, state, userId);
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 600),
		[userId]
	);

	const deleteRoute = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar esta ruta?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La ruta se quitará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "DELETED");
			},
		});
	};

	const handleAction = async (key, id) => {
		try {
			const _data = { id: key, userId: id };
			await delete_user_route(_data);
			fetch_routes("", state, userId);
			fetch_routes_header();
		} catch (err) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"La ruta no se puede borrar",
				"La ruta no se puede borrar"
			);
		}
	};

	const handleSubmit = () => {
		confirm({
			title: "¿Seguro que quiere publicar estas rutas?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "Los cambios se aplicaran de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				confirmChanges();
			},
		});
	};

	const confirmChanges = async () => {
		try {
			const res = await update_routes_by_user_id({
				userRoutes: clientUserPos,
				userId: userId,
			});
			openNotificationWithIcon(
				TYPE.SUCCESS,
				res,
				"Las rutas se actualizaron correctamente"
			);
			fetch_routes("", state, userId);
			fetch_routes_header();
			productIndex = null;
			setImportResponse(null);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Las rutas no se pudieron actualizar correctamente, contacte con soporte a soporte@checkpos.com",
				"Las rutas se se pudieron actualizar correctamente, contacte con soporte a soporte@checkpos.com"
			);
		}
	};

	const handleUpload = async (e) => {
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append(
				"data",
				JSON.stringify({ userId: userId ?? userdata.id })
			);
			formData.append("file", e.fileList[e.fileList.length - 1].originFileObj);
			const res = await add_user_route_by_cuit(formData);
			setImportResponse(res);
			handleAddToList(res.newUserPos);
			setErrors(res?.errors);
			if (res?.errors?.length > 0) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Los productos que intenta subir no se cargaron",
					"Los productos que intenta subir tienen problemas, reviselos y subalos de nuevo"
				);
			}
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Las rutas no pudieron actualizarse",
				"Verifique su archivo de excel, si sigue con problemas contacte con soporte a soporte@checkpos.com"
			);
		} finally {
			setLoading(false);
		}
	};

	const handleAddToList = (posList) => {
		const auxTemplate = clientUserPos.concat(posList);

		let uniqueArray = auxTemplate.filter((value, index) => {
			const _value = JSON.stringify(value);
			return (
				index ===
				auxTemplate.findIndex((obj) => {
					return JSON.stringify(obj) === _value;
				})
			);
		});

		uniqueArray = uniqueArray.filter(
			(value, index, self) =>
				index ===
				self.findIndex(
					(t) => t.preDefinedPointsOfSaleId === value.preDefinedPointsOfSaleId
				)
		);

		setClientUserPos(uniqueArray);
	};

	const fetch_predefined_pos = async (search, skipValue) => {
		setLoading(true);
		try {
			const res = await get_routes(search, clientId, "ACTIVE");
			setData(
				res.map((r) => ({
					...r,
					name: r.preDefinedPointsOfSale?.name,
					cuit: r.preDefinedPointsOfSale?.cuit,
					address: r.preDefinedPointsOfSale?.address,
					province: r.preDefinedPointsOfSale?.province,
					location: r.preDefinedPointsOfSale?.location,
					quantity: 1,
					key: r.id,
					hasChanges: true,
					preDefinedPointsOfSaleId: r.preDefinedPointsOfSale?.id,
				}))
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setSearchModalShow(true);
		}
	};

	//////******************************************************** */

	const handleAddToTemplate = async (e, q) => {
		const _template = data?.filter((p, idx) => idx === e);
		productIndex = _template;
		const auxTemplate = clientUserPos.concat(_template);

		let uniqueArray = auxTemplate.filter((value, index) => {
			const _value = JSON.stringify(value);
			return (
				index ===
				auxTemplate.findIndex((obj) => {
					return JSON.stringify(obj) === _value;
				})
			);
		});

		uniqueArray = uniqueArray.filter(
			(value, index, self) =>
				index ===
				self.findIndex(
					(t) => t.preDefinedPointsOfSaleId === value.preDefinedPointsOfSaleId
				)
		);

		setClientUserPos(uniqueArray);
	};

	//////******************************************************** */

	const handleDelete = (index) => {
		const _template = clientUserPos.filter((p, idx) => idx !== index);
		setClientUserPos(_template);
	};

	//////******************************************************** */

	const eventHandlerAdd = (e) => {
		setSearchPrePos(e.target.value);
		fetch_predefined_pos(e.target.value, 0);
	};

	const debouncedEventHandlerAdd = useMemo(
		() => debounce(eventHandlerAdd, 600),
		[]
	);

	const handleModalOk = () => {
		setSearchPrePos(null);
		setSearchModalShow(false);
	};

	const handleModalCancel = () => {
		setSearchPrePos(null);
		setSearchModalShow(false);
	};

	const handleCancel = () => {
		productIndex = null;
		setImportResponse(null);
		fetch_routes("", state, userId);
		fetch_routes_header();
	};

	const fetch_export_routes_to_assign = async () => {
		setLoading(true);
		try {
			const res = await export_routes_to_assign(clientId);
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const fileName =
				"PuntosDeVenta_" +
				client.name +
				"_" +
				moment().format("DD-MM-YYYY") +
				".xlsx";
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Inner>
			<Filters>
				{!headerInfo ? (
					<LoadingHeader />
				) : (
					<Space
						size="large"
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<div style={{ display: "flex", gap: 20, alignItems: "center" }}>
							<AntdSearch
								width={400}
								allowClear
								placeholder="Buscar asignados"
								onChange={debouncedEventHandler}
							/>
							<div>
								{!toogleAdd && (
									<DropShadow type="drop">
										<SignInTemplate.AntdButton
											type="primary"
											bg="Primary"
											color="White"
											onClick={() => setToogleAdd((prev) => !prev)}
											icon={<PlusOutlined />}
											style={{ width: "200px", fontWeight: "400" }}
											disabled={!!importResponse || !!productIndex}
										>
											Agregar PDV
										</SignInTemplate.AntdButton>
									</DropShadow>
								)}
								{toogleAdd && (
									<div style={{ display: "flex", gap: 10 }}>
										<AntdSearch
											allowClear
											placeholder="Buscar punto de venta general"
											width={300}
											onChange={(e) => setSearchPrePos(e.target.value)}
											value={searchPrePos}
											onPressEnter={(e) => debouncedEventHandlerAdd(e)}
											disabled={loading}
										/>
										<CloseCircleOutlined
											size="small"
											style={{ color: COLORS.Danger }}
											onClick={() => setToogleAdd((prev) => !prev)}
										/>
									</div>
								)}
							</div>
						</div>
						<ButtonsContainer>
							<AntdButton
								onClick={fetch_export_routes_to_assign}
								loading={loading}
							>
								Exportar PDV sin asignar
							</AntdButton>
							{loading ? (
								<SpinnerContainer>
									<Spin indicator={antIcon} size="small" style={{ scale: 2 }} />
								</SpinnerContainer>
							) : (
								<UploadFile
									multiple={false}
									showUploadList={false}
									beforeUpload={() => false}
									action={null}
									onChange={handleUpload}
									disabled={!!importResponse || !!productIndex}
								>
									Importar Cuits
								</UploadFile>
							)}
						</ButtonsContainer>
					</Space>
				)}
			</Filters>
			<RadioOptions>
				<Typo type="primary" level={6}>
					PDV activos en ruta: {headerInfo?.totalActive}
				</Typo>
			</RadioOptions>
			{clientId && (hasChanges || productIndex || importResponse) && (
				<ConfirmButton clientId={clientId}>
					<AntdButton onClick={handleSubmit} success>
						Confirmar cambios
					</AntdButton>{" "}
					<AntdButton onClick={handleCancel}>Cancelar cambios</AntdButton>
				</ConfirmButton>
			)}
			<TableContainer>
				<AntdTable
					dataSource={clientUserPos}
					columns={columns}
					loading={loading}
					pagination={{
						pageSize: 100,
						showSizeChanger: false,
						onChange: (page) => setPage(page),
					}}
					// scroll={{
					// 	y: 450,
					// }}
					rowClassName={(record) => (record.hasChanges ? "hasChanges" : null)}
				/>
			</TableContainer>
			<Modal
				open={searchModalShow}
				onOk={handleModalOk}
				onCancel={handleModalCancel}
				width={900}
				centered={true}
				footer={null}
				destroyOnClose={true}
			>
				<ModalContainer>
					<PrePosTable
						data={data}
						columns={prePosColumns}
						loading={loading}
						pagination={{
							pageSize: 100,
							showSizeChanger: false,
							onChange: (page) => setPageAdd(page),
						}}
						scrollData={{ y: 350 }}
					/>
				</ModalContainer>
			</Modal>
		</Inner>
	);
}
