import React, { useEffect, useState } from "react";
import Image from "../../common/image/Image";
import Layout from "../../layout/base/Layout";
import MarketLogo from "../../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Drawer, Badge, Col, Tooltip, Progress } from "antd";
import { RiBookletFill, RiDashboardLine } from "react-icons/ri";
import { AiFillAlert, AiOutlineLineChart } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { ImInsertTemplate } from "react-icons/im";
import { BiLogOut } from "react-icons/bi";
import {
	MdOutlineBusiness,
	MdOutlineComputer,
	MdOutlinePlace,
} from "react-icons/md";
import { RiUserSettingsLine, RiTvLine } from "react-icons/ri";
import { useAuth } from "../../../contexts/authContext";
import Aside from "../../common/aside/Aside";
import SearchProducts from "../../common/search-products/SearchProducts";
import { useCart } from "../../../contexts/cartContext";
import CartIcon from "../../../assets/images/cart-shopping-solid.svg";
import MenuIcon from "../../../assets/images/bars-solid.svg";
import UserIcon from "../../../assets/images/user-solid.svg";
import {
	AuditOutlined,
	DownloadOutlined,
	FormOutlined,
	PhoneOutlined,
	QuestionCircleOutlined,
	RiseOutlined,
	SettingOutlined,
	SnippetsOutlined,
} from "@ant-design/icons";
import MarketLogoIcon from "../../../assets/images/logo-box.png";
import FooterContainer from "../../containers/layout/FooterContainer";
import { COLORS, SIZES, Typo } from "../../layout/theme";
import { get_client_by_id } from "../../../api/endpoints/clients";
import { useScrollDirection } from "../../../hooks/useScrollDirection";
import { get_total_campaigns_by_region } from "../../../api/endpoints/campaigns";
import { checkOneMarketMenu } from "./components/AsideBarAdminOneMarketData";
import AsideBarSubMenuAdminOneMarket from "./components/AsideBarSubMenuAdminOneMarket";
import PermissionCheckVisibility from "../../common/permission-check-visibility/PermissionCheckVisibility";
import { useBook } from "../../../contexts/bookContext";
import HotSaleIcon from "../../../assets/images/fire-solid.svg";
import moment from "moment";
import { WhatsAppButton } from "../../layout/base/styles";
import { BsWhatsapp } from "react-icons/bs";
import {
	DropdownLink,
	IconContainer,
	LaboratorySidebarLink,
	MenuAlertContainer,
	SidebarLabel,
} from "./components/styles";
import { checkBuyerProgressPercentage } from "../../../utils/checkBuyerProgressPercentage";
import { checkSellerProgressPercentage } from "../../../utils/checkSellerProgressPercentage";
import { checkRolPermission } from "../../../utils/checkRolPermission";
import { useFilter } from "../../../contexts/filterContext";
import MarketHeader from "./components/market-header/MarketHeader";
import PharmacyHeader from "./components/pharmacy-header/PharmacyHeader";
import LaboratoryHeader from "./components/laboratory-header/LaboratoryHeader";

export default function LayoutContainer({ children }) {
	const {
		userdata,
		ready,
		logout,
		requestsQty,
		mobileRequestsQty,
		ordersQty,
		toValidateQty,
		changePhoto,
		setPhotoKey,
		browserInfo,
		bannerRequestQty,
	} = useAuth();
	const { setUserRol } = useFilter();
	const { qty, templateProducts } = useCart();
	const { bookQty } = useBook();
	const [showDrawer, setShowDrawer] = useState(false);
	const [photo, setPhoto] = useState(false);
	const [pharmaName, setPharmaName] = useState(null);
	const [clientSlug, setClientSlug] = useState(null);
	const [clientPhotoUrl, setClientPhotoUrl] = useState(null);
	const [campaignRegion, setCampaignRegion] = useState(null);
	const [percent, setPercent] = useState(0);
	const scrollDirection = useScrollDirection();
	const location = useLocation();
	const navigate = useNavigate();
	const [reportSubNav, setReportSubnav] = useState(false);
	const [mobileSubNav, setMobileSubnav] = useState(false);
	const [mobileActive, setMobileActive] = useState(false);
	const [eventSubNav, setEventSubnav] = useState(false);
	const [laboratoryAccountSubNav, setLaboratoryAccountSubnav] = useState(false);
	const [expoActive, setExpoActive] = useState(false);
	const [discountForUserActive, setDiscountForUserActive] = useState(false);
	const [contractZoneType, setContractZoneType] = useState(null);
	const [webActive, setWebActive] = useState(false);
	const [webSellerSubNav, setWebSellerSubnav] = useState(false);
	const [webSellerActive, setWebSellerActive] = useState(false);
	const [couponSubNav, setCouponSubnav] = useState(false);
	const [couponActive, setCouponActive] = useState(false);

	async function signOut() {
		await logout();
		setShowDrawer(false);
		navigate("/");
		window.location.reload();
	}

	useEffect(() => {
		if (userdata?.isSeller) {
			setPercent(() => checkSellerProgressPercentage(userdata));
		} else {
			setPercent(() => checkBuyerProgressPercentage(userdata));
		}
	}, [userdata]);

	const fetch_photo = async (id) => {
		const res = await get_client_by_id(id);

		const now = moment().milliseconds();

		setPhoto({
			url: res?.userPhoto + `?k=${now}`,
			key: now,
		});
		setPhotoKey(now);
		setPharmaName(res.client.businessName);
		setClientSlug(res.client.slug);
		setClientPhotoUrl(res.client?.photoUrl);
		setMobileActive(res.client?.contract?.activeMobileApp);
		setExpoActive(res.client?.contract?.activeExpoSection);
		setWebSellerActive(res.client?.contract?.activeWebSeller);
		setCouponActive(res.client?.contract?.activeCouponSection);
		setWebActive(res.client?.contract?.activeClientWeb);
		setDiscountForUserActive(res.client?.contract?.activeDiscountsForUsers);
		setContractZoneType(res.client?.contract?.mobileUsersZoneType);
		setUserRol(
			checkRolPermission(userdata, {
				...res.client,
				isOneMarket: userdata.isOneMarket,
			})
		);
	};

	useEffect(() => {
		fetch_photo(userdata.clientId);
	}, [changePhoto]);

	useEffect(() => {
		const fetch_campaign_region = async () => {
			const res = await get_total_campaigns_by_region();
			setCampaignRegion(res);
		};

		fetch_campaign_region();
	}, []);

	const showReportSubnav = () => setReportSubnav((prev) => !prev);
	const showMobileSubnav = () => setMobileSubnav((prev) => !prev);
	const showEventSubnav = () => setEventSubnav((prev) => !prev);
	const showWebSellerSubnav = () => setWebSellerSubnav((prev) => !prev);
	const showLaboratoryAccountSubnav = () =>
		setLaboratoryAccountSubnav((prev) => !prev);
	const showCouponSubnav = () => setCouponSubnav((prev) => !prev);

	return (
		<Layout>
			{!ready ? (
				"Loading..."
			) : userdata.isOneMarket ? (
				<MarketHeader
					scrollDirection={scrollDirection}
					userdata={userdata}
					location={location}
					templateProducts={templateProducts}
					bookQty={bookQty}
					qty={qty}
					setShowDrawer={setShowDrawer}
					showDrawer={showDrawer}
					browserInfo={browserInfo}
				/>
			) : userdata.isSeller ? (
				<LaboratoryHeader
					scrollDirection={scrollDirection}
					userdata={userdata}
					campaignRegion={campaignRegion}
					templateProducts={templateProducts}
					location={location}
					bookQty={bookQty}
					qty={qty}
					setShowDrawer={setShowDrawer}
					showDrawer={showDrawer}
					browserInfo={browserInfo}
					clientPhotoUrl={clientPhotoUrl}
				/>
			) : (
				<PharmacyHeader
					scrollDirection={scrollDirection}
					userdata={userdata}
					campaignRegion={campaignRegion}
					location={location}
					clientSlug={clientSlug}
					pharmaName={pharmaName}
					templateProducts={templateProducts}
					bookQty={bookQty}
					qty={qty}
					setShowDrawer={setShowDrawer}
					showDrawer={showDrawer}
					browserInfo={browserInfo}
				/>
			)}
			<Layout.Main>{children}</Layout.Main>
			<Drawer
				title=""
				headerStyle={{ display: "none" }}
				placement="right"
				onClose={() => setShowDrawer(false)}
				visible={showDrawer}
				zIndex={998}
			>
				<Aside>
					{!ready ? (
						"Loading..."
					) : userdata.isOneMarket ? (
						<Aside.Actions>
							<Aside.Separator>
								<Link to="/profile" onClick={() => setShowDrawer(false)}>
									<Layout.HeaderAction
										style={{
											width: "150px",
											wordBreak: "break-word",
											left: "-12px",
										}}
									>
										<Layout.HeaderUserIconContainer>
											<Layout.HeaderUserIcon>
												<Image
													src={photo.url ? photo.url : UserIcon}
													alt="User"
													style={{
														width: "36px",
														height: "36px",
														borderRadius: "50%",
													}}
													key={photo.key}
												/>
											</Layout.HeaderUserIcon>
											<Layout.HeaderUserParagraph>
												<Layout.HeaderSpan>
													{userdata?.firstName}
												</Layout.HeaderSpan>
												<Layout.HeaderSpan>
													<Typo type="primary" level={7} lineHeight={0}>
														OneMarket
													</Typo>
												</Layout.HeaderSpan>
											</Layout.HeaderUserParagraph>
										</Layout.HeaderUserIconContainer>
									</Layout.HeaderAction>
								</Link>
								<Aside.Action> </Aside.Action>
								<Aside.Action> </Aside.Action>
								{checkOneMarketMenu(userdata)?.map((item, index) => {
									return (
										<AsideBarSubMenuAdminOneMarket
											item={item}
											key={index}
											setShowDrawer={setShowDrawer}
											requestsQty={requestsQty}
											mobileRequestsQty={mobileRequestsQty}
											ordersQty={ordersQty}
											toValidateQty={toValidateQty}
											bannerRequestQty={bannerRequestQty}
										/>
									);
								})}
								<Aside.Action> </Aside.Action>
								<Aside.Action> </Aside.Action>
								<Link to="/">
									<Aside.Action onClick={() => signOut()}>
										<BiLogOut color={COLORS.Primary} />
										Cerrar Sesión
									</Aside.Action>
								</Link>
							</Aside.Separator>
						</Aside.Actions>
					) : !ready ? (
						"Loading..."
					) : userdata.isSeller ? (
						<Aside.Actions>
							<Aside.Separator>
								<Link to="/profile" onClick={() => setShowDrawer(false)}>
									<Layout.HeaderAction
										style={{
											width: "150px",
											wordBreak: "break-word",
											left: "-12px",
										}}
									>
										<Layout.HeaderUserIconContainer>
											<Layout.HeaderUserIcon>
												<Image
													src={photo ? photo : UserIcon}
													alt="User"
													style={{
														width: "36px",
														height: "36px",
														borderRadius: "50%",
													}}
												/>
											</Layout.HeaderUserIcon>
											<Layout.HeaderUserParagraph>
												<Layout.HeaderSpan>
													{userdata?.firstName}
												</Layout.HeaderSpan>
												<Layout.HeaderSpan>
													<Typo type="primary" level={7} lineHeight={0}>
														{pharmaName}
													</Typo>
												</Layout.HeaderSpan>
											</Layout.HeaderUserParagraph>
										</Layout.HeaderUserIconContainer>
									</Layout.HeaderAction>
								</Link>
								<Aside.Action> </Aside.Action>
								<Aside.Action> </Aside.Action>
								{/* <PermissionCheckVisibility
									permission={userdata.isSeller && userdata.p_client}
								>
									<Link to="/generalConfig">
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<SettingOutlined style={{ color: COLORS.Primary }} />
											Configuración General
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility> */}
								<PermissionCheckVisibility
									permission={
										userdata.isSeller &&
										userdata.p_dashboard &&
										userdata.p_sales &&
										(mobileActive || expoActive || webSellerActive)
									}
								>
									<Link to="/erp">
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<MdOutlineBusiness style={{ color: COLORS.Primary }} />
											ERP
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility>
								<PermissionCheckVisibility
									permission={
										userdata.isSeller &&
										userdata.p_dashboard &&
										userdata.p_sales
									}
								>
									<LaboratorySidebarLink to="#" onClick={showReportSubnav}>
										<div>
											<RiDashboardLine color={COLORS.Primary} />{" "}
											<SidebarLabel>Reportes</SidebarLabel>
										</div>
									</LaboratorySidebarLink>
								</PermissionCheckVisibility>
								{reportSubNav && (
									<div style={{ display: "flex", flexDirection: "column" }}>
										<DropdownLink to="/admin/dashboard">
											<div onClick={() => setShowDrawer(false)}>
												<RiDashboardLine color={COLORS.Primary} />
												<SidebarLabel>Dashboard</SidebarLabel>
											</div>
										</DropdownLink>
										{/* <DropdownLink to="/admin/laboratoryAuditory">
											<div onClick={() => setShowDrawer(false)}>
												<DownloadOutlined style={{ color: COLORS.Primary }} />
												<SidebarLabel>Auditoria</SidebarLabel>
											</div>
										</DropdownLink> */}
										{/* <DropdownLink to="/admin/laboratoryDistributorResponse">
											<div onClick={() => setShowDrawer(false)}>
												<SnippetsOutlined style={{ color: COLORS.Primary }} />
												<SidebarLabel>Estado ordenes</SidebarLabel>
											</div>
										</DropdownLink> */}
									</div>
								)}
								<PermissionCheckVisibility
									permission={
										(userdata.isSeller &&
											!userdata.p_dashboard &&
											userdata.p_sales &&
											userdata.p_shop) ||
										(userdata.isSeller &&
											!userdata.p_client &&
											!userdata.p_users &&
											!userdata.p_products &&
											!userdata.p_dashboard &&
											userdata.p_sales &&
											!userdata.p_shop &&
											userdata.p_mobile)
									}
								>
									<Link to="/admin/ventas">
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<AiOutlineLineChart color={COLORS.Primary} />
											Ventas
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility>
								<Aside.Action> </Aside.Action>
								<Aside.Action> </Aside.Action>
								<Link to="#">
									<Aside.Action onClick={() => signOut()}>
										<BiLogOut color={COLORS.Primary} />
										Cerrar Sesión
									</Aside.Action>
								</Link>
							</Aside.Separator>
							<Aside.Action> </Aside.Action>
							{/* <PermissionCheckVisibility permission={userdata.p_client}>
								<Link to="/startGuide">
									<Aside.Action onClick={() => setShowDrawer(false)}>
										<QuestionCircleOutlined style={{ color: COLORS.Primary }} />
										Guía de Inicio
										{!userdata?.isOneMarket && (
											<MenuAlertContainer>
												{userdata?.guideProgress === 0 ? (
													<AiFillAlert
														color={COLORS.Danger}
														style={{ width: 15 }}
													/>
												) : (
													<Progress
														type="circle"
														percent={percent}
														width={30}
													/>
												)}
											</MenuAlertContainer>
										)}
									</Aside.Action>
								</Link>
							</PermissionCheckVisibility> */}
						</Aside.Actions>
					) : (
						<Aside.Actions>
							<Aside.Separator>
								<Link to="/profile" onClick={() => setShowDrawer(false)}>
									<Layout.HeaderAction
										style={{
											width: "150px",
											wordBreak: "break-word",
											position: "relative",
											left: "-12px",
										}}
									>
										<Layout.HeaderUserIconContainer>
											<Layout.HeaderUserIcon>
												<Image
													src={photo ? photo : UserIcon}
													alt="User"
													style={{
														width: "36px",
														height: "36px",
														borderRadius: "50%",
													}}
												/>
											</Layout.HeaderUserIcon>
											<Layout.HeaderUserParagraph>
												<Layout.HeaderSpan>
													{userdata?.firstName}
												</Layout.HeaderSpan>
												<Layout.HeaderSpan>
													<Typo type="primary" level={7} lineHeight={0}>
														{pharmaName}
													</Typo>
												</Layout.HeaderSpan>
											</Layout.HeaderUserParagraph>
										</Layout.HeaderUserIconContainer>
									</Layout.HeaderAction>
								</Link>
								<Aside.Action> </Aside.Action>
								<Aside.Action> </Aside.Action>
								<PermissionCheckVisibility permission={userdata.p_dashboard}>
									<Link to="/admin/dashboard">
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<RiDashboardLine color={COLORS.Primary} />
											Dashboard
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility>
								<Link to="/admin/compras">
									<Aside.Action onClick={() => setShowDrawer(false)}>
										<AiOutlineLineChart color={COLORS.Primary} />
										Mis Compras
									</Aside.Action>
								</Link>
								<PermissionCheckVisibility permission={userdata.p_shop}>
									<Link to="/admin/templates">
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<ImInsertTemplate color={COLORS.Primary} />
											Plantillas
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility>
								<PermissionCheckVisibility permission={userdata.p_shop}>
									<Link to="/admin/marketing">
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<RiTvLine color={COLORS.Primary} />
											Campañas Publicitarias
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility>
								<Aside.Action> </Aside.Action>
								<Aside.Action> </Aside.Action>
								<PermissionCheckVisibility permission={userdata.p_client}>
									<Link to="/admin/pointsofsale">
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<MdOutlinePlace color={COLORS.Primary} />
											Puntos de venta
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility>
								<PermissionCheckVisibility permission={userdata.p_users}>
									<Link to="/admin/users">
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<FiUsers color={COLORS.Primary} />
											Usuarios y Permisos
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility>
								<PermissionCheckVisibility permission={userdata.p_client}>
									<Link to="/admin/account">
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<RiUserSettingsLine color={COLORS.Primary} />
											Datos de la cuenta
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility>
								<Aside.Action> </Aside.Action>
								<Aside.Action> </Aside.Action>
								<Link to="#">
									<Aside.Action onClick={() => signOut()}>
										<BiLogOut color={COLORS.Primary} />
										Cerrar Sesión
									</Aside.Action>
								</Link>
							</Aside.Separator>
							<Aside.Action> </Aside.Action>
							<PermissionCheckVisibility permission={userdata.p_client}>
								<Link to="/startGuide">
									<Aside.Action onClick={() => setShowDrawer(false)}>
										<QuestionCircleOutlined style={{ color: COLORS.Primary }} />
										Guía de Inicio
										{!userdata?.isOneMarket && (
											<MenuAlertContainer>
												{userdata?.guideProgress === 0 ? (
													<AiFillAlert
														color={COLORS.Danger}
														style={{ width: 15 }}
													/>
												) : (
													<Progress
														type="circle"
														percent={percent}
														width={30}
													/>
												)}
											</MenuAlertContainer>
										)}
									</Aside.Action>
								</Link>
							</PermissionCheckVisibility>
						</Aside.Actions>
					)}
				</Aside>
			</Drawer>
			{/* {!userdata.isOneMarket && (
				<WhatsAppButton>
					<a
						href={`https://wa.me/5491162906179?text=Hola,%20soy%20${
							userdata.firstName
						}%20de%20${
							userdata.isSeller ? "laboratorio" : "farmacia"
						}%20${pharmaName},%20tengo%20una%20consulta%20sobre%20checkpos%20transfer.`}
						target="blank"
						style={{
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
						}}
					>
						<Typo
							type="white"
							level={1}
							style={{ transform: "translate(1px, 3.5px)" }}
						>
							<BsWhatsapp />
						</Typo>
					</a>
				</WhatsAppButton>
			)} */}
			<FooterContainer img={MarketLogoIcon}></FooterContainer>
		</Layout>
	);
}
