import React, { useEffect, useState } from "react";
import {
	export_laboratory_sales,
	get_laboratory_sales,
} from "../../../../../api/endpoints/sales";
import Main from "../../../../layout/main/Main";
import MySalesTable from "./components/my-sales-table/MySalesTable";
import { Link, useNavigate } from "react-router-dom";
import {
	CalendarButton,
	RadioOptions,
	CompaginationDiv,
	AntdInput,
	Inner,
} from "./styles";
import { Button, DatePicker, Select } from "antd";
import {
	CloudDownloadOutlined,
	LeftOutlined,
	QuestionCircleOutlined,
	RightOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../../../../contexts/authContext";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { CONSTANTS } from "../../../../../utils/constants";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import moment from "moment";
import { useFilter } from "../../../../../contexts/filterContext";

export default function Sales({ clientId }) {
	const { user, userdata, updateStartGuideProgress } = useAuth();
	const { ordersLabSellerId, setOrdersLabSellerId } = useFilter();
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [laboratoryId, setLaboratoryId] = useState(clientId);
	const [distributorId, setDistributorId] = useState(null);
	const [pharmacyId, setPharmacyId] = useState(null);
	const [provinceId, setProvinceId] = useState(null);
	const [typeOfSale, setTypeOfSale] = useState(null);
	const [orderState, setOrderState] = useState(null);
	const [dateToSearch, setDateToSearch] = useState(null);
	const [page, setPage] = useState(CONSTANTS.PAGE_LARGE_SIZE);
	const [actualPage, setActualPage] = useState(1);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [searchInfo, setSearchInfo] = useState(null);
	const [users, setUsers] = useState(null);
	const [orderTypes, setOrderTypes] = useState(null);
	const [distributors, setDistributors] = useState(null);
	const [provinces, setProvinces] = useState(null);
	const [orderStates, setOrderStates] = useState(null);

	const fetch_sales = async (skipValue) => {
		setIsLoading(true);
		try {
			const res = await get_laboratory_sales(
				laboratoryId,
				distributorId,
				ordersLabSellerId,
				provinceId,
				dateToSearch,
				typeOfSale,
				orderState,
				skipValue,
				page
			);
			const resTypesOfOrders = JSON.parse(res?.paginationInfo?.typesOfOrders);
			const resStatesOfOrders = JSON.parse(res?.paginationInfo?.statesOfOrders);
			const resProvinceOfOrders = JSON.parse(
				res?.paginationInfo?.provinceOfOrders
			);

			setData(res?.orders);

			setSearchInfo(res?.paginationInfo);
			setPaginationInputValue(res?.paginationInfo?.actualPage);
			setUsers(
				res?.users?.map((r) => ({
					value: r.sellerId,
					label: r.sellerFirstName + " " + r.sellerLastName,
				}))
			);
			setOrderTypes(
				resTypesOfOrders?.map((r) => ({
					value: r.TypeOfSale,
					label: r.TypeOfSale,
				}))
			);
			setOrderStates(
				resStatesOfOrders?.map((r) => ({
					value: r.OrderState,
					label: selectStateToShow(r.OrderState),
				}))
			);
			setProvinces(
				resProvinceOfOrders?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);
			setDistributors(
				res?.distributors?.map((r) => ({
					value: r.distributorId,
					label: r.distributorName,
				}))
			);
			setHeaderInfo({
				totalActive: res?.paginationInfo.totalActive,
				totalPending: res?.paginationInfo.totalPending,
				totalSended: res?.paginationInfo.totalSended,
				totalCanceled: res?.paginationInfo.totalCanceled,
			});
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setActualPage(1);
		setPage(CONSTANTS.PAGE_LARGE_SIZE);
		ordersLabSellerId && fetch_sales(1);
	}, [
		orderState,
		typeOfSale,
		dateToSearch,
		ordersLabSellerId,
		provinceId,
		distributorId,
	]);

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendientes";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activas";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviadas";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Canceladas";

			default:
				return;
		}
	};

	const handleDeleteDates = () => {
		setDateToSearch(null);
		setDistributorId(null);
		setOrdersLabSellerId(null);
		setTypeOfSale(null);
		setOrderState(null);
		fetch_sales(1);
	};

	const handleBackToTheGuide = () => {
		updateStartGuideProgress(user.uid, 5);
		navigate("/startGuide");
	};

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_sales(cp);
			setActualPage(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_sales(cp);
			setActualPage(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_sales(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	const handleDateChange = (date) => {
		setDateToSearch(date.format("DD/MM/YYYY").toString());
	};

	const selectProvince = (e) => {
		setProvinceId(e);
	};

	const selectUser = (e) => {
		setOrdersLabSellerId(e);
	};

	const selectTypeOfSale = (e) => {
		setTypeOfSale(e);
	};

	const selectDistributor = (e) => {
		setDistributorId(e);
	};

	const selectState = (e) => {
		setOrderState(e);
	};

	const fetch_export_report = async () => {
		setIsLoading(true);
		try {
			const res = await export_laboratory_sales({
				laboratoryId: laboratoryId,
				distributorId: distributorId,
				pharmacyId: pharmacyId,
				sellerId: ordersLabSellerId,
				provinceId: provinceId,
				typeOfSale: typeOfSale,
				orderState: orderState,
				dateToSearch: dateToSearch,
			});
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const _fileName =
				"SalesReport_" + moment().format("DDMMYYYYhhmmss").toString() + ".xlsx";
			link.setAttribute("download", _fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Main>
			<Main.Breadcrumb>
				<Main.BreadcrumbItem>
					<Link to="/">Home</Link>
				</Main.BreadcrumbItem>
				<Main.BreadcrumbSeparator />
				<Main.ActiveBreadcrumbItem>Mis ventas</Main.ActiveBreadcrumbItem>
			</Main.Breadcrumb>
			<Inner></Inner>
			<Main.Body>
				<RadioOptions>
					<DatePicker
						picker="month"
						onChange={handleDateChange}
						placeholder="fecha"
					/>
					<Select
						showSearch
						allowClear
						placeholder="provincia"
						optionFilterProp="children"
						onChange={(e) => selectProvince(e)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={provinces}
						style={{ width: 220 }}
					/>
					{!userdata.p_shop && !userdata.p_mobile && (
						<Select
							showSearch
							allowClear
							placeholder="origen"
							optionFilterProp="children"
							onChange={(e) => selectTypeOfSale(e)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={orderTypes}
							style={{ width: 120 }}
						/>
					)}
					{!userdata.p_shop && !userdata.p_mobile && (
						<Select
							showSearch
							allowClear
							placeholder="usuario"
							optionFilterProp="children"
							onChange={(e) => selectUser(e)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={users}
							style={{ width: 200 }}
						/>
					)}
					<Select
						showSearch
						allowClear
						placeholder="droguería"
						optionFilterProp="children"
						onChange={(e) => selectDistributor(e)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 180 }}
					/>
					<Select
						showSearch
						allowClear
						placeholder="estado"
						optionFilterProp="children"
						onChange={(e) => selectState(e)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={orderStates}
						style={{ width: 180 }}
					/>
					<CalendarButton
						type="primary"
						color="white"
						onClick={handleDeleteDates}
						loading={isLoading}
					>
						Eliminar filtros
					</CalendarButton>
					<SignInTemplate.AntdButton
						type="Default"
						bg="Default"
						color="White"
						icon={<CloudDownloadOutlined />}
						onClick={fetch_export_report}
						loading={isLoading}
						style={{ width: "200px", fontWeight: "400" }}
					>
						Exportar
					</SignInTemplate.AntdButton>
				</RadioOptions>
				<MySalesTable data={data} loading={isLoading} pagination={false} />
				{searchInfo && searchInfo.totalOrders > 0 && (
					<CompaginationDiv>
						{searchInfo.actualPage !== 1 && (
							<Button size="small" shape="circle" onClick={handleDecrement}>
								<LeftOutlined />
							</Button>
						)}
						<AntdInput
							step={1}
							min={1}
							max={searchInfo.totalPages}
							onPressEnter={(e) => handlePageChange(e)}
							defaultValue={paginationInputValue.toString()}
							addonAfter={`/ ${searchInfo.totalPages}`}
							style={{
								width: ` ${searchInfo.totalPages > 99 ? "76px" : "66px"}`,
							}}
						/>
						{searchInfo.actualPage < searchInfo.totalPages && (
							<Button
								size="small"
								shape="circle"
								onClick={handleIncrement}
								disabled={
									searchInfo.actualPage === searchInfo.totalPages ? true : false
								}
							>
								<RightOutlined />
							</Button>
						)}
					</CompaginationDiv>
				)}
			</Main.Body>
			{userdata?.guideProgress === 4 && (
				<SignInTemplate.AntdButton
					type="primary"
					bg="Primary"
					color="White"
					icon={<QuestionCircleOutlined />}
					onClick={handleBackToTheGuide}
					style={{ width: "250px", fontWeight: "400" }}
				>
					Volver a la Guia de Inicio
				</SignInTemplate.AntdButton>
			)}
		</Main>
	);
}
